import { isExternalFlow } from './productIntegrationUtils';
import templateData from './getTemplateData';
import AppStore from '../stores/AppStore';

function isNna(country) {
  return (
    ['US'].indexOf(country) >= 0 &&
    templateData.nnaExperienceStatus &&
    !isExternalFlow() &&
    templateData?.pxp?.showNnaExp?.experiment &&
    !templateData.memberMandatory &&
    AppStore.getDonationRecurring() !== 'checked'
  );
}

export default isNna;
