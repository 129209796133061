import React from 'react';
import PropTypes from 'prop-types';
import templateData, { isOldButtonFactory } from '../utility/getTemplateData';
import { BodyText, HeadingText, CaptionText } from '@paypalcorp/pp-react';
import PPCC from './ppcc/ppcc';
import { Title } from '@paypalcorp/donate-react';
import { Container, Row, Col } from '@paypalcorp/pp-react-grid';
import classNames from 'classnames';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
import { MessageFormat } from '@paypalcorp/worldready';

class SubHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      isVoucherFlow: templateData.isVoucherFlow,
    };
  }

  formatOrgName() {
    let { label, orgName } = this.props;
    label = label && label.toString().replace(/\s/g, '').toUpperCase();
    const OName =
      orgName && orgName.toString().replace(/\s/g, '').toUpperCase();
    return label.includes(OName) ? '' : orgName;
  }

  shouldShowDonationId() {
    if (this.props.page === 'member' || this.props.page === 'crossborder') {
      return true;
    }
    return isOldButtonFactory();
  }

  getFormattedOrgName(purposeLabel, orgName) {
    if (!this.state.isVoucherFlow) {
      return `${orgName}`;
    }
    return orgName;
  }

  render() {
    const donateto = new MessageFormat(this.context, {
      id: 'common.subHeader.label.donateto',
    }).format();
    const support = new MessageFormat(this.context, {
      id: 'common.subHeader.label.support',
    }).format();
    const purposeLabel = new MessageFormat(this.context, {
      id: 'common.subHeader.label.purpose',
    }).format();

    const donationName = this.props.label;
    const orgName = this.formatOrgName();
    const donationId = this.props.donationId;
    let { formattedDonationName, formattedOrgName, formattedDonationId } = {};
    const isLanding = this.props.isLanding;
    const isCampaigns = this.props.isCampaigns;
    const hasLogo = this.props.hasLogo;

    const subHeaderClasses = classNames(
      'donate_sub_header',
      'donate-main-content',
      'donate-main-content-with-no-margin',
      {
        'org-content': !hasLogo && isCampaigns(),
      }
    );

    if (donationName === orgName) {
      formattedDonationName = donationName;
      formattedOrgName = '';
    } else {
      formattedDonationName = donationName;
    }

    if (orgName && !donationId) {
      if (donationName !== orgName) {
        formattedOrgName = this.getFormattedOrgName(purposeLabel, orgName);
      }
    }

    if (!orgName && donationId) {
      formattedDonationId = `${donationId}`;
    }

    if (orgName && donationId) {
      if (donationName !== orgName) {
        formattedDonationId = donationId;
        formattedOrgName = this.getFormattedOrgName(purposeLabel, orgName);
      } else {
        formattedOrgName = '';
        formattedDonationId = `${donationId}`;
      }
    }

    const shouldShowOrgName = isCampaigns()
      ? !isLanding
      : formattedOrgName && isLanding;

    return (
      <Container className={subHeaderClasses}>
        {!isCampaigns() && (
          <Row
            className="donate-to"
            style={!hasLogo ? { marginTop: '1.5rem' } : {}}
            align={'center'}
          >
            <Col xs={12} className="title">
              {isLanding ? (
                <BodyText
                  className={`${
                    this.state.isVoucherFlow
                      ? 'title-secondary'
                      : 'title-primary'
                  } no-margins text-center`}
                >
                  {this.state.isVoucherFlow ? support : donateto}
                </BodyText>
              ) : (
                <CaptionText
                  className={`${
                    this.state.isVoucherFlow
                      ? 'title-secondary'
                      : 'title-primary'
                  } no-margins text-center`}
                >
                  {this.state.isVoucherFlow ? support : donateto}
                </CaptionText>
              )}
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={12}>
            <div
              className="text-center"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!isCampaigns() && isLanding ? (
                <HeadingText
                  size="sm"
                  className="name no-margins org-name"
                  id="donation-name"
                >
                  {formattedDonationName}
                  <PPCC isHeader={true} />
                </HeadingText>
              ) : (
                <BodyText
                  className="name no-margins org-name guest-form"
                  strong
                >
                  {formattedDonationName}
                  <PPCC />
                </BodyText>
              )}
            </div>
          </Col>
        </Row>
        {shouldShowOrgName &&
          (isCampaigns() ? (
            <Title
              size="sm"
              className="campaign-subheader-title"
              title={formattedOrgName}
            />
          ) : (
            <BodyText className="landing-purpose">{formattedOrgName}</BodyText>
          ))}

        {formattedDonationId && this.shouldShowDonationId() && (
          <Row align={'center'}>
            <BodyText className="no-margins">{formattedDonationId}</BodyText>
          </Row>
        )}
      </Container>
    );
  }
}

SubHeader.propTypes = {
  label: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  donationId: PropTypes.string,
  page: PropTypes.string,
  isLanding: PropTypes.bool,
  isCampaigns: PropTypes.func,
  hasLogo: PropTypes.bool,
};

SubHeader.contextType = WorldReadyContext;
export default SubHeader;
