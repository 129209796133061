export function showActionableErrorModal(errMsg) {
  window.showActionableErrorModal();
  document.getElementById('actionable-error-msg-1').innerHTML = errMsg;
}

export function hideActionableErroModal() {
  window.hideActionableErroModal();
}

export function showUnactionableErrorModal() {
  window.showUnactionableErrorModal();
}

export function hideUnactionableErrorModal() {
  window.hideUnactionableErrorModal();
}

export function showLeaveModal() {
  window.showLeaveModal();
}

export function hideLeaveModal() {
  window.hideLeaveModal();
}
