import React, { useContext } from 'react';
import { IconButton, ArrowLeftIcon } from '@paypalcorp/pp-react';
import templateData from '../utility/getTemplateData';
import { useHistory } from 'react-router-dom';
import { fetcher } from '../utility/domUtils';
import { hideSpinnerOverlay, showSpinnerOverlay } from '../utility/helper';
import { getDismissEventName } from '../utility/productIntegrationUtils';
import { trackLinkClick } from '../fpti/fpti';
import { LINK_NAME } from '../fpti/fptiConstants';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
import { MessageFormat } from '@paypalcorp/worldready';

export default function BackButton(props) {
  const worldReady = useContext(WorldReadyContext);
  const history = useHistory();
  const buttonBack = new MessageFormat(worldReady, {
    id: 'common.header.button.back',
  }).format();

  const clearGuestSession = () => {
    showSpinnerOverlay();
    fetcher('/donate/guest/sessionLogout', {
      method: 'POST',
    }).finally(() => {
      hideSpinnerOverlay();
      history.push('/donate?token=' + templateData.token);
    });
  };

  const navigateBack = () => {
    trackLinkClick(LINK_NAME.BACK, {
      eventName: getDismissEventName(),
    });

    if (templateData.hideLandingPage) {
      window.location.href = templateData.charityCancelUrl;
    } else if (templateData.viewName === 'member') {
      window.location.href = '/donate?token=' + templateData.token;
    } else {
      // currentState is populated with the guest url on country change, which hides the landing page.
      // Therefore removing guest url from currentState on back button click, to enable displayLanding
      templateData.currentState = '';

      // In tesla form if user ends up on the guest page again due to any on-boarding error, the user auth_state is IDENTIFIED,
      // so on back button click we need to logout the user, before user starts a new guest session
      clearGuestSession();
    }
  };

  return (
    <IconButton
      data-testid="back-button"
      svgIconComponent={ArrowLeftIcon}
      onClick={navigateBack}
      className="back-button"
      buttonType="tertiary"
      {...props}
    >
      {buttonBack}
    </IconButton>
  );
}
