import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, ShareWhatsappIcon } from '@paypalcorp/pp-react';

function SocialShareWhatsapp({ url, handleSocialLinkClick, linkTitle }) {
  return (
    <IconButton
      href={url}
      target="popup"
      onClick={() => handleSocialLinkClick(url, 'whatsapp')}
      data-testid="social-share-whatsapp"
      size="sm"
      buttonType="secondary"
      svgIconComponent={ShareWhatsappIcon}
    >
      {linkTitle}
    </IconButton>
  );
}

SocialShareWhatsapp.propTypes = {
  url: PropTypes.string,
  handleSocialLinkClick: PropTypes.func,
  linkTitle: PropTypes.string,
};

export default SocialShareWhatsapp;
