import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '@paypalcorp/pp-react';
import { StyleSheet, css } from 'aphrodite';
import {
  Accordion,
  AccordionRow,
  RewardsIcon,
  Divider,
  CaptionText,
} from '@paypalcorp/pp-react';
import { getCurrencySymbol } from '../utility/getTemplateData';
import { formatCurrencyAsString, formatPercentage } from '../utility/formator';
import { sanitize } from '../utility/domUtils';

import { trackLinkClick } from '../fpti/fpti';
import { LINK_NAME } from '../fpti/fptiConstants';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
import { MessageFormat } from '@paypalcorp/worldready';
import { isRightSymbol } from './amount/amountUtils';

const styles = StyleSheet.create({
  offerWrapper: {
    paddingBottom: '20px',
    maxWidth: 415,
    margin: 'auto',
  },
  success: {
    paddingBottom: '8px',
  },
  accordionWrapper: {
    marginRight: 0,
    marginLeft: 0,
    backgroundColor: '#f5fbfe',
    backgroundClip: 'content-box',
  },
  offerDivider: {
    borderColor: '#BFDBEE',
    margin: 0,
  },
  offerHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    wordBreak: 'keep-all',
  },
  messageFrom: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  description: {
    paddingBottom: '10px',
  },
});

function formatOfferTextAmount(value, currencyCode, type = 'MONETARY') {
  const isAmtPercent = type === 'PERCENTAGE';
  const formattedValue = value / 100;
  const amountOpts = { currency: currencyCode, value: formattedValue };

  return isAmtPercent
    ? formatPercentage(formattedValue)
    : formatCurrencyAsString(amountOpts);
}

class OfferDetail extends React.Component {
  constructor(props) {
    super(props);
    let rightSymbol;
    try {
      rightSymbol = isRightSymbol();
    } catch (e) {
      rightSymbol = false;
    }
    this.state = {
      rightSymbol,
    };
  }

  componentDidMount() {
    const row = document.getElementById('offerAccordion-row1');
    if (row) {
      row.click();
    }
  }

  createMarkup(content) {
    return {
      __html: content,
    };
  }

  handleClick() {
    trackLinkClick(LINK_NAME.VOUCHER);
  }

  getAmountDisplayValue = ({ value, currencyCode }) => {
    let amountDisplayValue;
    if (this.state.rightSymbol) {
      amountDisplayValue = `${value}${getCurrencySymbol(currencyCode)}`;
    } else {
      amountDisplayValue = `${getCurrencySymbol(currencyCode)}${value}`;
    }
    return amountDisplayValue;
  };

  render() {
    const {
      offerProgram: {
        clientOfferType,
        businessName,
        subTitle,
        description,
        type,
        value,
        currencyCode,
      },
      success,
    } = this.props;

    const isCustomOffer = clientOfferType && clientOfferType === 'CUSTOM';
    const offerTextAmount = formatOfferTextAmount(value, currencyCode, type);
    const title = new MessageFormat(this.context, {
      id: 'common.offerdetail.offer.title',
    });
    const aMessageFrom = new MessageFormat(this.context, {
      id: 'common.offerdetail.offer.messagefrombusiness',
    }).format({
      businessName: sanitize(businessName),
    });
    const titleMessage = isCustomOffer
      ? sanitize(subTitle)
      : title.format({ amountText: offerTextAmount });
    // const additonalMessage = messages.offer.receive.replace('{amountText}', offerTextAmountOff);
    if (titleMessage && description) {
      const heading = (
        <div className={`${css(styles.offerHeader)}`}>
          <div>
            <RewardsIcon
              as="i"
              size="sm"
              style={{ color: '#0070BA', marginRight: 10 }}
            />
          </div>
          <CaptionText
            style={{ margin: 0 }}
            dangerouslySetInnerHTML={this.createMarkup(titleMessage)}
          ></CaptionText>
        </div>
      );
      return (
        <div
          className={` ${css(styles.offerWrapper, success && styles.success)}`}
        >
          <Row
            align="center"
            className={`${css(styles.accordionWrapper)}`}
            onClick={() => this.handleClick()}
          >
            <Accordion name="offerAccordion" className="accordion-no-border">
              <AccordionRow heading={heading}>
                <Divider className={css(styles.offerDivider)} />
                <div className="accordion-body">
                  <p
                    className={css(styles.messageFrom)}
                    dangerouslySetInnerHTML={this.createMarkup(aMessageFrom)}
                  ></p>
                  <p
                    className={css(styles.description)}
                  >{`"${description}"`}</p>
                </div>
              </AccordionRow>
            </Accordion>
          </Row>
        </div>
      );
    }
    return null;
  }
}

OfferDetail.propTypes = {
  offerProgram: PropTypes.object.isRequired,
  success: PropTypes.bool,
};

OfferDetail.contextType = WorldReadyContext;
export default OfferDetail;
