import { createBrowserHistory } from 'history';
import * as modalUtils from '../setup/utilsV3/modalUtils';

function waitForElement(selector, callback) {
  if (document.querySelector(selector) !== null) {
    callback(); // eslint-disable-line
  } else {
    setTimeout(function () {
      waitForElement(selector, callback);
    }, 100);
  }
}

const history = createBrowserHistory({
  getUserConfirmation(message, callback) {
    // Show some custom dialog to the user and call
    // callback(true) to continue the transiton, or
    // callback(false) to abort it.
    modalUtils.showLeaveModal();

    waitForElement('#stay-and-finish', () => {
      document
        .querySelector('#stay-and-finish')
        .addEventListener('click', function () {
          modalUtils.hideLeaveModal();
          callback(false); // eslint-disable-line
        });

      document
        .querySelector('#leave-now')
        .addEventListener('click', function () {
          modalUtils.hideLeaveModal();
          callback(true); // eslint-disable-line
        });
    });
  },
});
const location = history.location;

history.block((targetLocation, action) => {
  // The location and action arguments indicate the location
  // we're transitioning to and how we're getting there.
  if (
    window.location.pathname === '/donate/buttons/unhosted' &&
    targetLocation.pathname !== '/donate/buttons/unhosted/edit' &&
    targetLocation.pathname !== '/donate/buttons/login'
  ) {
    return "If you leave now, your work won't be saved and you'll need to start over to create your button.";
  }
});

export { history, location };

export default history;
