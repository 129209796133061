import React from 'react';
import withCurrentPageUpdate from '../../confirmation/withCurrentPageUpdate';
import withPageTrack from '../../confirmation/withPageTrack';
import withPostTransactionRequest from '../../confirmation/withPostTransactionRequest';
import PendingView from '../../confirmation/PendingView';
import SuccessView from '../../confirmation/SuccessView';

const PAGE_UPDATE_TAG = 'memberConfirmation';
const POST_TRANSACTION_URL = '/donate/member/postTransaction';
const FLOW = 'member';

export const MemberConfirmationPending = withPageTrack(
  withCurrentPageUpdate(
    withPostTransactionRequest(PendingView, POST_TRANSACTION_URL),
    PAGE_UPDATE_TAG
  ),
  FLOW,
  'pending'
);

export const MemberConfirmationRecurring = withPageTrack(
  withCurrentPageUpdate(SuccessView, 'MemberRecurringConfirmation'),
  FLOW,
  'success'
);

export const MemberConfirmationSuccess = withPageTrack(
  withCurrentPageUpdate(
    withPostTransactionRequest(SuccessView, POST_TRANSACTION_URL),
    PAGE_UPDATE_TAG
  ),
  FLOW,
  'success'
);
