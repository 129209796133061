import SubHeader from './subHeader';
import CharityLogo from './CharityLogo';
import React from 'react';
import templateData from '../utility/getTemplateData';
import PropTypes from 'prop-types';
import { isCampaigns } from '../utility/productIntegrationUtils';
import { BannerImage } from '@paypalcorp/donate-react';

const HeaderDNW = ({ isLanding }) => {
  const hasLogo = templateData.hasLogo,
    donationId = templateData.donationId,
    orgName = templateData.charityName ?? '',
    subHeaderLabel = templateData.donationName ?? '',
    bannerUrl = templateData.bannerUrl ?? '';

  const displayBannerImage = () => {
    if (bannerUrl) {
      if (isLanding && isCampaigns()) {
        return null;
      }
      return (
        <BannerImage
          className="banner-image"
          src={bannerUrl}
          data-test-id="bg-banner-image"
        />
      );
    }
    return null;
  };

  return (
    <div>
      {displayBannerImage()}
      {hasLogo && <CharityLogo />}
      <SubHeader
        donationId={donationId}
        orgName={orgName}
        label={subHeaderLabel}
        page="landing"
        isLanding={isLanding}
        isCampaigns={isCampaigns}
        hasLogo={hasLogo}
      />
    </div>
  );
};

HeaderDNW.propTypes = {
  isLanding: PropTypes.bool,
};

export default HeaderDNW;
