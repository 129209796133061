export function copyToClipboard(sourceId) {
  let range;
  let selection;
  const sourceElement = document.getElementById(sourceId);
  const isIOS = navigator.userAgent.match(/ipad|iphone/gi);
  if (isIOS) {
    range = document.createRange();
    range.selectNodeContents(sourceElement);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    sourceElement.setSelectionRange(0, 999999);
    sourceElement.readOnly = 'true';
  } else {
    sourceElement.select();
    sourceElement.readOnly = 'true';
  }
  document.execCommand('copy');
}
