import React from 'react';
import { hideSpinnerOverlay, setFocus } from '../utility/helper';
import _isFunction from 'lodash/isFunction';
import AppStore from '../stores/AppStore';
import { fetcher } from '../utility/domUtils';
import templateData from '../utility/getTemplateData';

export default function withPostTransactionRequest(
  WrappedComponent,
  postTransactionUrl
) {
  return class WithPostTransactionRequest extends React.Component {
    componentDidMount() {
      this.isComponentMounted = true;
      this.requestPostTransaction();
    }

    requestPostTransaction = async () => {
      const { token } = templateData;
      this.serverRequest = fetcher(postTransactionUrl, {
        method: 'POST',
        body: JSON.stringify({
          token: token,
          onboardingEnabled: templateData.onboardingEnabled,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          hideSpinnerOverlay();
          setFocus();
          if (response.data.confirmationData) {
            if (this.isComponentMounted) {
              // Dont call setState on an unmounted component
              this.setState({
                amount:
                  response.data.confirmationData.amount ||
                  AppStore.getDonationAmount(),
                guestSignUp:
                  response.data.confirmationData.guestSignUp || false,
                displayMyAccount:
                  response.data.confirmationData.showAccountOverview || false,
                confirmationNumber:
                  response.data.confirmationData.confirmationNumber || '',
                receiptId: response.data.confirmationData.receiptId || '',
                recurringConfirmationId:
                  response.data.confirmationData.recurringConfirmationId || '',
              });
            }
          }
        });
    };

    abortRequestPostTransaction = () => {
      this.isComponentMounted = false;
      if (this.serverRequest && _isFunction(this.serverRequest.abort)) {
        this.serverRequest.abort();
      }
    };

    componentWillUnmount = () => {
      this.abortRequestPostTransaction();
    };

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };
}
