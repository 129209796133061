import { getInitialData } from './formator';
import get from 'lodash/get';
import getContent from 'pp-react-l10n';

const cache = {};

export const getTouchpoint = (touchpointCode) => {
  if (cache[touchpointCode]) {
    return cache[touchpointCode];
  }
  const personalizedMessages = getInitialData().personalizedMessages || [];

  for (const touchpoint of personalizedMessages) {
    if (touchpoint.touchpoint_code === touchpointCode) {
      cache[touchpointCode] = touchpoint;
      return touchpoint;
    }
  }
  return null;
};

export const getPersonalizedMessages = (
  touchpointCode,
  { merge = false, isHtml = true, includeImpressionUrl = false } = {}
) => {
  const touchpoint = getTouchpoint(touchpointCode);
  if (!touchpoint || !touchpoint.messages) {
    return merge ? [] : {};
  }

  if (merge) {
    const messages = [];
    touchpoint.messages.forEach((message) => {
      if (message.content?.json) {
        Object.keys(message.content.json)
          .sort()
          .forEach((contentKey) => {
            if (isHtml) {
              messages.push(
                getContent(
                  `personalized.${touchpointCode}.${contentKey}`,
                  null,
                  {
                    useHTML: true,
                  }
                ),
                ' '
              );
            } else {
              messages.push(message.content.json[contentKey]);
            }
          });
      }
    });
    return messages;
  }

  let messages = {};
  touchpoint.messages.forEach((message) => {
    if (message.content?.json) {
      Object.keys(message.content.json).forEach((contentKey) => {
        messages[contentKey] = isHtml
          ? getContent(`personalized.${touchpointCode}.${contentKey}`, null, {
              useHTML: true,
            })
          : message.content.json[contentKey];
      });
    }
    if (includeImpressionUrl) {
      messages = {
        content: messages,
        trackingDetails: {
          impressionUrl: message.tracking_details.impression_url,
        },
      };
    }
  });
  return messages;
};

export const hasPersonalizedMessages = (touchpointCode) => {
  const touchpoint = getTouchpoint(touchpointCode);
  return touchpoint?.messages[0]?.content?.json;
};

export const getPersonalizedMessagesAsContentLoaderStandard = () => {
  const personalizedMessages = getInitialData().personalizedMessages || [];
  const result = {};
  for (const touchpoint of personalizedMessages) {
    result[touchpoint.touchpoint_code] = get(
      touchpoint,
      'messages[0].content.json',
      {}
    );
  }
  return result;
};
