import {
  formatCurrency,
  formatDate,
  formatNumber,
  formatPhoneNumber,
  getDateMaskFormat,
  getRelativeTime,
  wrNormalizeNumber,
  parsePhoneNumber,
} from './worldReadyUtils';

/**
 * Grabs the griffin metadata
 */
export function __getInitialData() {
  const initialDataNode = document.getElementById('initialData');
  const initialDataJSON =
    (initialDataNode && initialDataNode.innerHTML) || '{}';
  try {
    // parse the JSON strings to JavaScript objects
    return JSON.parse(initialDataJSON);
  } catch (e) {
    console.warn('ERROR:donate:initialData', e);
    return {};
  }
}

/**
 * getCurrencyParts
 */
export function getCurrencyParts() {
  return __getInitialData().currencyParts;
}

/**
 * getInitialData
 */
export function getInitialData() {
  return __getInitialData();
}

/**
 * Formats getAmountPlaceHolder
 * {string} placeholder
 */
export function getAmountPlaceHolder() {
  const allParts = __getInitialData().currencyParts || [];
  const amount = allParts.find((item) => item.$ === 'amount');
  return (amount && amount._) || '';
}

export function formatNormalizedAmount(amount) {
  if (
    isNaN(amount.value) ||
    !amount.currency ||
    (!amount.value && amount.value !== 0)
  ) {
    return '';
  }
  return formatCurrency(Number(amount.value), { currency: amount.currency });
}

/**
 * formatAmount
 * {amount} { currency, value }
 */
export function formatAmount(amount) {
  if (!amount.currency || (!amount.value && amount.value !== 0)) {
    return '';
  }
  const amountValue =
    typeof amount.value === 'number'
      ? amount.value
      : wrNormalizeNumber(amount.value);
  return formatCurrency(amountValue, {
    currency: amount.currency,
    style: amount.style || 'symbol',
  });
}

/**
 * Uses Griffin to format a voucher offer detail percentage
 * @param {string} value percentage value
 */
export function formatPercentage(value) {
  return formatNumber(Number(value), { style: 'percent' });
}

export function formatCurrencyNoDecimals(value) {
  if (!value) {
    return '';
  }

  return formatNumber(Number(value), { maximumFractionDigits: 0 });
}

export function formatCurrencyAsString(params) {
  if (!params.value || !params.currency) {
    return '';
  }

  // for campaigns, params.value is comming as a number, so we need to convert to string.
  const options = {
    currency: params.currency,
    maximumFractionDigits: params.maximumFractionDigits || 2,
    style: 'international',
  };
  const numericAmount = Number(params.value);
  return formatCurrency(numericAmount, options);
}

/**
 * normalizer number
 * {number} {amount}
 * https://github.paypal.com/Globalization-R/griffin-paypal/blob/develop/lib/client/README.md#normalizenumber
 */
export function normalizeNumber(amount) {
  if (typeof amount === 'number') {
    return amount;
  }
  if (!amount && amount !== 0) {
    return '';
  }
  return wrNormalizeNumber(amount);
}

/**
 * formatInputAmount
 * {string} amount
 */
export function formatInputAmount(amount) {
  if (!amount) {
    return '';
  }
  return wrNormalizeNumber(amount);
}

/**
 * Uses griffin to format phone number
 *
 * @param  {String} countryCode    country phone code. Eg: '1', '41', '91'
 * @param  {String} nationalNumber Phone number Eg: '012312', '418419'
 * @return {String}                Formatted phone number Eg: '(418) 400 1234'
 */
export function formatPhone(phoneRegion, countryCode, nationalNumber) {
  if (!nationalNumber || !countryCode || !phoneRegion) {
    return '';
  }
  const options = { phoneRegion };
  const phoneObject = { countryCode, nationalNumber };

  return formatPhoneNumber(options, phoneObject);
}

export function validatePhone(phoneRegion, nationalNumber) {
  try {
    const normalizedPhone = parsePhoneNumber({ phoneRegion }, nationalNumber);

    if (!normalizedPhone || normalizedPhone.errors) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Removes country code from phoneNumber
 * Eg:
 * Input: "+49 6645878508"
 * Output: "6645878508"
 */
export function getPhoneNumberWithoutCountryCode(phoneNumber) {
  if (!phoneNumber || typeof phoneNumber !== 'string') {
    return phoneNumber;
  }

  const firstCharacter = phoneNumber[0];
  if (firstCharacter !== '+') {
    // No country code
    return phoneNumber;
  }

  const firstSpaceIndex = phoneNumber.indexOf(' ');
  // No Space !! Malformed?
  if (firstSpaceIndex === -1) {
    return phoneNumber;
  }

  // First space is last character?
  if (phoneNumber.length < firstSpaceIndex + 1) {
    return phoneNumber;
  }

  return phoneNumber.substring(firstSpaceIndex + 1);
}

export function getDateFormat() {
  return getDateMaskFormat({ style: 'date-short' });
}

export function formatCurrencyWithSymbol(params) {
  return formatCurrency(params.value, { currency: params.currency });
}

export function formatRelativeDate(date) {
  const utcDate = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  );
  return getRelativeTime(utcDate, {
    unit: 'day',
    timeZone: 'Etc/UTC',
  });
}

export function getRemainingDays(date) {
  const now = new Date();
  const utcDate = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  );

  return Math.ceil((now - utcDate) / (1000 * 3600 * 24));
}

export function roundUpAndFormatCurrency(amount, currency) {
  const roundedUpValue = Math.ceil(amount);
  return formatCurrency(roundedUpValue, { currency, maximumFractionDigits: 0 });
}

// Format date object to short format string, e.g.: 6/4/22
export function parseDateOnly(date) {
  date.setHours(0, 0, 0, 0);
  return formatDate(date, { timeZone: 'Etc/UTC' });
}
