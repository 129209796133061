/*
 * Returns the interval between navigationStart and current time
 */
const getTimeToInteract = () => {
  const startTime = window?.performance?.timing?.navigationStart;
  // send the difference only when startTime is available

  return startTime ? Date.now() - startTime : 0;
};

/*
 * Function that returns the page load time
 */
const getPageLoadTime = () => {
  const time = window?.performance?.timing || {};

  // send the difference only when time is available
  return time ? time.loadEventEnd - time.navigationStart : 0;
};

const getDeviceAndNetworkInfo = () => {
  const networkInfo = window?.navigator?.connection || {};

  return {
    screen_width: window?.innerWidth || document?.documentElement?.clientWidth, // fallback to clientWidth to support ie
    screen_height:
      window?.innerHeight || document?.documentElement?.clientHeight, // fallback to clientHeight to support ie
    network_effectiveType: networkInfo?.effectiveType, // 2g, 3g, wifi , etc calculated from downlink and etc
  };
};

/**
 *
 * @param { action, uicomp, uitype, page } params
 */

export const logPerformance = (params) => {
  if (!window?.PAYPAL?.analytics?.logPerformance) {
    return;
  }

  const deviceAndNetworkInfo = getDeviceAndNetworkInfo(),
    logPerformanceData = {
      ...params,
      tt: getPageLoadTime(), // tt - The whole process of navigation and page load
      view: {
        t11: getTimeToInteract(),
        et: deviceAndNetworkInfo?.network_effectiveType, // network connection type
        pt: document.title, // page title
        ru: document.referrer, // referrer
        cd: window.screen.colorDepth, // screenColorDepth
        sw: window.screen.width, // screenWidth
        sh: window.screen.height, // screenHeight
        dw: window.screen.width * (window.devicePixelRatio || 1), // deviceWidth
        dh: window.screen.height * (window.devicePixelRatio || 1), // deviceHeight
        bw: deviceAndNetworkInfo?.screen_width, // browserWidth
        bh: deviceAndNetworkInfo?.screen_height, // browserHeight
      },
    };

  try {
    window.PAYPAL.analytics.logPerformance(logPerformanceData);
  } catch (error) {
    // Error populating log performance data
  }
};

export const startCustomCPLTrackingForConfirmationPage = () => {
  const page = `${window.fpti.page.split('success:')[0]}confirmationView:web:`;
  window.PAYPAL.analytics.startCPLTracking({
    page,
    action: 'confirmation_page_loaded',
    uicomp: 'confirmationView',
    uitype: 'page',
  });
};

export const endCustomCPLTrackingForConfirmationPage = () => {
  const page = `${window.fpti.page.split('success:')[0]}confirmationView:web:`;
  window.PAYPAL.analytics.endCPLTracking({
    page,
    action: 'confirmation_page_loaded',
    uicomp: 'confirmationView',
    uitype: 'page',
  });
};
