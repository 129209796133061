import React from 'react';
import PropTypes from 'prop-types';
import { CausesIcon } from '@paypalcorp/pp-react-icons';
import { isPPCampaign } from '../../utility/productIntegrationUtils';

const bannerType = {
  logoBanner: 'logo-banner', // Styles for banner with logo or Avatar
  banner: 'banner', // Styles for banner without logo or avatar
  backgroundBanner: 'bg-banner', // Styles for banner without logo or avatar
  bannerv2: 'banner-v2', // Styles for banner without logo or avatar after repaint for PPUI 2.0.
  mobileBanner: 'mobile-banner',
};

const BannerHeader = ({ type, children }) => {
  return <div className={bannerType[type]}>{children}</div>;
};

export const BackgroundBanner = ({ bannerURL }) => {
  return <img className="bg-banner-image" src={bannerURL} />;
};

export const Banner = ({ bannerURL }) => {
  return (
    <section
      className="banner-img"
      style={{
        backgroundImage: `url(${bannerURL})`,
      }}
    />
  );
};

export const Avatar = ({ logoURL, initialLogo, initialColor }) => {
  if (logoURL !== '') {
    return <ImgLogo logoURL={logoURL} />;
  }
  if (isPPCampaign()) {
    return (
      <InititalsLogo initialLogo={initialLogo} initialColor={initialColor} />
    );
  }
  return <IconLogo />;
};

const ImgLogo = ({ logoURL }) => {
  return (
    <div className="avatar-rounded-container">
      <img className="avatar" src={logoURL} aria-hidden="true" />
    </div>
  );
};

const InititalsLogo = ({ initialLogo, initialColor }) => {
  return (
    <div
      className={`avatar-rounded-container avatar-logo logo-${initialColor}-bg`}
      aria-hidden="true"
    >
      <span className="logo-initials">{initialLogo}</span>
    </div>
  );
};

const IconLogo = () => {
  return (
    <div
      className="avatar-rounded-container avatar-logo logo-pink-bg"
      aria-hidden="true"
    >
      <CausesIcon className="logo-icon" />
    </div>
  );
};

BannerHeader.propTypes = {
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Avatar.propTypes = {
  logoURL: PropTypes.string,
  initialLogo: PropTypes.string,
  initialColor: PropTypes.string,
};

BackgroundBanner.propTypes = {
  bannerURL: PropTypes.string,
};

Banner.propTypes = {
  bannerURL: PropTypes.string,
};

ImgLogo.propTypes = {
  logoURL: PropTypes.string,
};

InititalsLogo.propTypes = {
  initialLogo: PropTypes.string,
  initialColor: PropTypes.string,
};

export default BannerHeader;
