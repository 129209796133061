import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, LinkIcon } from '@paypalcorp/pp-react';
import { StyleSheet, css } from '../../utility/paypalAphrodite';

const styles = StyleSheet.create({
  iconLink: {
    marginRight: '0.375rem',
  },
});

function SocialShareCopyLink({ handleOpenCopyModal, linkTitle }) {
  return (
    <IconButton
      onClick={handleOpenCopyModal}
      className={`${css(styles.iconLink)}`}
      data-testid="social-share-copy-link"
      size="sm"
      buttonType="secondary"
      svgIconComponent={LinkIcon}
    >
      {linkTitle}
    </IconButton>
  );
}

SocialShareCopyLink.propTypes = {
  url: PropTypes.string,
  handleOpenCopyModal: PropTypes.func,
  linkTitle: PropTypes.string,
  color: PropTypes.string,
};

export default SocialShareCopyLink;
