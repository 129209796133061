const keyMirror = require('keymirror');

module.exports = {
  CHANGE_AMOUNT: 'CHANGE_AMOUNT',
  CHANGE_SYMBOL: 'CHANGE_SYMBOL',
  CHANGE_CODE: 'CHANGE_CODE',
  CHANGE_RECURRING: 'CHANGE_RECURRING',
  CHANGE_RECURRING_FREQUENCY: 'CHANGE_RECURRING_FREQUENCY',
  CHANGE_COVER_FEE: 'CHANGE_COVER_FEE',
  CHANGE_FEE_AMOUNT: 'CHANGE_FEE_AMOUNT',
  CHANGE_FORMATED_FEE_AMOUNT: 'CHANGE_FORMATED_FEE_AMOUNT',
  CHANGE_AMOUNT_BEFORE_FEE: 'CHANGE_AMOUNT_BEFORE_FEE',
  CHANGE_ANONYMOUS_STATUS: 'CHANGE_ANONYMOUS_STATUS',
  RP_MODAL_CLOSED: 'RP_MODAL_CLOSED',
  GIFT_AID_IT: 'GIFT_AID_IT',
  CHANGE_FORMATED_AMOUNT: 'CHANGE_FORMATED_AMOUNT',

  CHANGE_NOTE: 'CHANGE_NOTE',

  CHANGE_COUNTRY: 'CHANGE_COUNTRY',

  CHANGE_CARD_NUMBER: 'CHANGE_CARD_NUMBER',
  CHANGE_EXP_DATE: 'CHANGE_EXP_DATE',
  CHANGE_SECURITY_CODE: 'CHANGE_SECURITY_CODE',
  CHANGE_CARD_SPEC: 'CHANGE_CARD_SPEC',

  CHANGE_FIRST_NAME: 'CHANGE_FIRST_NAME',
  CHANGE_LAST_NAME: 'CHANGE_LAST_NAME',

  CHANGE_ADDRESS: 'CHANGE_ADDRESS',

  CHANGE_PHONE: 'CHANGE_PHONE',
  CHANGE_EMAIL: 'CHANGE_EMAIL',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',

  SHOW_LANGUAGE_SELECTOR: 'SHOW_LANGUAGE_SELECTOR',

  UPDATE_CONFIRMATION: 'UPDATE_CONFIRMATION',

  POST_BACK_DATA: 'POST_BACK_DATA',

  UPDATE_CURRENT_PAGE: 'UPDATE_CURRENT_PAGE',

  UPDATE_SERVER_ERRORS: 'UPDATE_SERVER_ERRORS',

  PAYMENT_ERROR: 'PAYMENT_ERROR',

  UPDATE_SIGN_UP_ERROR: 'UPDATE_SIGN_UP_ERROR',

  FUNDING_OPTIONS: 'FUNDING_OPTIONS',

  EMAIL: 'EMAIL',

  BILLING_ADDRESS: 'BILLING_ADDRESS',

  UPDATE_DONATION_AMOUNT_TYPE: 'UPDATE_DONATION_AMOUNT_TYPE',

  ENABLE_PRESET_AMOUNTS: 'ENABLE_PRESET_AMOUNTS',

  CHANGE_SHARE_ADDRESS: 'CHANGE_SHARE_ADDRESS',

  ADD_CARD_ERROR: 'ADD_CARD_ERROR',

  UPDATE_SELECTED_PROGRAM: 'UPDATE_SELECTED_PROGRAM',

  SAVE_FORM_DATA: 'SAVE_FORM_DATA',

  UPDATE_THREEDS_DATA: 'UPDATE_THREEDS_DATA',

  UPDATE_SELECTED_PRESET_BUTTON_ID: 'UPDATE_SELECTED_PRESET_BUTTON_ID',

  UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',

  UPDATE_PPCC_STATUS: 'UPDATE_PPCC_STATUS',

  EMAIL_REX:
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+\/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/,

  DEFAULT_CARD_SPEC: {
    type: 'card',
    fields: {
      ccNumber: {
        required: true,
        pattern: '4[0-9]{12}(?:[0-9]{3})?',
        maxlength: 19,
      },
      expirationDate: {
        required: true,
        pattern: '(0[1-9]|1[012])[/]((20)[0-9]{2}|[0-9]{2})',
        maxlength: 7,
      },
      csc: {
        required: true,
        pattern: '[0-9]*',
        maxlength: 3,
        minlength: 3,
      },
    },
  },
  DONATE_GIFTAID_IT_EXTRA_AMOUNT: 1.25,

  PPGF_DISCLAIMER_TOUCHPOINT: 'Donate_PPGF_Disclaimers_Touchpoint',
  GN_CONTENT_TOUCHPOINT: 'Donate_GN_Content_Touchpoint',
  NNA_CONTENT_TOUCHPOINT: 'Donate_NNA',

  PRODUCT: {
    BRANDED_CAMPAIGN: 'BRANDED_CAMPAIGN',
    GENEROSITY_NETWORK: 'GENEROSITY_NETWORK',
    GENEROSITY_NETWORK_CHARITIES: 'GENEROSITY_NETWORK_CHARITIES',
    PP_FUNDRAISING: 'PP_FUNDRAISING',
    DONATE_BUTTON_CAMPAIGN: 'DONATE_BUTTON_CAMPAIGN',
    WPS_DONATION: 'WPS_DONATION',

    // externalIntegeration
    PAYPAL_FUNDRAISER_LISTED_CAMPAIGN: 'PAYPAL_FUNDRAISER_LISTED_CAMPAIGN',
    PAYPAL_FUNDRAISER_UNLISTED_CAMPAIGN: 'PAYPAL_FUNDRAISER_UNLISTED_CAMPAIGN',
  },

  // Post payment product type identifiers
  PRODUCT_TYPE: {
    DBUTTON: 'DBUTTON',
    DBUTTONC: 'DBUTTONC',
    SEND_MONEY_RECURRING_DONATION: 'SEND_MONEY_RECURRING_DONATION',
    DONATE_BUTTON: 'DONATE_BUTTON',
  },

  EVENT_NAME: {
    DW_GIVING_DONATE_SETUP_SCREEN_SHOWN: 'dw_giving_donate_setup_screen_shown',
    DW_GIVING_DONATE_SETUP_COMMENT_PRESSED:
      'dw_giving_donate_setup_comment_pressed',
    DW_GIVING_DONATE_SETUP_NEXT_PRESSED: 'dw_giving_donate_setup_next_pressed',
    DW_GIVING_DONATE_SETUP_DONATE_WITH_CARD_PRESSED:
      'dw_giving_donate_setup_card_pressed',
    DW_GIVING_DONATE_SETUP_TOOLTIP_PRESSED:
      'dw_giving_donate_setup_tooltip_pressed',
    DW_GIVING_DONATE_SETUP_TERMS_PRESSED:
      'dw_giving_donate_setup_terms_pressed',
    DW_GIVING_DONATE_SETUP_DISMISS_PRESSED:
      'dw_giving_donate_setup_dismiss_pressed',
    DW_GIVING_DONATE_SETUP_SHARE_CONTACT_CHECK_PRESSED:
      'dw_giving_donate_setup_share_contact_check_pressed',
    DW_GIVING_DONATE_SETUP_SHARE_CONTACT_UNCHECK_PRESSED:
      'dw_giving_donate_setup_share_contact_uncheck_pressed',
    DW_GIVING_GUEST_FORM_SCREEN_SHOWN: 'dw_giving_guest_form_screen_shown',
    DW_GIVING_GUEST_FORM_DONATE_PRESSED: 'dw_giving_guest_form_donate_pressed',
    DW_GIVING_GUEST_FORM_DISMISS_PRESSED:
      'dw_giving_guest_form_dismiss_pressed',
    DW_GIVING_MEMBER_REVIEW_DISMISS_PRESSED:
      'dw_giving_member_review_dismiss_pressed',
    DW_GIVING_PAYMENT_SUCCESS_DISMISS_PRESSED:
      'dw_giving_payment_success_dismiss_pressed',
    DW_GIVING_PAYMENT_SUCCESS_DONE_PRESSED:
      'dw_giving_payment_success_done_pressed',
    DW_GIVING_PAYMENT_SUCCESS_SCREEN_SHOWN:
      'dw_giving_payment_success_screen_shown',
    DW_GIVING_PAYMENT_PENDING_SCREEN_SHOWN:
      'dw_giving_payment_pending_screen_shown',
    DW_GIVING_PAYMENT_SUCCESS_SHARE_PRESSED:
      'dw_giving_payment_success_share_pressed',
    DW_GIVING_SHARE_SCREEN_SHOWN: 'dw_giving_share_screen_shown',
    DW_GIVING_SHARE_CHANNEL_PRESSED: 'dw_giving_share_channel_pressed',
    DW_GIVING_SHARE_DISMISS_PRESSED: 'dw_giving_share_dismiss_pressed',
    DW_GIVING_REDIRCT_SCREEN_SHOWN: 'dw_giving_redirct_screen_shown',
    DW_GIVING_REDIRCT_OPEN_PRESSED: 'dw_giving_redirct_open_pressed',
    DW_GIVING_REDIRCT_DISMISS_PRESSED: 'dw_giving_redirct_dismiss_pressed',
    DW_GIVING_FAILED_CUSTOMER_INTERACTION_OCCURRED:
      'dw_giving_failed_customer_interaction_occurred',
    DONATION_PAYMENT_VIEW_LANDING_DONATE_PRESSED:
      'donate_donor_view_landing_donate_pressed',
    DONATION_PAYMENT_VIEW_LANDING_PAYPAL_PRESSED:
      'donate_donor_view_embed_page_donate_with_paypal_pressed',
    DONATION_PAYMENT_VIEW_LANDING_CARD_PRESSED:
      'donate_donor_view_embed_page_donate_with_card_pressed',
    DONATION_PAYMENT_VIEW_LANDING_FREQUENCY_PRESSED:
      'donate_donor_view_embed_page_frequency_pressed',
    DONATION_PAYMENT_GUEST_FORM_DONATE_PRESSED:
      'donate_donor_view_guest_form_donate_pressed',
    DONATION_ANONYMOUS_CHECKBOX_CHECK_PRESSED:
      'donate_donor_view_share_contact_check_pressed',
    DONATION_ANONYMOUS_CHECKBOX_UNCHECK_PRESSED:
      'donate_donor_view_share_contact_uncheck_pressed',
    DONATION_CONFIRMATION_PAGE_IMP:
      'donate_donor_view_payment_success_screen_shown',
    DONATE_THREE_DS_DDC_START: 'donate_three_ds_ddc_start',
    DONATE_THREE_DS_DDC_COMPLETE: 'donate_three_ds_ddc_complete',
    DONATE_THREE_DS_DDC_FAILURE: 'donate_three_ds_ddc_failure',
    DONATE_THREE_DS_LOOKUP_START: 'donate_three_ds_lookup_start',
    DONATE_THREE_DS_LOOKUP_COMPLETE: 'donate_three_ds_lookup_complete',
    DONATE_THREE_DS_IFRAME_SHOWN: 'donate_three_ds_iframe_shown',
    DONATE_THREE_DS_PROOCEED_TO_PAYMENT: 'donate_three_ds_proceed_to_payment',
    DONATE_LANDING_SCREEN_SHOWN: 'donate_donor_view_landing_screen_shown',
    DONATE_REVIEW_SCREEN_SHOWN: 'donate_donor_view_payment_review_screen_shown',
    DONATE_GUEST_FORM_SCREEN_SHOWN: 'donate_donor_view_guest_form_screen_shown',
    DONATE_SUCCESS_SHARE_MESSAGE:
      'donate_donor_view_payment_success_share_pressed',
    DONATE_SUCCESS_MERCHANT_SITE_PRESSED:
      'donate_donor_view_payment_success_merchant_site_pressed',
    DONATE_LANDING_MERCHANT_SITE_PRESSED:
      'donate_donor_view_landing_merchant_site_pressed',
    DONATE_GUEST_REVIEW_PROCESS_DONATE_CLICK:
      'donate_donor_view_payment_review_donate_pressed',
    DONATE_GENERIC_SOCIAL_SHARE_SCREEN_SHOWN:
      'donate_donor_view_generic_social_share_screen_shown',
    DONATE_MATCH_SOCIAL_SHARE_SCREEN_SHOWN:
      'donate_donor_view_match_social_share_screen_shown',
    DONATE_LANDING_UPDATES_MODULE_SHOWN:
      'donate_donor_view_landing_updates_module_shown',
    DONATE_LANDING_UPDATES_READ_MORE_PRESSED:
      'donate_donor_view_landing_updates_read_more_pressed',
    DONATE_LANDING_UPDATES_SHOW_MORE_PRESSED:
      'donate_donor_view_landing_updates_show_more_pressed',
    DONATE_LANDING_UPDATES_LIKE_PRESSED:
      'donate_donor_view_landing_updates_like_pressed_start',
    DONATE_LANDING_UPDATES_LIKE_PRESSED_COMPLETED:
      'donate_donor_view_landing_updates_like_pressed_completed',
    DONATE_LANDING_UPDATES_LIST_SHOWN:
      'donate_donor_view_updates_list_screen_shown',
    DONATE_LANDING_UPDATES_LIST_LIKE_PRESSED:
      'donate_donor_view_updates_list_like_pressed',
    DONATE_LANDING_UPDATES_LIST_SELECTION_PRESSED:
      'donate_donor_view_updates_list_selection_pressed',
    DONATE_LANDING_UPDATES_LIST_DISMISS_PRESSED:
      'donate_donor_view_updates_list_dismiss_pressed',
    DONATE_LANDING_UPDATES_DETAIL_VIEW_SHOWN:
      'donate_donor_view_updates_detail_screen_shown',
    DONATE_LANDING_UPDATES_DETAIL_LIKE_PRESSED:
      'donate_donor_view_updates_detail_like_pressed',
    DONATE_LANDING_UPDATES_DETAIL_DISMISS_PRESSED:
      'donate_donor_view_updates_detail_dismiss_pressed',
    DONATE_LANDING_UPDATES_LOGIN_VIEW_SHOWN:
      'donate_donor_view_updates_login_screen_shown',
    DONATE_LANDING_UPDATES_LOGIN_BUTTON_PRESSED:
      'donate_donor_view_updates_login_log_in_pressed',
    DONATE_LANDING_UPDATES_LOGIN_SIGNUP_PRESSED:
      'donate_donor_view_updates_login_sign_up_pressed',
    DONATE_LANDING_UPDATES_LOGIN_DISMISS_PRESSED:
      'donate_donor_view_updates_login_dismiss_pressed',
    DONATE_LANDING_UPDATES_LOADING_ERROR_SHOWN:
      'donate_donor_view_updates_loading_error_shown',
    DONATE_LANDING_UPDATES_LOADING_ERROR_RETRY_PRESSED:
      'donate_donor_view_updates_loading_error_retry_pressed',
    DONATE_DONOR_VIEW_POST_DONATION_SET_FAV_CHARITY_MODULE_PRESSED:
      'donate_donor_view_post_donation_set_fav_charity_module_pressed',
    DONATE_DONOR_VIEW_POST_DONATION_CREATE_FUNDRAISER_MODULE_PRESSED:
      'donate_donor_view_post_donation_create_fundraiser_module_pressed',
    DONATE_DONOR_VIEW_POST_DONATION_INSIGHTS_MODULE_PRESSED:
      'donate_donor_view_post_donation_insights_module_pressed',
    DONATE_DONOR_VIEW_POST_DONATION_SET_FAVORITE_CHARITY_ERROR:
      'donate_donor_view_post_donation_set_favorite_charity_error',
    DONATE_DONOR_VIEW_POST_DONATION_CREATE_FUNDRAISER_MODAL_SHOWN:
      'donate_donor_view_post_donation_create_fundraiser_modal_shown',
    DONATE_DONOR_VIEW_POST_DONATION_CREATE_FUNDRAISER_MODAL_PRESSED:
      'donate_donor_view_post_donation_create_fundraiser_modal_pressed',
    DONATE_DONOR_VIEW_REPORT_MERCHANT_PRESSED:
      'donate_donor_view_report_merchant_pressed',
  },

  SCREEN_NAME: {
    DW_GIVING_DONATE_SETUP_SCREEN: 'dw_giving_donate_setup_screen',
    DW_GIVING_DONATE_GUEST_FORM_SCREEN: 'dw_giving_donate_guest_form_screen',
  },

  FPTI_PRODUCT: 'dw_giving',
  KEY_DOWN: {
    TAB: 9,
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    SPACE: 32,
    ENTER: 13,
  },

  AU_CRS: {
    STATUS: {
      ABORTED: 'ABORTED',
      FAILURE: 'FAILURE',
      SUCCESS: 'SUCCESS',
    },
    ABORTED_STATUS_CODE: ['DOC_UPLOAD_PENDING', 'INFO_NOT_COLLECTED'],
    FAILURE_STATUS_CODE: [
      'INVALID_REQUEST',
      'NOT_APPLICABLE',
      'SERVICE_UNAVAILABLE',
      'THIRD_PARTY_VERIFICATION_FAIL',
      'UNAUTHORIZED',
    ],
    SUCCESS_STATUS_CODE: ['COMPLETED', 'VERIFIED'],
    SINGLE_TAX_RESIDENCY: 'tax_inside_legal_country',
    MULTI_TAX_RESIDENCY: 'tax_outside_legal_country',
  },
  ALLOWED_IMAGE_TYPES: [
    'image/gif',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/bmp',
  ],
  MAX_IMAGE_SIZE: 12582912,
  KILOBYTE: 1024,
  MEGABYTE: 1048576,
  BTN_IMAGE_MAX_FILE_SIZE: 2000000, // 2MB;
  ALLOWED_FILE_EXTENSIONS: ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'tiff', 'tif'],
  IMG_SCALE_SIZE: {
    WIDTH_STEP_1: 300,
    WIDTH_BANNER: 750,
    WIDTH_LOGO: 400,
    WIDTH_CAMPAIGN: 3000,
  },
  INSTRUMENT_TYPES: {
    HOLDING: 'HOLDING',
    PAYMENT_CARD: 'PAYMENT_CARD',
    PAYMENT_TOKEN: 'PAYMENT_TOKEN',
    BANK_ACCOUNT: 'BANK_ACCOUNT',
    CREDIT: 'CREDIT',
    UNKNOWN: 'UNKNOWN',
  },
  PAYMENT_CARDS: keyMirror({
    VISA: null,
    MASTERCARD: null,
    MASTER_CARD: null,
    MAESTRO: null,
    AMEX: null,
    DISCOVER: null,
    JCB: null,
    CHINA_UNION_PAY: null,
  }),
  CDN_HOST_NAME: 'https://www.paypalobjects.com',
  MERCHANT_PPCC_CONFIRMED_STATUS: 'CONFIRMED',
  FULL: 'CTRL',
  VARIANT_VALUEPROP_1: 'TRMT_1',
  VARIANT_VALUEPROP_2: 'TRMT_2',
  VARIANT_VALUEPROP_3: 'TRMT_3',
  APP_ID: '321983581337375',
  ORGANIZER_UPDATES: {
    INITIAL_UPDATES_COUNT: 3, // initial number of updates to show on overview page
    MAX_UPDATES_PER_PAGE: 30, // max updates returned by FRS API per page
    MAX_CHARACTERS_SUBJECT: 50, // max number of characters in the subject of an update
    MAX_ORGANIZER_UPDATES: 200, // max number of updates allowed for an account
    ACTIONS: {
      SHOW_UPDATE_DETAIL_VIEW: 'showUpdateDetailView',
      SHOW_UPDATE_LIST: 'showUpdatesList',
      ORGANIZER_UPDATE_SINGLE_UPDATE_ERROR: 'showSingleUpdateError',
    },
  },
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  DNW_UNHANDLED_ERROR: 'DNW_UNHANDLED_ERROR',
  POST_DONATION_MODULES: {
    SET_FAVORITE_CHARITY: 'SET_FAVORITE_CHARITY',
    CREATE_FUNDRAISER: 'CREATE_FUNDRAISER',
    GIVING_SUMMARY: 'GIVING_SUMMARY',
    SHARE: 'SHARE',
  },
};
