import React from 'react';
import { CaptionText, Col, Row } from '@paypalcorp/pp-react';
import PropTypes from 'prop-types';

function ConfirmationTransactionId(props) {
  return (
    <Row>
      <Col id="transactionId" xs={12} className="text-center">
        <CaptionText className="transaction-id-text">
          {props.transactionId}
        </CaptionText>
      </Col>
    </Row>
  );
}

ConfirmationTransactionId.propTypes = {
  transactionId: PropTypes.string,
};

export default ConfirmationTransactionId;
