import React from 'react';
import PropTypes from 'prop-types';
import templateData from '../../utility/getTemplateData';
import { showSpinnerOverlay } from '../../utility/helper';
import { fetcher } from '../../utility/domUtils';
import _get from 'lodash/get';
import { isInContext } from '../../utility/inContextDonation';
import { isExternalFlow } from '../../utility/productIntegrationUtils';
import isEmpty from 'lodash/isEmpty';
import { KEY_DOWN } from '../../utility/constants';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';

class PostTransaction extends React.Component {
  constructor(props) {
    super(props);
    const { postdata = {}, syncReturnUrl = '' } = this.props || {};

    let postBackArray = [];

    Object.keys(postdata).forEach(function (key) {
      postBackArray.push({
        name: key,
        value: postdata[key],
      });
    });

    this.state = {
      orgName: this.props.orgName,
      redirectMethod: this.props.method,
      redirectUrl: this.props.action,
      syncReturnUrl,
      postBackArray,
      isRedirecting: false,
      allowRedirect: true,
    };
  }

  componentDidMount() {
    const { hideSuccessPage } = templateData;
    const { syncReturnUrl, redirectUrl } = this.state;
    document.addEventListener('mousedown', this.stopRedirect, true);
    document.addEventListener('keydown', this.handleKeyDown, true);

    // Disable redirection to merchant when DNW is in popup mode
    if (!isInContext() && (!isEmpty(syncReturnUrl) || !isEmpty(redirectUrl))) {
      this.setState({
        isRedirecting: true,
      });

      setTimeout(
        () => {
          const { allowRedirect } = this.state;
          if (allowRedirect) {
            this.handleRedirect();
          }
        },
        hideSuccessPage ? 1000 : 30000
      );
    }

    // No need to handle refresh case
    // As user will be automatically redirected back
    // In case if some is required, listening to UPDATE_CONFIRMATION
    // AppDispatcher.register(function(payload) {
    //	 if (payload.action && (payload.action.actionType === 'UPDATE_CONFIRMATION')) {}
    // });
  }

  stopRedirect = () => {
    const { allowRedirect } = this.state;
    if (allowRedirect) {
      this.setState({
        allowRedirect: false,
        isRedirecting: false,
      });
    }
    document.removeEventListener('mousedown', this.stopRedirect, true);
    document.removeEventListener('keydown', this.handleKeyDown, true);
  };

  handleKeyDown = (event) => {
    const keyPressed = event.which;
    if (
      KEY_DOWN.TAB === keyPressed ||
      KEY_DOWN.UP_ARROW === keyPressed ||
      KEY_DOWN.LEFT_ARROW === keyPressed ||
      KEY_DOWN.DOWN_ARROW === keyPressed ||
      KEY_DOWN.RIGHT_ARROW === keyPressed
    ) {
      this.stopRedirect();
    }
  };

  /*
   * handleRedirect
   */
  handleRedirect = () => {
    const { hideSuccessPage } = templateData;

    if (hideSuccessPage) {
      this.redirect();
      return;
    }

    if (this.shouldShowSpinner()) {
      showSpinnerOverlay();
    }
    fetcher('/donate/sessionlogout').finally(() => {
      this.redirect();
    });
  };

  shouldShowSpinner = () => {
    let { syncReturnUrl = '', redirectUrl = '' } = this.state || {};
    if (
      isExternalFlow() &&
      templateData.ismobile &&
      templateData.hideLandingPage &&
      !syncReturnUrl.includes('http') &&
      !redirectUrl.includes('http')
    ) {
      return false;
    } else {
      return true;
    }
  };

  redirect = () => {
    let {
      syncReturnUrl = '',
      redirectUrl = '',
      redirectMethod = '',
    } = this.state || {};

    if (redirectMethod === 'POST' && syncReturnUrl) {
      this.postBackForm.submit();
    } else if (redirectMethod === 'GET' && syncReturnUrl) {
      if (
        syncReturnUrl.indexOf('http') !== 0 &&
        syncReturnUrl.indexOf('//') !== 0 &&
        !isExternalFlow()
      ) {
        syncReturnUrl = '//' + syncReturnUrl;
      }
      window.location.href = syncReturnUrl;
    } else {
      if (
        redirectUrl.indexOf('http') !== 0 &&
        redirectUrl.indexOf('//') !== 0 &&
        !isExternalFlow()
      ) {
        redirectUrl = '//' + redirectUrl;
      }
      window.location.href = redirectUrl;
    }
  };

  render = () => {
    if (this.state.orgName && this.state.isRedirecting) {
      const { orgName } = this.state;
      const redirectText = new MessageFormat(this.context, {
        id: 'confirmation.postTransaction.label.redirectText',
      }).format({ orgName });
      document.getElementById('spinnerText').innerHTML = redirectText;
    }

    return (
      <div>
        <form
          name="postBackForm"
          method={this.props.method}
          action={this.props.action}
          ref={(form) => {
            this.postBackForm = form;
          }}
        >
          {' '}
          {this.state.postBackArray.map(function (obj) {
            return (
              <input
                type="hidden"
                key={obj.name}
                name={obj.name}
                defaultValue={obj.value}
              />
            );
          })}
        </form>
      </div>
    );
  };
}

PostTransaction.propTypes = {
  method: PropTypes.string,
  action: PropTypes.string,
  orgName: PropTypes.string,
  syncReturnUrl: PropTypes.string.isRequired,
};

PostTransaction.defaultProps = {
  method: 'GET',
};
PostTransaction.contextType = WorldReadyContext;

export default PostTransaction;
