import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Illustration from '../components/illustrations/Illustration';
import {
  isPPCampaign,
  isGNC,
  isPPFundraising,
} from '../utility/productIntegrationUtils';
import BannerHeader, { Banner } from '../components/GN/bannerHeader';
import templateData from '../utility/getTemplateData';
import classNames from 'classnames';
import {
  isMobileOrInContext,
  isInContext,
  isMobileOrTablet,
} from '../utility/inContextDonation';

export class ConfirmationCheckmark extends Component {
  illustrationSize() {
    return classNames({
      max: isMobileOrTablet(),
      extraLarge: isPPFundraising(),
      extraLargeInContext: isInContext(),
      extraLargeDWEB: !isPPFundraising() && !isMobileOrInContext(),
    });
  }

  render() {
    return (
      <div>
        <input
          type="hidden"
          id="confirmationNumber"
          defaultValue={this.props.confirmationNumber}
        />
        <div>
          {isPPCampaign() || isGNC() ? (
            <BannerHeader type="banner">
              <Banner bannerURL={templateData.bannerUrl} />
            </BannerHeader>
          ) : (
            <div className="confirmation_img_holder center-block">
              <Illustration
                type="confirmation"
                size={this.illustrationSize()}
                ariaHidden={true}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ConfirmationCheckmark;

ConfirmationCheckmark.propTypes = {
  confirmationNumber: PropTypes.string,
};
