import React from 'react';
import PropTypes from 'prop-types';
import { BodyText } from '@paypalcorp/pp-react-text';
import { Row, Col } from '@paypalcorp/pp-react';
import OfferDetail from './offerDetail';
import OfferSaveButton from './offerSaveButton';
import { Message } from '@paypalcorp/worldready-react';

class OfferConfirmation extends React.Component {
  render() {
    const { offerProgram } = this.props;
    return (
      <div>
        <Row>
          <Col>
            <div className="text-center">
              <BodyText
                style={{
                  textAlign: 'center',
                  margin: '0 auto 24px',
                  maxWidth: '415px',
                }}
              >
                <Message id="confirmation.confirmation.label.offer" />
              </BodyText>
            </div>
          </Col>
        </Row>
        <OfferDetail offerProgram={offerProgram} success={true} />
        <OfferSaveButton
          offerProgram={offerProgram}
          txnStatement={this.props.txnId}
        />
      </div>
    );
  }
}

OfferConfirmation.propTypes = {
  offerProgram: PropTypes.object.isRequired,
  txnId: PropTypes.string,
};

export default OfferConfirmation;
