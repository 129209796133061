import React from 'react';
import PropTypes from 'prop-types';
import { getPersonalizedMessages } from '../utility/personalizedMessages';
import { GN_CONTENT_TOUCHPOINT } from '../utility/constants';
import isNna from '../utility/nna';
import templateData from '../utility/getTemplateData';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
import MessageWithHTML from '../components/MessageWithHtml';

export default function withConfirmationMessages(
  WrappedComponent,
  renderedView
) {
  class CmHOC extends React.Component {
    static propTypes = {
      amount: PropTypes.string,
      confirmationNumber: PropTypes.string,
      giftaidFlag: PropTypes.bool,
      isVoucherFlow: PropTypes.bool,
      orgName: PropTypes.string,
      postBackData: PropTypes.object,
      rp_status: PropTypes.bool,
      signUpStatus: PropTypes.bool,
      receiptId: PropTypes.string,
      isExternalFlow: PropTypes.bool,
      isCampaigns: PropTypes.bool,
      personalizedMessages: PropTypes.object,
      selectedRecurringFrequency: PropTypes.string,
    };

    isSuccessView = () => renderedView === 'success';

    getGiftAidConfText = () => {
      let giftaidFrequencyLabel;
      switch (this.props.selectedRecurringFrequency) {
        case 'Week':
          giftaidFrequencyLabel = 'giftAidRecurringWeek';
          break;
        case 'Year':
          giftaidFrequencyLabel = 'giftAidRecurringYear';
          break;
        default:
          giftaidFrequencyLabel = 'giftAidRecurring';
          break;
      }
      if (this.props.giftaidFlag && this.isSuccessView()) {
        return new MessageFormat(this.context, {
          id: `confirmation.confirmation.label.${
            this.props.rp_status ? giftaidFrequencyLabel : 'giftAid'
          }`,
        }).format();
      }
      return '';
    };

    getYourDonationLabel = (personalizedMessages) => {
      if (this.props.isExternalFlow && personalizedMessages.success_header) {
        return personalizedMessages.success_header.replace(
          '<donationAmount>',
          this.props.amount
        );
      }

      let yourDonation = (
        <MessageWithHTML
          id="confirmation.confirmation.label.yourdonation"
          amount={this.props.amount}
          orgName={
            this.props.isCampaigns
              ? templateData.campaignTitle
              : this.props.orgName
          }
        />
      );

      if (this.props.isVoucherFlow) {
        yourDonation =
          (
            <MessageWithHTML
              id="confirmation.confirmation.label.yourdonation"
              amount={this.props.amount}
              orgName={this.props.orgName}
            />
          ) || yourDonation;
      }

      return yourDonation;
    };

    getYourRPDonationLabel = () => {
      let frequencyLabel;
      switch (this.props.selectedRecurringFrequency) {
        case 'Week':
          frequencyLabel = 'yourRPdonationWeek';
          break;
        case 'Year':
          frequencyLabel = 'yourRPdonationYear';
          break;
        default:
          frequencyLabel = 'yourRPdonation';
          break;
      }
      return (
        <MessageWithHTML
          id={`confirmation.confirmation${
            this.props.isCampaigns
              ? '.label.campaignRPDonation'
              : `.label.${frequencyLabel}`
          }`}
          amount={this.props.amount}
          orgName={this.props.orgName}
        />
      );
    };

    getReturnTextLabel = () => {
      return new MessageFormat(this.context, {
        id: `confirmation.${
          this.isSuccessView ? 'confirmation' : 'pending'
        }.label.returnText`,
      }).format({ orgName: this.props.orgName });
    };

    getReturnTextMemberLabel = () => {
      return !this.isSuccessView
        ? new MessageFormat(this.context, {
            id: 'confirmation.pending.label.returnTextMember',
          }).format({ orgName: this.props.orgName })
        : '';
    };

    getReturnToLabel = () => {
      return new MessageFormat(this.context, {
        id: `confirmation.${
          this.isSuccessView ? 'confirmation' : 'pending'
        }.label.returnto`,
      }).format({ orgName: this.props.orgName });
    };

    getTxnIdLabel = () => {
      let id = this.props.rp_status
        ? this.props.confirmationNumber
        : this.props.receiptId;
      id = id || this.props.confirmationNumber;

      return new MessageFormat(this.context, {
        id: `confirmation.${
          this.isSuccessView ? 'confirmation' : 'pending'
        }.label.txnId`,
      }).format({ txnId: id });
    };

    getSignupProps = () => {
      return {
        signUpStatus: this.props.signUpStatus,
        successMessage:
          isNna(templateData.country) && this.isSuccessView()
            ? new MessageFormat(this.context, {
                id: 'confirmation.confirmation.label.successMessageNna',
              }).format()
            : new MessageFormat(this.context, {
                id: `confirmation.${
                  this.isSuccessView ? 'confirmation' : 'pending'
                }.label.successMessage`,
              }).format(),
        failMessage: new MessageFormat(this.context, {
          id: `confirmation.${
            this.isSuccessView ? 'confirmation' : 'pending'
          }.label.failMessage`,
        }).format(),
      };
    };

    getPersonalizedContent = () => {
      if (this.props.isExternalFlow) {
        const psMessages = getPersonalizedMessages(GN_CONTENT_TOUCHPOINT, {
          isHtml: false,
        });

        if (psMessages.success_subheader) {
          psMessages.success_subheader = psMessages.success_subheader.replace(
            '<donationAmount>',
            this.props.amount
          );
        }
        return psMessages;
      }
      return {};
    };

    getOrgSuccess = () => (
      <MessageWithHTML
        id="confirmation.confirmation.label.orgSuccess"
        orgName={this.props.orgName}
      />
    );

    render() {
      const personalizedMessages = this.getPersonalizedContent();
      const returnText = this.getReturnTextLabel();
      const returnTextMember = this.getReturnTextMemberLabel();
      const returnTo = this.getReturnToLabel();
      const txnId = this.getTxnIdLabel();
      const yourRPdonation = this.getYourRPDonationLabel();
      const giftAidConfText = this.getGiftAidConfText();
      const yourDonation = this.getYourDonationLabel(personalizedMessages);
      const signUpProps = this.getSignupProps();
      const orgSuccess = this.getOrgSuccess();

      return (
        <WrappedComponent
          {...this.props}
          yourDonation={yourDonation}
          returnText={returnText}
          returnTextMember={returnTextMember}
          returnTo={returnTo}
          txnId={txnId}
          yourRPdonation={yourRPdonation}
          giftAidConfText={giftAidConfText}
          signUpProps={signUpProps}
          personalizedMessages={personalizedMessages}
          orgSuccess={orgSuccess}
        />
      );
    }
  }

  CmHOC.contextType = WorldReadyContext;
  return CmHOC;
}
