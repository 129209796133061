import React, { useContext } from 'react';
import templateData from '../utility/getTemplateData';
import { Row, Col } from '@paypalcorp/pp-react';
import ConfirmationBody from './ConfirmationBody';
import ConfirmationTitle from './ConfirmationTitle';
import ConfirmationView from './ConfirmationView';
import PendingWarning from '../components/PendingWarning';
import PropTypes from 'prop-types';
import withConfirmationData from './withConfirmationData';
import withConfirmationMessages from './withConfirmationMessages';
import withTheme from '../components/withTheme';
import isNna from '../utility/nna';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
import isPslCountry from '../utility/israelPsl';
import { isGNC } from '../utility/productIntegrationUtils';

function PendingView(props) {
  const worldReady = useContext(WorldReadyContext);
  const isOfacPending = props.isOfacPending;

  const contentRoute = 'confirmation.pending.label';
  let headingMessageId = `${contentRoute}.pending`;
  let bodyMessageId = `${contentRoute}.pending2`;

  if (isNna(templateData.country)) {
    bodyMessageId = `${contentRoute}.pendingNna`;
  } else if (isPslCountry() && !isGNC()) {
    if (isOfacPending) {
      headingMessageId = `${contentRoute}.ispsl.ofac.heading`;
      bodyMessageId = `${contentRoute}.ispsl.ofac.body1`;
    } else {
      bodyMessageId = `${contentRoute}.ispsl.body`;
    }
  }

  const headingMessage = new MessageFormat(worldReady, {
    id: headingMessageId,
  }).format();
  const bodyMessage = new MessageFormat(worldReady, {
    id: bodyMessageId,
  }).format({
    orgName: templateData.donationName,
  });

  const PendingMessage = () => (
    <>
      <Row>
        <Col xs={12} className="text-center">
          <ConfirmationTitle message={headingMessage} />
        </Col>
      </Row>
      <Row className="pending-content">
        <Col xs={12} className="text-center">
          <ConfirmationBody message={bodyMessage} />
          {isPslCountry() && !isGNC() && isOfacPending && (
            <ConfirmationBody
              message={new MessageFormat(worldReady, {
                id: `${contentRoute}.ispsl.ofac.body2`,
              }).format()}
            />
          )}
        </Col>
      </Row>
    </>
  );

  return (
    <ConfirmationView
      {...props}
      illustration={<PendingWarning />}
      message={<PendingMessage />}
    />
  );
}

PendingView.propTypes = {
  handleRedirect: PropTypes.func,
  orgName: PropTypes.string,
  returnTo: PropTypes.string,
  returnTextMember: PropTypes.string,
  isOfacPending: PropTypes.bool,
};

export default withConfirmationData(
  withConfirmationMessages(withTheme(PendingView), 'pending')
);
