import AppStore from '../../stores/AppStore';
import * as AppActions from '../../actions/AppActions';
import { formatAmount, normalizeNumber } from '../../utility/formator';
import TemplateData from '../../utility/getTemplateData';
import { getParameterByName } from '../../utility/queryStringHelper';
import templateData from '../../utility/getTemplateData';

export const isOptInCoverFee = () => {
  return AppStore.getCoverFeeOptInStatus() === 'checked';
};

export const isAtLandingPage = () => {
  const isGuestReviewPage =
    window.location.pathname.includes('guest') ||
    ![undefined, null].includes(getParameterByName('signup')) ||
    getParameterByName('countryChange');
  return !isGuestReviewPage;
};

export const computeFeeAmount = () => {
  const donationAmount = parseFloat(AppStore.getDonationAmount()),
    flatFee = parseFloat(TemplateData.flatFee),
    feeRate = parseFloat(TemplateData.feeRate) * 0.01;

  if (donationAmount > 0) {
    // X is the full amount the donor needs to pay for the charity to receive $100
    // X - .03X - $0.30 = $100
    // If X is the full amount the donor needs to pay for the charity to receive Y
    // X - feeRate*X - flatFee = Y
    // X = (Y + flatFee) / (1 - feeRate)
    const totalAmountAfterFee = (donationAmount + flatFee) / (1 - feeRate),
      feeAmount = totalAmountAfterFee - donationAmount;
    return feeAmount;
  }

  return 0;
};

export const computeAmountAfterFee = (donationAmount, feeAmount) => {
  const totalAmount = parseFloat(donationAmount) + normalizeNumber(feeAmount);

  return totalAmount.toString();
};

export const setFeeAmountInStore = () => {
  const feeAmount = computeFeeAmount(),
    currencyCode = AppStore.getDonationCode(),
    formatedFeeAmount = formatAmount({
      currency: currencyCode,
      value: feeAmount,
      style: 'international',
    }),
    formatedFeeAmountValue = feeAmount;

  AppActions.changeFeeAmount({ feeAmount: formatedFeeAmountValue });
  AppActions.changeFormatedFeeAmount({
    formatedFeeAmount,
  });
};

export const updateDonationAmount = (amount) => {
  const currencyCode = AppStore.getDonationCode(),
    formatedAmount = formatAmount({
      currency: currencyCode,
      value: amount,
    });
  AppActions.changeAmount({ amount });
  AppActions.changeFormatedAmount({ formatedAmount });
};

export const restoreDonationAmount = () => {
  const rawAmount = AppStore.getAmountBeforeFee() || TemplateData.rawAmount;
  updateDonationAmount(rawAmount.toString());
};

export const optOutCoverFee = () => {
  AppActions.changeCoverFee({ coverFee: 'unchecked' });
  AppActions.changeFeeAmount({ feeAmount: 0 });
  AppActions.changeFormatedAmount({ formatedFeeAmount: '' });
};

export const shouldSelectPresetAmount = (amount) => {
  if (!amount) {
    return false;
  }
  if (templateData.presetAmounts?.length === 3) {
    const presetAmounts = TemplateData.presetAmounts;
    return presetAmounts.map(Number).includes(Number(amount));
  }
};
