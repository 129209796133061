import templateData from '../utility/getTemplateData';
import { isExternalFlow } from '../utility/productIntegrationUtils';
import { EVENT_NAME, SCREEN_NAME } from '../utility/constants';
import {
  CLOSE_SHEET_REASONS,
  DISMISS_METHODS,
  FPTI_TAGS,
  LINK_NAME,
  LOADING_STATES,
  PAGE_SPEC,
} from './fptiConstants';
import {
  getAnonymousStatus,
  getConfirmationData,
  getDefaultPageSpec,
  getDonationAmount,
  getExternalFlowData,
  getFeeData,
  getFeeDataFromTemplateData,
  getFeePayer,
  getLandingPageData,
  getNoteData,
  getRecurringStatus,
  getThreeDSData,
  getToken,
  getUiType,
  updateGlobalRespDuration,
  getSelectedRecurringFrequency,
  updateGlobalFPTI,
  checkAndReplaceInternalErrWithDnwErr,
} from './helper';
import {
  trackPageLoad,
  trackClick,
  trackError,
  trackEventWithData,
} from './analytics';
import isNna from '../utility/nna';
import { isAtLandingPage } from '../components/coverFee/feeUtils';
import { getParameterByName } from '../utility/queryStringHelper';
const {
  country,
  donationAmountEditable,
  selectedPresetButtonId,
  sessionDeprecationEnabled,
} = templateData;

export const trackLandingPageIM = (data) => {
  const shouldTrackExternalFlowData = isExternalFlow();
  const onceData = {
    ...getAnonymousStatus(),
    ...getNoteData(data),
    ...(shouldTrackExternalFlowData
      ? {
          [FPTI_TAGS.EVENT_NAME]:
            EVENT_NAME.DW_GIVING_DONATE_SETUP_SCREEN_SHOWN,
        }
      : { [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATE_LANDING_SCREEN_SHOWN }),
    ...(selectedPresetButtonId
      ? {
          [FPTI_TAGS.SELECTED_PRESET_BUTTON_ID]: selectedPresetButtonId,
        }
      : {}),
    ...getFeeDataFromTemplateData(),
    ...getDonationAmount(),
    ...(data.donationFraudEligibility && {
      donation_fraud_eligible: data.donationFraudEligibility,
    }),
  };

  const globalData = {
    [FPTI_TAGS.UI_TYPE]: getUiType(),
    [FPTI_TAGS.AMOUNT_EDIT_ALLOWED]: donationAmountEditable,
    [FPTI_TAGS.VERSION]: sessionDeprecationEnabled ? 'V2' : 'V1',
    ...getExternalFlowData(),
    ...PAGE_SPEC.LANDING_PAGE,
    ...(getParameterByName('fromUOB') ? { [FPTI_TAGS.SOURCE]: 'uob' } : {}),
  };

  updateGlobalFPTI(globalData);
  trackPageLoad(globalData, onceData);
};

export const trackRecurringFrequencySelectionClick = () => {
  const data = {
    [FPTI_TAGS.EVENT_NAME]:
      EVENT_NAME.DONATION_PAYMENT_VIEW_LANDING_FREQUENCY_PRESSED,
  };
  updateGlobalFPTI({
    ...getRecurringStatus(),
    ...getSelectedRecurringFrequency(),
  });
  trackClick(data);
};

export const trackGuestFormIM = ({ isOnboarding = false } = {}) => {
  const onceData = {
    ...getDonationAmount(),
    ...getRecurringStatus(),
    ...(isExternalFlow()
      ? { [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DW_GIVING_GUEST_FORM_SCREEN_SHOWN }
      : { [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATE_GUEST_FORM_SCREEN_SHOWN }),
    ...(isNna(country) && !isOnboarding
      ? { [FPTI_TAGS.OFFER_TYPE]: 'nna' }
      : {}),
  };
  const globalData = {
    ...(isOnboarding
      ? { ...PAGE_SPEC.ONBOARDING_GUEST_FORM }
      : { ...PAGE_SPEC.GUEST_FORM }),
    ...getSelectedRecurringFrequency(),
  };

  trackPageLoad(globalData, onceData);
};

export const trackMemberReviewPageIM = ({
  status,
  statusCode,
  processStatus,
}) => {
  const fromUOB = getParameterByName('fromUOB');
  const onceData = {};
  const globalData = {
    [FPTI_TAGS.STATUS]: status,
    [FPTI_TAGS.STATUS_CODE]: statusCode,
    [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATE_REVIEW_SCREEN_SHOWN,
    ...(fromUOB ? { [FPTI_TAGS.SOURCE]: 'uob' } : {}),
    ...PAGE_SPEC.MEMBER_REVIEW_PAGE,
    ...getUiType(),
    ...getSelectedRecurringFrequency(),
  };

  if (processStatus) {
    globalData[FPTI_TAGS.STATUS] = 'success';
    globalData[FPTI_TAGS.PROCESS_STATUS] = processStatus;
    globalData[FPTI_TAGS.SOURCE] = 'adroit';
  }

  trackPageLoad(globalData, onceData);
};

export const trackErrorIM = ({
  errorCode,
  errorMessage,
  fieldName,
  pageSpec = getDefaultPageSpec(),
  additionalData,
}) => {
  const data = {
    ...(isExternalFlow()
      ? {
          eventName: EVENT_NAME.DW_GIVING_FAILED_CUSTOMER_INTERACTION_OCCURRED,
          screen: isAtLandingPage()
            ? SCREEN_NAME.DW_GIVING_DONATE_SETUP_SCREEN
            : SCREEN_NAME.DW_GIVING_DONATE_GUEST_FORM_SCREEN,
        }
      : {}),
    [FPTI_TAGS.ERR_FIELD]: fieldName,
    [FPTI_TAGS.ERR_CODE]: errorCode,
    [FPTI_TAGS.ECCD]: errorCode,
    [FPTI_TAGS.ERPG]: errorMessage,
    [FPTI_TAGS.ERR_DESC]: errorMessage,
    ...pageSpec,
    ...getDonationAmount(),
    ...additionalData,
  };
  trackError(data);
};

export const trackThreeDSIframeIM = ({
  threeDSRespDuration,
  confirmationStatus,
}) => {
  const globalRespDuration = window.fpti.resp_duration || {};
  const globalData = {
    ...getThreeDSData(),
    ...PAGE_SPEC.THREE_DS_IFRAME_ONBRD,
    [FPTI_TAGS.THREE_DS_RESP_DURATION]: Object.assign(
      {},
      globalRespDuration,
      threeDSRespDuration
    ),
    [FPTI_TAGS.CHALLENGE_STATUS]: confirmationStatus,
  };
  const onceData = {
    [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATE_THREE_DS_IFRAME_SHOWN,
  };

  trackPageLoad(globalData, onceData);
};

export const trackConfirmationPageIM = ({
  isSignUp,
  pageSpec,
  flow,
  confirmationData,
}) => {
  const globalData = {
    [FPTI_TAGS.IS_SIGNUP]: isSignUp,
    [FPTI_TAGS.ACCOUNT_TYPE]: flow,
    ...getExternalFlowData(),
    ...getConfirmationData(confirmationData),
    ...getToken(),
    ...pageSpec,
  };
  const onceData = {};

  trackPageLoad(globalData, onceData);
};

export const trackSocialShareIM = () => {
  const globalData = {};
  const onceData = {
    [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATE_GENERIC_SOCIAL_SHARE_SCREEN_SHOWN,
  };

  trackPageLoad(globalData, onceData);
};

export const trackPSLContentShow = ({
  isSignUp,
  pageSpec,
  flow,
  confirmationData,
  status,
}) => {
  const globalData = {
    [FPTI_TAGS.IS_SIGNUP]: isSignUp,
    [FPTI_TAGS.ACCOUNT_TYPE]: flow,
    ...getExternalFlowData(),
    ...getConfirmationData(confirmationData),
    ...getToken(),
    ...pageSpec,
    [FPTI_TAGS.EVENT_NAME]: `donate_donor_view_payment_${status}_psl_content_shown`,
  };
  const onceData = {};

  trackPageLoad(globalData, onceData);
};

export const trackCaptureOrderPageIM = ({ flow, confirmationData }) => {
  const pgrp = PAGE_SPEC.CAPTURE_ORDER_PAGE.pgrp.replace('{flow}', flow);
  const onceData = {
    [FPTI_TAGS.PAGE_NAME]: `${pgrp}:web::`,
    [FPTI_TAGS.PAGE_GROUP]: pgrp,
  };
  const globalData = {
    [FPTI_TAGS.ACCOUNT_TYPE]: flow,
    ...getToken(),
    ...getExternalFlowData(),
    ...getConfirmationData(confirmationData),
  };

  trackPageLoad(globalData, onceData);
};

export const trackErrorPageIM = ({ pageSpec, errorCode, additionalData }) => {
  const onceData = {
    [FPTI_TAGS.ERR_CODE]: checkAndReplaceInternalErrWithDnwErr(errorCode),
  };
  const globalData = {
    ...pageSpec,
    ...additionalData,
  };

  trackPageLoad(globalData, onceData);
};

export const trackPageIM = ({ pageSpec, additionalData }) => {
  const onceData = {
    ...additionalData,
  };
  const globalData = {
    ...pageSpec,
  };

  trackPageLoad(globalData, onceData);
};

export const trackAnonymousCheckboxClick = (status) => {
  const data = {
    [FPTI_TAGS.LINK]: LINK_NAME.ANONYMOUS_CHECKBOX,
    [FPTI_TAGS.CHECKBOX]: status,
    [FPTI_TAGS.NONANONYMOUS]: !status,
    ...(isExternalFlow()
      ? {
          [FPTI_TAGS.EVENT_NAME]: status
            ? EVENT_NAME.DW_GIVING_DONATE_SETUP_SHARE_CONTACT_CHECK_PRESSED
            : EVENT_NAME.DW_GIVING_DONATE_SETUP_SHARE_CONTACT_UNCHECK_PRESSED,
        }
      : {
          [FPTI_TAGS.EVENT_NAME]: status
            ? EVENT_NAME.DONATION_ANONYMOUS_CHECKBOX_CHECK_PRESSED
            : EVENT_NAME.DONATION_ANONYMOUS_CHECKBOX_UNCHECK_PRESSED,
        }),
    ...getDonationAmount(),
    ...PAGE_SPEC.LANDING_PAGE,
  };
  trackClick(data);
};

export const trackCoverFeeCheckboxClick = (status) => {
  const data = {
    [FPTI_TAGS.LINK]: LINK_NAME.COVER_FEE_CHECKBOX,
    [FPTI_TAGS.CHECKBOX]: status,
    ...getFeeData(),
    ...getDonationAmount(),
    ...PAGE_SPEC.LANDING_PAGE,
  };
  trackClick(data);
};

export const trackDonateWithCardClick = ({ noteFieldDisplayed, fptiData }) => {
  const data = {
    [FPTI_TAGS.LINK]: 'donateWithCard',
    ...getLandingPageData({ noteFieldDisplayed }),
    ...(isExternalFlow()
      ? {
          [FPTI_TAGS.EVENT_NAME]:
            EVENT_NAME.DW_GIVING_DONATE_SETUP_DONATE_WITH_CARD_PRESSED,
        }
      : {
          [FPTI_TAGS.EVENT_NAME]:
            EVENT_NAME.DONATION_PAYMENT_VIEW_LANDING_CARD_PRESSED,
        }),
    ...fptiData,
    ...getSelectedRecurringFrequency(),
  };
  trackClick(data);
};

export const trackDonateWithPayPalClick = ({
  noteFieldDisplayed,
  fptiData,
}) => {
  const data = {
    ...getLandingPageData({ noteFieldDisplayed }),
    ...(isExternalFlow()
      ? {
          [FPTI_TAGS.EVENT_NAME]:
            EVENT_NAME.DW_GIVING_DONATE_SETUP_NEXT_PRESSED,
        }
      : {
          [FPTI_TAGS.EVENT_NAME]:
            EVENT_NAME.DONATION_PAYMENT_VIEW_LANDING_PAYPAL_PRESSED,
        }),
    [FPTI_TAGS.LINK]: 'donateWithPayPal',
    ...fptiData,
    ...getFeePayer(),
    ...getSelectedRecurringFrequency(),
  };
  trackClick(data);
};

export const trackNextClickForCheckout = ({ noteFieldDisplayed }) => {
  const data = {
    ...getLandingPageData({ noteFieldDisplayed }),
    [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DW_GIVING_DONATE_SETUP_NEXT_PRESSED,
    [FPTI_TAGS.LINK]: 'donateViaCheckout',
  };
  trackClick(data);
};

export const trackSignupChekboxClick = (status, id) => {
  const data = {
    [FPTI_TAGS.LINK]: 'createAccount',
    [FPTI_TAGS.IS_SIGNUP]: status === 'checked',
    ...PAGE_SPEC.GUEST_FORM,
  };

  const target =
    id === 'createAccountSwitch' ? FPTI_TAGS.SWITCH : FPTI_TAGS.CHECKBOX;
  data[target] = status;
  trackClick(data);
};

// This submits the guest form
// after passing all client side validations
export const trackPaymentSubmitClick = ({ isSignUp, isNNA, link }) => {
  const data = {
    ...getDonationAmount(),
    ...getRecurringStatus(),
    [FPTI_TAGS.LINK]: link,
    [FPTI_TAGS.IS_SIGNUP]: isSignUp,
    ...(isNNA
      ? { [FPTI_TAGS.OFFER_TYPE]: 'nna' }
      : { [FPTI_TAGS.OFFER_TYPE]: undefined }),
    ...(isExternalFlow()
      ? {
          [FPTI_TAGS.EVENT_NAME]:
            EVENT_NAME.DW_GIVING_GUEST_FORM_DONATE_PRESSED,
        }
      : {
          [FPTI_TAGS.EVENT_NAME]:
            EVENT_NAME.DONATION_PAYMENT_GUEST_FORM_DONATE_PRESSED,
        }),
    ...(link === LINK_NAME.GUEST_FORM_PAYMENT_SUBMIT_BUTTON
      ? { ...PAGE_SPEC.GUEST_FORM }
      : { ...PAGE_SPEC.ONBOARDING_GUEST_FORM }),
    ...getFeePayer(),
    ...getSelectedRecurringFrequency(),
  };
  trackClick(data);
};

const addGlobalFptiData = (data) => {
  window.fpti = Object.assign({}, window.fpti, data);
};

export const trackOnbrdPaymentSubmitClick = ({ isSignUp, isCvvSent }) => {
  addGlobalFptiData({
    ...getDonationAmount(),
    ...getRecurringStatus(),
    [FPTI_TAGS.IS_SIGNUP]: isSignUp,
    [FPTI_TAGS.ACTION]: '',
    [FPTI_TAGS.IS_CVV_SENT]: isCvvSent,
    ...getFeePayer(),
    ...getSelectedRecurringFrequency(),
  });
};

export const trackLinkClick = (
  linkName,
  { eventName, pageSpec = getDefaultPageSpec(), additionalData } = {}
) => {
  const data = {
    [FPTI_TAGS.LINK]: linkName,
    [FPTI_TAGS.EVENT_NAME]: eventName,
    [FPTI_TAGS.PAGE_LINK]: `${pageSpec?.page}|${linkName}`,
    [FPTI_TAGS.PAGE_GROUP_LINK]: `${pageSpec?.pgrp}|${linkName}`,
    ...pageSpec,
    ...additionalData,
    ...getDonationAmount(),
  };
  trackClick(data);
};

export const trackThreeDsDdcStart = (data) => {
  updateGlobalRespDuration(data);
  trackEventWithData({
    event_name: EVENT_NAME.DONATE_THREE_DS_DDC_START,
    data: { ...getThreeDSData() },
  });
};

export const trackThreeDsDdcComplete = (data) => {
  updateGlobalRespDuration(data);

  trackEventWithData({
    event_name: EVENT_NAME.DONATE_THREE_DS_DDC_COMPLETE,
    data: { ...getThreeDSData() },
  });
};

export const trackThreeDsDdcFailure = (data) => {
  trackEventWithData({
    event_name: EVENT_NAME.DONATE_THREE_DS_DDC_FAILURE,
    data: {
      ...getThreeDSData(),
      ...data,
    },
  });
};

export const trackThreeDsLookupStart = (data) => {
  updateGlobalRespDuration(data);
  trackEventWithData({
    event_name: EVENT_NAME.DONATE_THREE_DS_LOOKUP_START,
    data: { ...getThreeDSData() },
  });
};

export const trackThreeDsLookupComplete = (data) => {
  updateGlobalRespDuration(data);
  if (data?.timestamp) {
    delete data.timestamp;
  }
  trackEventWithData({
    event_name: EVENT_NAME.DONATE_THREE_DS_LOOKUP_COMPLETE,
    data: { ...getThreeDSData(), ...data },
  });
};

export const trackDonateLandingButtonClick = ({ noteFieldDisplayed }) => {
  const data = {
    [FPTI_TAGS.EVENT_NAME]:
      EVENT_NAME.DONATION_PAYMENT_VIEW_LANDING_DONATE_PRESSED,
    ...getLandingPageData({ noteFieldDisplayed }),
    ...getFeePayer(),
  };
  trackClick(data);
};

export const trackGuestReviewPageDonateClick = ({ isCvvSend }) => {
  trackEventWithData({
    event_name: EVENT_NAME.DONATE_GUEST_REVIEW_PROCESS_DONATE_CLICK,
    data: { [FPTI_TAGS.IS_CVV_SENT]: isCvvSend },
  });
};

export const track3DSProceedToPayment = ({ isCvvSend }) => {
  trackEventWithData({
    event_name: EVENT_NAME.DONATE_THREE_DS_PROOCEED_TO_PAYMENT,
    data: { [FPTI_TAGS.IS_CVV_SENT]: isCvvSend },
  });
};

export const trackOrganizerUpdatesLoad = (isPopulated) => {
  const globalData = {};
  const onceData = {
    [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATE_LANDING_UPDATES_MODULE_SHOWN,
    [FPTI_TAGS.STATE]: isPopulated
      ? LOADING_STATES.POPULATED
      : LOADING_STATES.EMPTY,
  };

  trackPageLoad(globalData, onceData);
};

export const trackUpdatesReadMorePressed = () => {
  trackClick({
    [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATE_LANDING_UPDATES_READ_MORE_PRESSED,
  });
};

export const trackUpdatesShowMorePressed = () => {
  trackClick({
    [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATE_LANDING_UPDATES_SHOW_MORE_PRESSED,
  });
};

export const trackUpdatesLikePressed = (
  updateId,
  positiveLike,
  status,
  likesCount = 0
) => {
  trackClick({
    [FPTI_TAGS.EVENT_NAME]:
      status === 'REQUEST_SENT'
        ? EVENT_NAME.DONATE_LANDING_UPDATES_LIKE_PRESSED
        : EVENT_NAME.DONATE_LANDING_UPDATES_LIKE_PRESSED_COMPLETED,
    [FPTI_TAGS.UPDATE_ID]: updateId,
    [FPTI_TAGS.LINK_NAME]: positiveLike ? LINK_NAME.LIKE : LINK_NAME.UNLIKE,
    [FPTI_TAGS.STATUS]: status,
    [FPTI_TAGS.COUNT]: likesCount,
  });
};

export const trackOrganizerUpdatesListIM = (count = 0) => {
  const globalData = {};
  const onceData = {
    [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATE_LANDING_UPDATES_LIST_SHOWN,
    [FPTI_TAGS.COUNT]: count,
  };

  trackPageLoad(globalData, onceData);
};

export const trackUpdatesListSelectionPressed = (updateId) => {
  trackClick({
    [FPTI_TAGS.EVENT_NAME]:
      EVENT_NAME.DONATE_LANDING_UPDATES_LIST_SELECTION_PRESSED,
    [FPTI_TAGS.UPDATE_ID]: updateId,
  });
};

export const trackUpdatesListLikePressed = (updateId, positiveLike) => {
  trackClick({
    [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATE_LANDING_UPDATES_LIST_LIKE_PRESSED,
    [FPTI_TAGS.UPDATE_ID]: updateId,
    [FPTI_TAGS.LINK_NAME]: positiveLike ? LINK_NAME.LIKE : LINK_NAME.UNLIKE,
  });
};

export const trackUpdatesListDismissPressed = (reason) => {
  const dismissMethod =
    reason === CLOSE_SHEET_REASONS.BACKDROP_CLICK
      ? DISMISS_METHODS.BACKGROUND
      : DISMISS_METHODS.CLOSE;
  trackClick({
    [FPTI_TAGS.EVENT_NAME]:
      EVENT_NAME.DONATE_LANDING_UPDATES_LIST_DISMISS_PRESSED,
    [FPTI_TAGS.DISMISS_METHOD]: dismissMethod,
  });
};

export const trackUpdatesDetailDismissPressed = (reason, updateId) => {
  const dismissMethod =
    reason === CLOSE_SHEET_REASONS.BACKDROP_CLICK
      ? DISMISS_METHODS.BACKGROUND
      : DISMISS_METHODS.CLOSE;
  trackClick({
    [FPTI_TAGS.EVENT_NAME]:
      EVENT_NAME.DONATE_LANDING_UPDATES_DETAIL_DISMISS_PRESSED,
    [FPTI_TAGS.DISMISS_METHOD]: dismissMethod,
    [FPTI_TAGS.UPDATE_ID]: updateId,
  });
};

export const trackUpdatesLoginDismissPressed = (reason) => {
  const dismissMethod =
    reason === CLOSE_SHEET_REASONS.BACKDROP_CLICK
      ? DISMISS_METHODS.BACKGROUND
      : DISMISS_METHODS.CLOSE;
  trackClick({
    [FPTI_TAGS.EVENT_NAME]:
      EVENT_NAME.DONATE_LANDING_UPDATES_LOGIN_DISMISS_PRESSED,
    [FPTI_TAGS.DISMISS_METHOD]: dismissMethod,
  });
};

export const trackUpdatesDetailIM = (updateId) => {
  const globalData = {};
  const onceData = {
    [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATE_LANDING_UPDATES_DETAIL_VIEW_SHOWN,
    [FPTI_TAGS.UPDATE_ID]: updateId,
  };

  trackPageLoad(globalData, onceData);
};

export const trackUpdatesLoginIM = () => {
  const globalData = {};
  const onceData = {
    [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DONATE_LANDING_UPDATES_LOGIN_VIEW_SHOWN,
  };

  trackPageLoad(globalData, onceData);
};

export const trackUpdatesLoginButtonPressed = () => {
  trackClick({
    [FPTI_TAGS.EVENT_NAME]:
      EVENT_NAME.DONATE_LANDING_UPDATES_LOGIN_BUTTON_PRESSED,
  });
};

export const trackUpdatesLoginSignupPressed = () => {
  trackClick({
    [FPTI_TAGS.EVENT_NAME]:
      EVENT_NAME.DONATE_LANDING_UPDATES_LOGIN_SIGNUP_PRESSED,
  });
};

export const trackUpdatesLoadingError = () => {
  const globalData = {};
  const onceData = {
    [FPTI_TAGS.EVENT_NAME]:
      EVENT_NAME.DONATE_LANDING_UPDATES_LOADING_ERROR_SHOWN,
  };

  trackPageLoad(globalData, onceData);
};
