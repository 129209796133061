import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@paypalcorp/pp-react';
import { trackLinkClick } from '../fpti/fpti';
import { LINK_NAME } from '../fpti/fptiConstants';

const ReturnToMerchantLink = ({ returnLabel, organizationName, returnUrl }) => {
  const label = returnLabel.replace('{orgName}', organizationName);

  const handleReturnToMerchant = () => {
    trackLinkClick(LINK_NAME.RETURN);

    if (returnUrl.indexOf('http') !== 0 && returnUrl.indexOf('//') !== 0) {
      returnUrl = '//' + returnUrl;
    }
    window.location.href = returnUrl;
  };

  return <Link onClick={handleReturnToMerchant}>{label}</Link>;
};

ReturnToMerchantLink.propTypes = {
  returnLabel: PropTypes.string,
  organizationName: PropTypes.string,
  returnUrl: PropTypes.string,
};

export default ReturnToMerchantLink;
