import React, { useContext, useEffect } from 'react';
import { BodyText, PAYPAL_THEME } from '@paypalcorp/pp-react';
import getSocialCampaign from '../utility/getSocialCampaign';
import TemplateData from '../utility/getTemplateData';
import SocialShare from '../components/socialShare/socialShare';
import _isEmpty from 'lodash/isEmpty';
import { isInContext } from '../utility/inContextDonation';
import {
  isExternalFlow,
  isCampaigns,
} from '../utility/productIntegrationUtils';
import { EVENT_NAME } from '../utility/constants';
import classNames from 'classnames';
import { trackLinkClick, trackSocialShareIM } from '../fpti/fpti';
import { FPTI_TAGS } from '../fpti/fptiConstants';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';

const { sysColorPrimaryMain, sysColorUnselectedContrast } = PAYPAL_THEME;

export default function ConfirmationSocialShare(props) {
  const worldReady = useContext(WorldReadyContext);
  const {
    email,
    hostedButtonId,
    orgName,
    messages: { social },
    personalizedMessages,
    campaignId,
    isLanding = false,
    isPostDonationModule = false,
    postDonationModule,
  } = props;
  const copy = new MessageFormat(worldReady, {
      id: 'common.copyLink.label.copy',
    }).format(),
    copied = new MessageFormat(worldReady, {
      id: 'common.copyLink.label.copied',
    }).format();
  const handleSocialAnalytics = (type) => {
    trackLinkClick(type, {
      eventName: isExternalFlow()
        ? EVENT_NAME.DW_GIVING_SHARE_CHANNEL_PRESSED
        : EVENT_NAME.DONATE_SUCCESS_SHARE_MESSAGE,
      additionalData: {
        [FPTI_TAGS.MODULE]: postDonationModule,
      },
    });
  };

  const getTitleMessages = (id) => {
    return new MessageFormat(worldReady, {
      id: `common.socialShareMessage.${id}`,
    }).format();
  };

  useEffect(() => {
    trackSocialShareIM();
  }, []);

  const title = {
      email: getTitleMessages('title.email'),
      facebook: getTitleMessages('title.facebook'),
      whatsapp: getTitleMessages('title.whatsapp'),
      messenger: getTitleMessages('title.messenger'),
      twitter: getTitleMessages('title.twitter'),
      copyLink: getTitleMessages('title.copyLink'),
      mobile: getTitleMessages('title.mobile'),
      overflow: getTitleMessages('title.overflow'),
      share: getTitleMessages('title.share'),
    },
    sheet = {
      email: getTitleMessages('sheet.email'),
      facebook: getTitleMessages('sheet.facebook'),
      whatsapp: getTitleMessages('sheet.whatsapp'),
      messenger: getTitleMessages('sheet.messenger'),
      twitter: getTitleMessages('sheet.twitter'),
      copyLink: getTitleMessages('sheet.copyLink'),
      mobile: getTitleMessages('sheet.mobile'),
    };

  let socialMessages;

  if (!_isEmpty(personalizedMessages)) {
    socialMessages = {
      ...social,
      ...personalizedMessages,
      copyLinkCopy: copy,
      copyLinkCopied: copied,
      sheet,
    };
  } else if (hostedButtonId) {
    socialMessages = {
      ...social,
      copyLinkCopy: copy,
      copyLinkCopied: copied,
      sheet,
    };
  } else {
    socialMessages = {
      ...social,
      ...social.unhosted,
      copyLinkCopy: copy,
      copyLinkCopied: copied,
      sheet,
    };
  }

  const isMobileWithExternalFlow = () => {
    return TemplateData.ismobile && isExternalFlow();
  };

  const socialCampaign = getSocialCampaign(
    socialMessages,
    orgName,
    hostedButtonId,
    campaignId,
    title,
    email,
    isPostDonationModule
  );

  const getSocialShareHeader = () => {
    let content = '';
    if (personalizedMessages.social_title) {
      content = personalizedMessages.social_title;
    } else if (isCampaigns()) {
      content = social.shareGeneric;
    } else if (_isEmpty(personalizedMessages)) {
      content = social.shareWithMatch;
    }
    return content;
  };

  const generateSocialShareUrl = () => {
    if (isExternalFlow()) {
      return TemplateData.shareUrl;
    } else if (hostedButtonId) {
      return `${TemplateData.paypalHostName}/donate/?hosted_button_id=${hostedButtonId}&source=url`;
    } else if (campaignId) {
      return `${TemplateData.paypalHostName}/donate/?campaign_id=${campaignId}&source=url`;
    } else if (email) {
      return `${TemplateData.paypalHostName}/donate/?business=${email}&source=url`;
    } else if (TemplateData.merchantId) {
      return `${TemplateData.paypalHostName}/donate/?business=${TemplateData.merchantId}&source=url`;
    }
  };

  const socialShareUrl = generateSocialShareUrl();

  const colors = {
    facebook: '#007AFF',
    twitter: '#64D2FF',
  };

  const headerClasses = classNames('text-center', 'social_share_header', {
    inContext: isInContext(),
    externalFlow: isExternalFlow(),
    'campaign-landing': isCampaigns() && isLanding && !TemplateData.ismobile,
  });

  const getShareStyles = () => {
    if (isInContext() && isExternalFlow()) {
      return { marginBottom: '28px' };
    } else if (isCampaigns() && isLanding) {
      return { marginBottom: '48px', flexWrap: 'wrap', rowGap: '14px' };
    }
    return { marginBottom: '74px' };
  };

  const getColor = () => {
    if (isInContext() && isExternalFlow()) {
      return sysColorUnselectedContrast;
    }
    return sysColorPrimaryMain;
  };

  const getHeader = () => {
    return (
      !isPostDonationModule && (
        <BodyText className={headerClasses}>{getSocialShareHeader()}</BodyText>
      )
    );
  };

  return (
    <>
      {getHeader()}
      {isMobileWithExternalFlow() ? (
        <SocialShare
          shareUrl={socialShareUrl}
          socialCampaign={socialCampaign}
          isMobile={TemplateData.ismobile}
          analytics={handleSocialAnalytics}
          color={colors}
          style={{ marginBottom: '20px' }}
          renderMode="sheet"
          buttonText={personalizedMessages.success_share_button}
          isPostDonationModule={isPostDonationModule}
        />
      ) : (
        <SocialShare
          shareUrl={socialShareUrl}
          socialCampaign={socialCampaign}
          isMobile={TemplateData.ismobile}
          analytics={handleSocialAnalytics}
          color={getColor()}
          style={!isPostDonationModule && getShareStyles()}
          align={
            isCampaigns() && isLanding && !TemplateData.ismobile
              ? 'left'
              : 'center'
          }
          isPostDonationModule={isPostDonationModule}
        />
      )}
    </>
  );
}
