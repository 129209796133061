import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HeadingText } from '@paypalcorp/pp-react';

class ConfirmationAmount extends Component {
  render() {
    return (
      <HeadingText
        size="sm"
        className="text-center confirmation-heading"
        style={{ margin: '24px auto' }}
      >
        {this.props.message}
      </HeadingText>
    );
  }
}
export default ConfirmationAmount;

ConfirmationAmount.propTypes = {
  message: PropTypes.object,
};
