import templateData from './getTemplateData';
import { getParameterByName } from './queryStringHelper';
const languagesList = templateData.languagesList;

export function getCurrentLangName() {
  const languageCode = templateData.language;
  let currentLanguage = 'English';
  languagesList.forEach((lang) => {
    const languageName = lang.split('-')[0];
    const locale = lang.split('-')[1];
    if (locale.split('_')[0] === languageCode) {
      currentLanguage = languageName;
    }
  });

  return currentLanguage;
}

export function getLanguageOptions() {
  const lang_options = [];
  languagesList.forEach((lang) => {
    const languageName = lang.split('-')[0];
    lang_options.push({
      title: languageName,
    });
  });

  return lang_options;
}

export function updatePageLanguageByLocale(locale) {
  const urlParams = `token=${templateData.token}&locale.x=${locale}`;
  const isOnGuestForm =
    window.location.href.indexOf('/guest') > 0 ||
    ![undefined, null].includes(getParameterByName('signup'));

  let updatedUrl;
  if (isOnGuestForm) {
    const clientState = encodeURIComponent(`/donate/guest?${urlParams}`);
    updatedUrl = `/donate/?${urlParams}&clientState=${clientState}`;
  } else {
    updatedUrl = `${window.location.href.split('?')[0]}?${urlParams}`;
  }

  window.location.href = updatedUrl;
}
