import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@paypalcorp/pp-react-links';
import { isInContext, closeWindow } from '../utility/inContextDonation';
import { EVENT_NAME } from '../utility/constants';
import { trackLinkClick } from '../fpti/fpti';
import { LINK_NAME } from '../fpti/fptiConstants';

const DoneLink = ({ doneContent, syncReturnUrl, redirectUrl }) => {
  const handleClose = () => {
    const eventName = EVENT_NAME.DW_GIVING_PAYMENT_SUCCESS_DONE_PRESSED;
    trackLinkClick(LINK_NAME.DONE, {
      eventName,
    });

    if (isInContext()) {
      closeWindow();
    }

    if (syncReturnUrl) {
      window.location.href = syncReturnUrl;
    } else {
      window.location.href = redirectUrl;
    }
  };

  return (
    <div className="text-center done-link">
      <Link href="#" onClick={handleClose}>
        {doneContent}
      </Link>
    </div>
  );
};

DoneLink.propTypes = {
  doneContent: PropTypes.string,
  syncReturnUrl: PropTypes.string,
  redirectUrl: PropTypes.string,
};

export default DoneLink;
