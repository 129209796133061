import React from 'react';
import { trackLinkClick } from '../fpti/fpti';
import { LINK_NAME } from '../fpti/fptiConstants';
import { EVENT_NAME } from '../utility/constants';
import { isExternalFlow } from '../utility/productIntegrationUtils';
import TemplateData from '../utility/getTemplateData';
import { isInContext, isMobileOrTablet } from '../utility/inContextDonation';
import classNames from 'classnames';
import { CancelAndReturnUrl } from '@paypalcorp/donate-react';
import { Col } from '@paypalcorp/pp-react';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';

class CancelAndReturn extends React.Component {
  static contextType = WorldReadyContext;

  constructor() {
    super();
    this.state = {
      displayMe: TemplateData.charityCancelUrl ? true : false,
    };
    this.isLanding = TemplateData.viewName === 'landing';
  }

  handleCancel = () => {
    trackLinkClick(LINK_NAME.CANCEL, {
      eventName: EVENT_NAME.DONATE_LANDING_MERCHANT_SITE_PRESSED,
    });
    let cancelurl = TemplateData.charityCancelUrl;
    if (cancelurl.indexOf('http') != 0 && cancelurl.indexOf('//') != 0) {
      cancelurl = '//' + cancelurl;
    }
    window.location.href = cancelurl;
  };

  cancelButtonWrapperClass() {
    return classNames({
      'text-center': true,
      pp_cancel_btn_wrapper: true,
      is_mobile: isMobileOrTablet(),
      is_landing: this.isLanding && !this.props.isGuest,
    });
  }

  render() {
    if (isExternalFlow() || isInContext()) {
      return null;
    }

    return (
      <div>
        {this.state.displayMe && (
          <Col>
            <div
              className={this.cancelButtonWrapperClass()}
              data-testid="cancel-and-return-container"
              id="cancelReturnBtn"
            >
              <CancelAndReturnUrl
                onClick={this.handleCancel}
                content={new MessageFormat(this.context, {
                  id: 'common.cancelAndReturn.label.return',
                }).format({
                  orgName: TemplateData.donationName,
                })}
              />
            </div>
          </Col>
        )}
      </div>
    );
  }
}

export default CancelAndReturn;
