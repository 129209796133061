import React, { useEffect, useState, useContext } from 'react';
import { Tooltip } from '@paypalcorp/pp-react';
import AppDispatcher from '../../dispatcher/AppDispatcher';
import * as AppConstants from '../../utility/constants';
import AppStore from '../../stores/AppStore';
import PropTypes from 'prop-types';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';

function PPCC({ isHeader }) {
  const worldReady = useContext(WorldReadyContext);
  const [showTooltip, setShowTooltip] = useState(AppStore.getPPCCStatus());
  const toolTipMsgFmt = new MessageFormat(worldReady, {
    id: 'common.ppcc.ppcc.tooltip',
  });
  let dispatcherMessages;
  const registerAppDispatcherMessages = () => {
    dispatcherMessages = AppDispatcher.register((payload) => {
      if (
        payload.action &&
        payload.action.actionType === AppConstants.UPDATE_PPCC_STATUS
      ) {
        setShowTooltip(payload.action.item);
      }
    });
  };

  useEffect(() => {
    registerAppDispatcherMessages();
    return function cleanup() {
      AppDispatcher.unregister(dispatcherMessages);
    };
  }, []);

  if (!showTooltip) {
    return null;
  }
  return (
    <Tooltip
      className={isHeader ? 'ppcc_tooltip_within_header' : 'ppcc_tooltip'}
      description={toolTipMsgFmt.format()}
      verifiedIcon
    />
  );
}

PPCC.propTypes = {
  isHeader: PropTypes.bool,
};

export default PPCC;
