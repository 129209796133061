import React from 'react';
import templateData from '../utility/getTemplateData';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from '@paypalcorp/pp-react-grid';

const styles = StyleSheet.create({
  centedImage: {
    maxWidth: 300,
    maxHeight: 60,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 25,
    marginTop: 24,
  },
});

class CharityLogo extends React.Component {
  constructor() {
    super();
    const logoUrl = templateData.logoUrl || '';
    this.state = {
      bannerUrl: logoUrl.includes('https') ? logoUrl : false,
      altText: templateData.donationName || '',
    };
  }

  render() {
    return (
      <Container className="donate_banner_wrapper">
        <Row>
          <Col sm={12}>
            <div className="banner_img_holder center-block">
              {this.state.bannerUrl ? (
                <img
                  alt={this.state.altText}
                  id="charityLogo"
                  className={css(styles.centedImage)}
                  src={this.state.bannerUrl}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CharityLogo;
