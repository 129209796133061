import React, { useState } from 'react';
import { ActionMenu, Button, ChevronUpIcon } from '@paypalcorp/pp-react';
import templateData from '../utility/getTemplateData';
import { showSpinnerOverlay } from '../utility/helper';
import {
  getCurrentLangName,
  updatePageLanguageByLocale,
  getLanguageOptions,
} from '../utility/languageSelector';

const languagesList = templateData.languagesList;

export default function LanguageSelector() {
  const [isOpen, setIsOpen] = useState(false);
  const currentLanguage = getCurrentLangName();
  const options = getLanguageOptions();

  if (!options) return null;

  const handleOpen = (event) => {
    event.stopPropagation();
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const getLocale = (index) => {
    const lang = languagesList[index];
    return `${lang.split('-')[1].split('_')[0]}_${templateData.country}`;
  };

  const selectMenuItem = (index) => {
    showSpinnerOverlay();
    const locale = getLocale(index);
    updatePageLanguageByLocale(locale);
  };

  return (
    <div className="lang_selector">
      <Button size="sm" onClick={handleOpen}>
        {currentLanguage}
        <ChevronUpIcon size="xs" />
      </Button>
      <ActionMenu
        isOpen={isOpen}
        options={options}
        onSelection={selectMenuItem}
        onClose={handleClose}
      />
    </div>
  );
}
