import React, { useState, useEffect, useContext } from 'react';
import TemplateData from '../utility/getTemplateData';
import { BodyText, Avatar, Row, Col } from '@paypalcorp/pp-react';
import ConfirmationSocialShare from './ConfirmationSocialShare';
import ConfirmationTransactionId from './ConfirmationTransactionId';
import OfferConfirmation from '../components/offerConfirmation';
import PropTypes from 'prop-types';
import SignUpAlert from '../components/guest/SignUpAlert';
import VoucherFlowDisclaimer from '../components/voucherFlowDisclaimer';
import ConfirmationRedirect from './ConfirmationRedirect';
import { endCustomCPLTrackingForConfirmationPage } from '../utility/cpl';
import {
  isInContext,
  isMobileOrInContext,
  onComplete,
  isMWebNoReturnURL,
} from '../utility/inContextDonation';
import { isPPFundraising } from '../utility/productIntegrationUtils';
import CloseButton from '../components/CloseButton';
import classNames from 'classnames';
import DoneLink from './DoneLink';
import { BannerImage } from '@paypalcorp/donate-react';
import { isCampaigns } from '../utility/productIntegrationUtils';
import { addFeature, updateFPTIValue } from '../fpti/helper';
import { FEATURES, FPTI_TAGS } from '../fpti/fptiConstants';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
import { fetcher } from '../utility/domUtils';
import PostDonationModules from './../components/postDonationModules';
import { POST_DONATION_MODULES } from './../utility/constants';
const POST_DONATION_MODULE_URL = '/donate/api/postdonation/getmodule';

const checkShareModule = (postDonationModule) => {
  return (
    postDonationModule &&
    postDonationModule.module === POST_DONATION_MODULES.SHARE
  );
};

const isPostDonationModule = (postDonationModule, isShareModule) => {
  return postDonationModule && !isShareModule;
};
function ConfirmationView(props) {
  const worldReady = useContext(WorldReadyContext);
  const getSocialMessage = (id, params) => {
    return new MessageFormat(worldReady, {
      id: isCampaigns()
        ? `landing.social.${id}`
        : `confirmation.confirmation.social.${id}`,
    }).format(params);
  };
  const {
    autoRedirect,
    autoRedirectUrl,
    autoRedirectMethod,
    footer,
    handleRedirect,
    hostedButtonId,
    illustration,
    isSignUp,
    isVoucherFlow,
    message,
    offerProgram,
    orgName,
    postBackData,
    returnTo,
    returnText,
    signUpProps,
    syncReturnUrl,
    txnId,
    personalizedMessages,
    isExternalFlow,
    campaignId,
  } = props;

  useEffect(() => {
    endCustomCPLTrackingForConfirmationPage();
  }, []);

  useEffect(() => {
    onComplete(postBackData);
  }, []);

  const [postDonationModule, setPostDonationModule] = useState({
    isSocialShareLoading: isCampaigns(),
  });
  const isShareModule = checkShareModule(postDonationModule);

  const socialShareMessages = {
    social: {
      emailSubject: getSocialMessage('emailSubject', {
        donationName: '{donationName}',
      }),
      emailBody: getSocialMessage('emailBody', {
        donationName: '{donationName}',
        url: '{url}',
      }),
      mobileText: getSocialMessage('mobileText', {
        donationName: '{donationName}',
      }),
      facebookMessage: getSocialMessage('facebookMessage', {
        donationName: '{donationName}',
        url: '{url}',
      }),
      twitterMessage: getSocialMessage('twitterMessage', {
        donationName: '{donationName}',
      }),
      whatsappMessage: getSocialMessage('whatsappMessage', {
        donationName: '{donationName}',
      }),
      copyLinkHeader: getSocialMessage('copyLinkHeader'),
      copyLinkSubheaderBefore: getSocialMessage('copyLinkSubheaderBefore'),
      copyLinkSubheader: getSocialMessage('copyLinkSubheader'),
      copiedText: getSocialMessage('copiedText'),
      copyText: getSocialMessage('copyText'),
      subheader: getSocialMessage('subheader'),
      shareGeneric: getSocialMessage('shareGeneric'),
      shareWithMatch: getSocialMessage('shareWithMatch'),
      ...(!isCampaigns()
        ? {
            unhosted: {
              emailSubject: getSocialMessage('unhosted.emailSubject'),
              emailBody: getSocialMessage('unhosted.emailBody', {
                url: '{url}',
              }),
              mobileText: getSocialMessage('unhosted.mobileText'),
              facebookMessage: getSocialMessage('unhosted.facebookMessage'),
              twitterMessage: getSocialMessage('unhosted.twitterMessage'),
              whatsappMessage: getSocialMessage('unhosted.whatsappMessage', {
                url: '{url}',
              }),
            },
          }
        : {}),
    },
  };

  useEffect(() => {
    fetcher(`${POST_DONATION_MODULE_URL}?token=${TemplateData.token}`)
      .then((response) => response.json())
      .then((result) => {
        const data = result?.data?.clientData?.postDonationModuleData;
        // Show post donation module
        if (data) {
          setPostDonationModule({
            ...data,
            isSocialShareLoading: false,
          });
          const { metaData: { elmo } = {} } = data;
          if (elmo) {
            updateFPTIValue(FPTI_TAGS.EXPERIMENT_ID, elmo.experiment_id);
            updateFPTIValue(FPTI_TAGS.TREATMENT_ID, elmo.treatment_id);
          }
        } else {
          setPostDonationModule({
            module: POST_DONATION_MODULES.SHARE,
            isSocialShareLoading: false,
          });
        }
      })
      .catch(() => {
        setPostDonationModule({
          module: POST_DONATION_MODULES.SHARE,
          isSocialShareLoading: false,
        });
      });
  }, []);

  const socialShareLoadingShimmer = () => {
    return (
      <div style={{ margin: '0px 24px' }}>
        <BodyText isLoading />
        <Row
          style={{
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            padding: '1rem',
          }}
        >
          <Avatar isLoading size="xs" />
          <Avatar isLoading size="xs" />
          <Avatar isLoading size="xs" />
          <Avatar isLoading size="xs" />
          <Avatar isLoading size="xs" />
          <Avatar isLoading size="xs" />
        </Row>
      </div>
    );
  };

  function showSocialShare() {
    if (TemplateData.socialSharePreference) {
      addFeature(FEATURES.SOCIAL_SHARE_PREFERENCE);
      return postDonationModule?.isSocialShareLoading ? (
        socialShareLoadingShimmer()
      ) : (
        <ConfirmationSocialShare
          hostedButtonId={hostedButtonId}
          campaignId={campaignId}
          orgName={orgName}
          messages={socialShareMessages}
          email={TemplateData.donationEmail}
          personalizedMessages={personalizedMessages}
          isPostDonationModule={isPostDonationModule(
            postDonationModule,
            isShareModule
          )}
          postDonationModule={postDonationModule?.module}
        />
      );
    }

    return null;
  }

  function confirmationClass() {
    return classNames(
      {
        gn_donate_wrapper: TemplateData.bannerUrl,
        donate_wrapper: !TemplateData.bannerUrl,
        confirmation: !TemplateData.bannerUrl,
      },
      { mobile_view: isMobileOrInContext() },
      {
        mobile_view_without_return_message: isMWebNoReturnURL(),
      },
      { ppf: isPPFundraising() }
    );
  }

  personalizedMessages.success_subheader =
    personalizedMessages.success_subheader
      ?.split('\\n')
      .join('<br />')
      ?.split('\n')
      .join('<br />');

  const transactionIdValue =
    !TemplateData.recurringStatus ||
    TemplateData.recurringStatus === 'unchecked'
      ? txnId?.split(':')[1]?.trim()
      : null;
  return (
    <div>
      {isSignUp && <SignUpAlert {...signUpProps} />}
      <div className={confirmationClass()}>
        {isExternalFlow && <CloseButton inverse />}
        {TemplateData.bannerUrl ? (
          <BannerImage src={TemplateData.bannerUrl} />
        ) : (
          <div> {illustration} </div>
        )}
        <div className="success_content">
          {message}

          {isExternalFlow && personalizedMessages.success_subheader ? (
            <BodyText
              className="text-center success_subheader"
              dangerouslySetInnerHTML={{
                __html: personalizedMessages.success_subheader,
              }}
            ></BodyText>
          ) : null}

          {isVoucherFlow && (
            <OfferConfirmation offerProgram={offerProgram} txnId={txnId} />
          )}

          {isVoucherFlow && <VoucherFlowDisclaimer />}

          <PostDonationModules
            {...postDonationModule}
            transactionId={transactionIdValue}
          >
            {showSocialShare()}
          </PostDonationModules>

          {postDonationModule?.module && isShareModule && !isExternalFlow && (
            <ConfirmationTransactionId transactionId={txnId} />
          )}

          {isExternalFlow && (
            <DoneLink
              doneContent={new MessageFormat(worldReady, {
                id: 'confirmation.confirmation.label.doneButton',
              }).format()}
              syncReturnUrl={syncReturnUrl}
              redirectUrl={autoRedirectUrl}
            />
          )}
        </div>
      </div>
      {postDonationModule?.module && !isShareModule && (
        <Row className="post-donation-share-widget">
          {!isExternalFlow && txnId && (
            <Col lg={6} sm={12} md={6} className="post-donation-transaction-id">
              <ConfirmationTransactionId transactionId={txnId} />
            </Col>
          )}
          <Col
            lg={6}
            sm={12}
            md={6}
            className={classNames({
              'post-donation-social-share': true,
              'post-donation-social-share-in-context':
                isInContext() && isExternalFlow,
            })}
          >
            {showSocialShare()}
          </Col>
        </Row>
      )}

      {!isInContext() && autoRedirect && autoRedirectUrl && (
        <ConfirmationRedirect
          autoRedirectMethod={autoRedirectMethod}
          autoRedirectUrl={autoRedirectUrl}
          handleRedirect={handleRedirect}
          orgName={orgName}
          postBackData={postBackData}
          returnText={returnText}
          returnTo={returnTo}
          syncReturnUrl={syncReturnUrl}
        />
      )}
      {footer}
    </div>
  );
}

ConfirmationView.propTypes = {
  amount: PropTypes.string,
  autoRedirectMethod: PropTypes.string,
  autoRedirect: PropTypes.bool,
  autoRedirectUrl: PropTypes.string,
  footer: PropTypes.object,
  handleRedirect: PropTypes.func,
  hostedButtonId: PropTypes.string,
  isVoucherFlow: PropTypes.bool,
  illustration: PropTypes.object,
  isSignUp: PropTypes.bool,
  message: PropTypes.object,
  offerProgram: PropTypes.object,
  orgName: PropTypes.string,
  postBackData: PropTypes.object,
  redirect: PropTypes.func,
  returnTo: PropTypes.string,
  returnText: PropTypes.string,
  signUpProps: PropTypes.object,
  syncReturnUrl: PropTypes.string,
  txnId: PropTypes.string,
  personalizedMessages: PropTypes.object,
  isExternalFlow: PropTypes.bool,
  isOfacPending: PropTypes.bool,
  campaignId: PropTypes.string,
};

export default ConfirmationView;
