import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BodyText } from '@paypalcorp/pp-react-text';
import { isExternalFlow } from '../utility/productIntegrationUtils';
import classNames from 'classnames';
import { isMobileOrTablet } from '../utility/inContextDonation';

export class ConfirmationReturnText extends Component {
  confirmationReceiptWrapper() {
    return classNames({
      confirmation_reciept_wrapper: true,
      is_mobile: isMobileOrTablet(),
    });
  }

  render() {
    if (isExternalFlow()) {
      return null;
    }

    return (
      <div className={this.confirmationReceiptWrapper()}>
        <BodyText className="text-center confirmation_text">
          {this.props.returnText}
        </BodyText>
      </div>
    );
  }
}

export default ConfirmationReturnText;

ConfirmationReturnText.propTypes = {
  returnText: PropTypes.string,
};
