import { FPTI_TAGS } from './fptiConstants';
import { updateGlobalFPTI } from './helper';

const analytics = window?.PAYPAL?.analytics?.instance;

// Start client side error tracking
window?.PAYPAL?.analytics?.startClientErrorTracking();

export const trackEventWithData = (spec = {}, additionalData = {}) => {
  updateGlobalFPTI({
    [FPTI_TAGS.ACTION]: '',
  });

  const fptiData = {
    ...window.fpti,
    ...spec.data,
    ...additionalData,
    tsrce: 'donatenodeweb',
  };
  // ref: https://github.paypal.com/pages/Tracking-R/client-side-instrumentation/docs/fptiAnalytics.Analytics.html#recordEvent
  // the 3rd param is set to false meaning the data payload won't be merged
  // with window.fpti object
  analytics?.recordEvent(spec.event_name, fptiData, false);
};

export const trackEventWithGlobalData = (spec = {}, additionalData = {}) => {
  updateGlobalFPTI({
    [FPTI_TAGS.ACTION]: '',
  });

  const fptiData = {
    ...spec.data,
    ...additionalData,
    tsrce: 'donatenodeweb',
  };
  analytics?.recordEvent(spec.event_name, fptiData, true);
};

export const trackPageLoad = (globalData, onceData, fromSetup = false) => {
  updateGlobalFPTI({
    [FPTI_TAGS.ACTION]: '',
  });

  globalData.tsrce = 'donatenodeweb';
  analytics?.recordImpression({ data: globalData }, onceData, fromSetup);
};

export const trackClick = (data) => {
  updateGlobalFPTI({
    [FPTI_TAGS.ACTION]: '',
  });

  data.tsrce = 'donatenodeweb';
  analytics?.recordClick({ data });
};

export const trackError = (data) => {
  updateGlobalFPTI({
    [FPTI_TAGS.ACTION]: '',
  });

  data.tsrce = 'donatenodeweb';
  analytics?.recordError({ data });
};
