import React from 'react';
import { hideSpinnerOverlay, setFocus } from '../utility/helper';
import AppStore from '../stores/AppStore';
import TemplateData from '../utility/getTemplateData';
import {
  isExternalFlow,
  isCampaigns,
} from '../utility/productIntegrationUtils';

const donateGiftPercentage = AppStore.getGiftAidItExtraAmount();

export default function withConfirmationData(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.confirmationData = this.getConfirmationData();
    }

    handleRedirect = () => {
      let finalUrl = this.confirmationData.autoRedirectUrl;

      if (this.confirmationData.syncReturnUrl) {
        finalUrl = this.confirmationData.syncReturnUrl;
      }

      if (
        finalUrl.indexOf('http') !== 0 &&
        finalUrl.indexOf('//') !== 0 &&
        !isExternalFlow()
      ) {
        finalUrl = '//' + finalUrl;
      }

      window.location.href = finalUrl;
    };

    getExtraDonateAmount = (giftaidFlag, donatedAmount) => {
      return giftaidFlag
        ? TemplateData.donationSymbol +
            (donatedAmount * donateGiftPercentage - donatedAmount).toFixed(2)
        : '';
    };

    getConfirmationData = () => {
      const confirmationData =
        AppStore.getConfirmation() || TemplateData.confirmationData || {};
      const result = {};
      result.amount = confirmationData.amount || '';
      result.confirmationNumber = confirmationData.confirmationNumber || '';
      result.postBackData = confirmationData || {};
      result.displayMyAccount = confirmationData.showAccountOverview || false;
      result.orgName = TemplateData.donationName
        ? TemplateData.donationName
        : '';
      result.receiptId = confirmationData.receiptId;
      result.isOfacPending = confirmationData.isOfacPending;

      result.autoRedirect = TemplateData.returnAutoRedirect !== '';
      result.autoRedirectUrl = TemplateData.charityReturnUrl
        ? TemplateData.charityReturnUrl
        : '';
      result.autoRedirectMethod = TemplateData.returnMethod
        ? TemplateData.returnMethod
        : '';
      result.syncReturnUrl = confirmationData.syncReturnUrl || '';

      result.isSignUp = confirmationData.isSignUp || false;
      result.signUpStatus = confirmationData.signUpSuccess || false;
      result.isVoucherFlow = TemplateData.isVoucherFlow;
      result.offerProgram = TemplateData.offerProgram;
      result.hostedButtonId = TemplateData.hostedButtonId || '';
      result.campaignId = TemplateData.productId || '';
      result.isExternalFlow = isExternalFlow();
      result.isCampaigns = isCampaigns();

      result.handleRedirect = this.handleRedirect;
      result.rp_status = AppStore.getDonationRecurring() === 'checked';
      result.giftaidFlag = AppStore.getGiftaidItFlagStatus();
      result.selectedRecurringFrequency =
        AppStore.getSelectedRecurringFrequency();
      result.donatedAmount = AppStore.getDonationAmount();

      result.extraDonateAmount = this.getExtraDonateAmount(
        result.giftaidFlag,
        result.donatedAmount
      );
      result.showMyAccount =
        result.displayMyAccount &&
        !result.isVoucherFlow &&
        !result.autoRedirect;

      return result;
    };

    componentDidMount = () => {
      hideSpinnerOverlay();
      setFocus();
    };

    render() {
      return <WrappedComponent {...this.confirmationData} {...this.props} />;
    }
  };
}
