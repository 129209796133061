import React from 'react';
import * as AppActions from '../actions/AppActions';

export default function withCurrentPageUpdate(WrappedComponent, currentPage) {
  return class WithCurrentPageUpdate extends React.Component {
    componentDidMount() {
      AppActions.updateCurrentPage({
        currentPage: currentPage,
      });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
