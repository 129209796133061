import AppDispatcher from '../dispatcher/AppDispatcher';
import * as AppConstants from '../utility/constants';

export function changeAmount(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_AMOUNT,
    item: item,
  });
}

export function changeSymbol(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_SYMBOL,
    item: item,
  });
}

export function changeCode(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_CODE,
    item: item,
  });
}

export function changeFormatedAmount(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_FORMATED_AMOUNT,
    item: item,
  });
}

export function changeRecurring(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_RECURRING,
    item: item,
  });
}

export function changeSelectedRecurringFrequency(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_RECURRING_FREQUENCY,
    item: item,
  });
}

export function changeRecurringModalStatus(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.RP_MODAL_CLOSED,
    item: item,
  });
}

export function changeCoverFee(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_COVER_FEE,
    item: item,
  });
}

export function changeAnonymousStatus(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_ANONYMOUS_STATUS,
    item: item,
  });
}

export function changeFeeAmount(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_FEE_AMOUNT,
    item: item,
  });
}

export function changeFormatedFeeAmount(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_FORMATED_FEE_AMOUNT,
    item: item,
  });
}

export function changeAmountBeforeFee(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_AMOUNT_BEFORE_FEE,
    item: item,
  });
}

export function changeNote(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_NOTE,
    item: item,
  });
}

export function changeCountry(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_COUNTRY,
    item: item,
  });
}

export function updateConfirmation(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.UPDATE_CONFIRMATION,
    item: item,
  });
}

export function updatePostBackData(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.POST_BACK_DATA,
    item: item,
  });
}

export function updateCurrentPage(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.UPDATE_CURRENT_PAGE,
    item: item,
  });
}

export function updateServerErrors(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.UPDATE_SERVER_ERRORS,
    item: item,
  });
}

export function updatePaymentError(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.PAYMENT_ERROR,
    item: item,
  });
}

export function updateSignUpError(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.UPDATE_SIGN_UP_ERROR,
    item: item,
  });
}

export function updateFundingOptions(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.FUNDING_OPTIONS,
    item: item,
  });
}

export function updateEmail(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.EMAIL,
    item: item,
  });
}

export function updateBillingAddress(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.BILLING_ADDRESS,
    item: item,
  });
}

/**
 * Sets the donation amount type
 * @param  {String} type type of donation amount - "preset" or "userinput"
 */
export function updateDonationAmountType(donationAmountType) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.UPDATE_DONATION_AMOUNT_TYPE,
    item: donationAmountType,
  });
}

/**
 * Sets the giftaidit type
 * @param  {Boolean}
 */
export function updateGiftaidItFlag(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.GIFT_AID_IT,
    item: item,
  });
}

/**
 * Sets the shareAddress checkbox
 * @param  {Boolean} shareAddress checkbox value
 */
export function updateShareAddress(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_SHARE_ADDRESS,
    item: item,
  });
}

/**
 * Sets the any card releated errors
 * @param  {String} card error
 */
export function updateAddCardError(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.ADD_CARD_ERROR,
    item: item,
  });
}

export function updateSelectedProgram(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.UPDATE_SELECTED_PROGRAM,
    item: item,
  });
}

export function changeCardSpec(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.CHANGE_CARD_SPEC,
    item: item,
  });
}

export function saveFormData(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.SAVE_FORM_DATA,
    item,
  });
}

export function updateSelectedPresetButtonId(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.UPDATE_SELECTED_PRESET_BUTTON_ID,
    item,
  });
}

export function updateThreeDSData(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.UPDATE_THREEDS_DATA,
    item,
  });
}

export function showLanguageSelector(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.SHOW_LANGUAGE_SELECTOR,
    item,
  });
}

export function updateOrderStatus(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.UPDATE_ORDER_STATUS,
    item,
  });
}

export function updatePPCCStatus(item) {
  AppDispatcher.handleViewAction({
    actionType: AppConstants.UPDATE_PPCC_STATUS,
    item,
  });
}
