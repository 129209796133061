import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@paypalcorp/pp-react-links';
import { isExternalFlow } from '../utility/productIntegrationUtils';
import { trackLinkClick } from '../fpti/fpti';
import { EVENT_NAME } from '../utility/constants';

export class ConfirmationReturnTo extends Component {
  constructor(props) {
    super(props);
  }

  redirectUrl = () => {
    trackLinkClick('merchant_redirect', {
      eventName: EVENT_NAME.DONATE_SUCCESS_MERCHANT_SITE_PRESSED,
    });
    this.props.handleRedirect();
  };

  render() {
    if (isExternalFlow()) {
      return null;
    }

    return (
      <div className="text-center return-message">
        <Link onClick={this.redirectUrl} className="pp_guest_btn">
          {this.props.returnto}
        </Link>
      </div>
    );
  }
}

export default ConfirmationReturnTo;

ConfirmationReturnTo.propTypes = {
  returnto: PropTypes.string,
  handleRedirect: PropTypes.func,
};
