import React from 'react';
import { CaptionText, HeadingText } from '@paypalcorp/pp-react-text';
import { CausesIcon } from '@paypalcorp/pp-react-icons';
import PropTypes from 'prop-types';
import { Message } from '@paypalcorp/worldready-react';

const GNCSubheader = ({ organizationName, purpose }) => {
  return (
    <div className="donate_sub_header donate-main-content">
      <div className="gnc-subheader">
        <HeadingText className="charity-header" level={2}>
          {purpose}
        </HeadingText>
        <div className="charity-subtitle">
          <CausesIcon size="sm" className="subtitle-icon" />
          <CaptionText>
            <Message
              id="common.subHeader.label.supportingOrganization"
              organization={organizationName}
            />
          </CaptionText>
        </div>
      </div>
    </div>
  );
};

GNCSubheader.propTypes = {
  organizationName: PropTypes.string,
  purpose: PropTypes.string,
};

export default GNCSubheader;
