import templateData from '../utility/getTemplateData';
import appStore from '../stores/AppStore';
import { isAmountGreaterThanZero } from '../components/amount/amountUtils';
import { getParameterByName } from './queryStringHelper';
import { show, hide, addClass, removeClass, fetcher } from './domUtils';
import { isOptInCoverFee } from '../components/coverFee/feeUtils';
import find from 'lodash/find';
const FROMUL_QUERY_PARAM = 'fromUL';
const FROMUOB_QUERY_PARAM = 'fromUOB';
const SDKMETA_QUERY_PARAM = 'sdkMeta';

export const addShake = () => {
  addClass('.inputField  .money_input', ['animated', 'shake']);
  setTimeout(() => {
    removeClass('.inputField  .money_input', ['animated', 'shake']);
  }, 1000);
};

export const showSpinnerOverlay = () => {
  show('.spinneroverlay');

  const waitTextEl = document.getElementById('waitText');
  if (waitTextEl) {
    waitTextEl.focus();

    // Added this so mouse user will not always see the focus box
    waitTextEl.style.outline = 'none';

    // Since there is only one focusable element within the spinner
    // overlay, prevent any behaviour from occurring when the user
    // presses the TAB key
    waitTextEl.onkeydown = function (event) {
      // Add style back when user tries to manually focus on it
      waitTextEl.style.outline = 'thin dotted';
      waitTextEl.style.outline = '5px auto -webkit-focus-ring-color';
      waitTextEl.style.outlineOffset = '-2px';
      if (event.keyCode === 9 && document.activeElement === waitTextEl) {
        event.preventDefault();
      }
    };
  }
};

const getFirstNonHiddenFocusableEl = (elements) => {
  return find(elements, (el) => {
    return el.type !== 'hidden';
  });
};

// After spinner exits, we need to set focus to the first fosable element
// Assuming that focus should be set with either the gdpr cookie banner or donateWrapper
// If any flow use a different main component, should update here
export const setFocus = () => {
  const elementWrapper =
    document.getElementById('gdprCookieContent_wrapper') ||
    document.getElementById('donateWrapper');

  if (elementWrapper) {
    // If there is no focusable element, will get text element
    const focusable =
      elementWrapper.querySelectorAll(
        'button:not([hidden]),[href]:not([hidden]), input:not([hidden]), select:not([hidden]), textarea:not([hidden]), [tabindex]:not([tabindex="-1"]):not([hidden])'
      ) ||
      elementWrapper.querySelectorAll('p:not([hidden]), span:not([hidden])');

    const firstFocusableEl = getFirstNonHiddenFocusableEl(focusable);

    if (firstFocusableEl) {
      firstFocusableEl.style.outline = 'none';
      firstFocusableEl.focus();

      firstFocusableEl.onkeydown = function () {
        firstFocusableEl.style.outline = 'thin dotted';
        firstFocusableEl.style.outline = '5px auto -webkit-focus-ring-color';
        firstFocusableEl.style.outlineOffset = '-2px';
      };

      firstFocusableEl.onblur = function () {
        firstFocusableEl.style.outline = 'none';
      };
    }
  }
};

export const hideSpinnerOverlay = () => {
  hide('.spinneroverlay');
};

export const updateDonation = (redirecturl, clearSavedFormData) => {
  const data = {
    token: templateData.token,
    amount: appStore.getDonationAmount(),
    donationAmountType:
      appStore.getDonationAmountType() === 'preset' ? 'preset' : 'userinput',
    currencyCode: templateData.donationCode,
    donationNote: appStore.getDonationNote() || '',
    recurring: appStore.getDonationRecurring(),
    addCardError: appStore.getAddCardError(),
    giftAidItFlag: appStore.getGiftaidItFlagStatus(),
    selectedProgram: appStore.getSelectedProgram(),
    selectedPresetButtonId: appStore.getSelectedPresetButtonId(),
    selectedRecurringFrequency: appStore.getSelectedRecurringFrequency(),
  };

  if (templateData.coverFeeEnabled) {
    data.feePayer = isOptInCoverFee() ? 'consumer' : 'merchant';
    data.feeCurr = appStore.getDonationCode();
    data.feeAmount = appStore.getFeeAmount();
    data.rawAmount = appStore.getAmountBeforeFee();
  }

  if (templateData.anonymousEnabled) {
    data.anonymousStatus = appStore.getAnonymousStatus();
  }

  if (clearSavedFormData) {
    data.clearSavedFormData = clearSavedFormData;
  }

  showSpinnerOverlay();
  let updateUrl = `/donate/update/donation`;
  if (templateData.token) {
    updateUrl = `${updateUrl}?token=${templateData.token}`;
  }
  fetcher(updateUrl, {
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then((result) => {
      if (result && result.status === 401) {
        const failureUrl = '/donate/?token=' + templateData.token;
        window.location.href = failureUrl;
        return;
      }

      showSpinnerOverlay();
      window.location.href = redirecturl;
    })
    .catch(() => {
      showSpinnerOverlay();
      // On juno update failure, redirecting to landing page
      // Juno token will be re-validated and will handle following cases
      // Case 1: Display generic error page for expired token
      // Case 2: Start new flow, if token is valid.
      const failureUrl = '/donate/?token=' + templateData.token;
      window.location.href = failureUrl;
    });
};

/*
 * Handles for loaging any CAL event.
 */
export const logCalEvent = (message) => {
  fetcher('/donate/event/logger', {
    method: 'POST',
    body: JSON.stringify({
      message,
    }),
  });
};

/*
 * Handles for redirect to UL.
 */
export const handleLogIn = () => {
  const donationAmount = appStore.getDonationAmount();

  if (isAmountGreaterThanZero(donationAmount)) {
    let url = `/donate/member?token=${templateData.token}`;
    if (getParameterByName(FROMUL_QUERY_PARAM) === 'true') {
      url = `${url}&${FROMUL_QUERY_PARAM}=true`;
    }
    if (getParameterByName(FROMUOB_QUERY_PARAM) === 'true') {
      url = `${url}&${FROMUOB_QUERY_PARAM}=true`;
    }
    const sdkMeta = templateData.sdkMeta;
    const targetMeta = getParameterByName('targetMeta') || '';

    if (sdkMeta) {
      url = `${url}&${SDKMETA_QUERY_PARAM}=${sdkMeta}`;
    }
    if (targetMeta) {
      url = `${url}&targetMeta=${targetMeta}`;
    }

    updateDonation(url);
  } else {
    addShake();
  }
};

/*
 * Handles for redirect to guest.
 */
export const handleGuestCountryChange = (url) => {
  updateDonation(url, true);
};

/*
 * Handles if token is reused to access donate app to make second payment.
 */
export const handleTokenReuse = () => {
  const paymentStatus = templateData.paymentStatus || '';
  const token = templateData.token;
  if (paymentStatus === 'complete') {
    window.location.href = `/donate/paymentComplete?token=${token}`;
  }
};

export const removeDuplicateErrors = (errorMessages = []) => {
  return errorMessages.reduce((uniqueErr, errMsg) => {
    if (!uniqueErr.some((errMsgObj) => errMsgObj.value === errMsg.value)) {
      uniqueErr.push(errMsg);
    }
    return uniqueErr;
  }, []);
};

export const parseErrorResponse = (errors, errorMessages) => {
  let messages = [],
    allErrorCodes = errors.details;

  for (const i in allErrorCodes) {
    const code = allErrorCodes[i].name,
      message = errorMessages[code];
    if (message) {
      messages.push({
        key: i,
        value: message,
      });
    }
  }
  messages = removeDuplicateErrors(messages);

  if (messages.length === 0) {
    const message = errorMessages.GENERIC_ERROR;
    if (message) {
      messages.push({
        key: 0,
        value: message,
      });
    }
  }
  return messages;
};

export const parseValErrResp = (errors, dump, GENERIC_ERROR) => {
  const messages = [],
    allErrorCodes = errors.errorData || [];
  for (const allError in allErrorCodes) {
    const code =
        allErrorCodes[allError].errorCode || allErrorCodes[allError].code,
      message = dump[code];

    if (message) {
      messages.push({
        key: allError,
        value: message,
      });
    }
  }

  if (messages.length === 0) {
    const message = dump[GENERIC_ERROR];
    if (message) {
      messages.push({
        key: 0,
        value: message,
      });
    }
  }
  return messages;
};

export const getErrFields = (errors) => {
  const allFields = [],
    allErrorCodes = errors.errorData || [];
  for (const allError in allErrorCodes) {
    allFields.push({
      key: allError,
      type: allErrorCodes[allError].code,
      fields: allErrorCodes[allError].fields,
    });
  }

  if (allFields.length === 0) {
    allFields.push({
      key: 0,
      type: 'VALIDATION_ERROR',
    });
  }

  return allFields;
};

/**
 * Checks whether minimum donation restriction should be enforced.
 * @return {Boolean} true if currency code is USD
 */
export const shouldEnforceMinimumDonation = () => {
  // @TODO: Check for corpIp
  // TemplateData.isCorpIp
  if (
    templateData.donationAmountEditable &&
    ['USD'].indexOf(templateData.donationCode) >= 0
  ) {
    return true;
  }
  return false;
};

/**
 * Gets minimum donation for current currency
 * @return {Number} 1 for USD, 0 otherwise
 */
export const getMinimumDonation = () => {
  // @TODO: Check for corpIp
  // && !TemplateData.isCorpIp
  if (['USD'].indexOf(templateData.donationCode) >= 0) {
    return 1;
  }
  return 0;
};

/**
 * Validates the donation amount restriction for USD
 * @return { Boolean } Returns false if validation fails, true otherwise
 */
export const validateDonationAmountForUS = () => {
  if (shouldEnforceMinimumDonation()) {
    const donationAmount = appStore.getDonationAmount() || 0;
    const minimumDonation = getMinimumDonation();
    if (donationAmount < minimumDonation) {
      return false;
    }
  }

  return true;
};

/**
 * Checks whether to show the promotion for HurricaneHarvey or not
 * Please note: Currently disabled. Always returns false.
 */
export const shouldShowHurricaneHarveyPromotion = () => {
  return false;
  // Check merchant and user country
  // const userCountry = templateData.country;
  // const userLanguage = templateData.language;

  // return userCountry === 'US' && userLanguage === 'en';
};

/**
 * Sets country and locale in the provided string
 * @param  {String} str         String to set country and locale on
 * @param  {String} country     Country code like 'AU', 'US'
 * @param  {String} locale      locale code like 'en_AU', en_US'
 * @return {String}             Original string with country and locale
 */
export const setCountryAndLocale = (str) => {
  str = str.replace(new RegExp('{country}', 'g'), templateData.country);
  str = str.replace(
    new RegExp('{locale}', 'g'),
    `${templateData.language}_${templateData.country}`
  );
  str = str.replace(new RegExp('{brand}', 'g'), templateData.brand);
  str = str.replace(new RegExp('{language}', 'g'), templateData.language);
  return str;
};

export const isLTR = () => {
  const { contextLocalityDir } = templateData;
  return contextLocalityDir === 'ltr';
};

export const getPPCCStatus = () => {
  const { merchantPpccStatus } = templateData;
  return merchantPpccStatus
    ? merchantPpccStatus.toUpperCase()
    : merchantPpccStatus;
};

export const waitForElementToDisplay = (selector, time, callback) => {
  if (document.querySelector(selector) !== null) {
    callback(); // eslint-disable-line
  } else {
    setTimeout(function () {
      waitForElementToDisplay(selector, time, callback);
    }, time);
  }
};

export const observeUntilEventObserved = (targetNode, options, callback) => {
  const mutationObserver = new MutationObserver(function (mutations, observer) {
    callback(observer);
  });

  mutationObserver.observe(targetNode, options);
};

export const getInitialDataInDom = () => {
  const initialDataInDom = document.getElementById('initialData');
  if (initialDataInDom) {
    return JSON.parse(initialDataInDom.innerHTML || '{}');
  }
};

// export default {
// 	addShake: addShake,
// 	updateDonation: updateDonation,
// 	logCalEvent: logCalEvent,
// 	handleLogIn: handleLogIn,
// 	handleGuestCountryChange: handleGuestCountryChange,
// 	handleTokenReuse: handleTokenReuse,
// 	removeErrors: removeErrors,
// 	serializeFormData: serializeFormData,
// 	parseErrorResponse: parseErrorResponse,
// 	parseValErrResp: parseValErrResp,
// 	getErrFields: getErrFields,
// 	isLTR,
// 	getPPCCStatus,
// 	shouldEnforceMinimumDonation,
// 	validateDonationAmountForUS,
// 	shouldShowHurricaneHarveyPromotion,
// 	setCountryAndLocale,
// 	showSpinnerOverlay,
// 	hideSpinnerOverlay
// }
