import React from 'react';
import { PAYPAL_THEME, CaptionText, Link } from '@paypalcorp/pp-react';
import { StyleSheet, css } from 'aphrodite';
import { Message } from '@paypalcorp/worldready-react';

const { sysColorUnselectedContrast } = PAYPAL_THEME;

const styles = StyleSheet.create({
  disclaimerWrapper: {
    maxWidth: 415,
    margin: '24px auto 0',
  },
  disclaimer: {
    margin: 'auto',
  },
});

export default function VoucherFlowDisclaimer() {
  return (
    <div className={`voucher-disclaimer ${css(styles.disclaimerWrapper)}`}>
      <CaptionText
        style={{
          color: sysColorUnselectedContrast,
        }}
      >
        <Message
          id="landing.wr.voucher.legal"
          lineBreak={<br />}
          link={(innerMessage) => (
            <Link href="/donate/buttons/partner/terms/customer">
              {innerMessage}
            </Link>
          )}
        />
      </CaptionText>
    </div>
  );
}
