import TemplateData from '../../utility/getTemplateData';
import {
  formatCurrencyAsString,
  normalizeNumber,
  formatCurrencyNoDecimals,
  getCurrencyParts,
} from '../../utility/formator';
import AppStore from '../../stores/AppStore';
import {
  getMaximumDonationAlertMessage,
  isExternalFlow,
} from '../../utility/productIntegrationUtils';
import { MessageFormat } from '@paypalcorp/worldready';
import {
  formatNumber,
  getWorldReady,
  formatCurrency,
} from '../../utility/worldReadyUtils';

export const MIN_INPUT_WIDTH = 91;
export const DEFAULT_INPUT_FONTSIZE = 44;

/**
 * This is a copy of the function "formatAmountFromServer" from
 * "public/js/buttons/utilsV3/amountUtils.js"
 */
export function formatAmountFromServerDNW(value, { noDecimals = false } = {}) {
  if (isExternalFlow() || (noDecimals && value % 1 === 0)) {
    return formatCurrencyNoDecimals(value);
  }

  return formatAmount(value);
}

export function formatAmount(value = '') {
  return formatNumber(Number(value));
}

export function amountLengthValid(value = '') {
  const val = value.replace(/[^0-9]/g, '') || 0;

  if (val.length > 9) {
    return false;
  }
  return true;
}

const allowedKeys = [16, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
export function isKeyAllowed(keyCode) {
  return allowedKeys.indexOf(keyCode) > -1;
}

export function getMinimumDonationErrorMessage(id, amount = 1) {
  const worldReady = getWorldReady();

  const formattedDollarOne = formatCurrencyAsString({
    value: amount,
    currency: TemplateData.donationCode,
    maximumFractionDigits: 0,
  });

  return new MessageFormat(worldReady, {
    id: `${id}.error.minimumDonationUS`,
  }).format({ amount: formattedDollarOne });
}

/**
 * 1. Validate donation amount against the minimum donation amount
 * configured by clients or the $1 requirement for US, whichever is larger
 * 2. Validate if amount is greater than 0
 */

export function validateMinimumDonationAmount(id) {
  // Get client configured minimum donation from token
  let minimumDonationAmount = Number(TemplateData.minimumDonationAmount) || 0;
  if (TemplateData.donationCode === 'USD') {
    // For US donations, set the minimum amount limit as the lager amount
    // of the client's configuration and $1
    minimumDonationAmount = Math.max(minimumDonationAmount, 1);
  }

  const worldReady = getWorldReady();
  const donationAmount = Number(AppStore.getDonationAmount());

  let showValidationError = false,
    validationErrorMessage = '',
    validationErrorCode = '';
  if (
    donationAmount !== 0 &&
    minimumDonationAmount > 0 &&
    donationAmount < minimumDonationAmount
  ) {
    showValidationError = true;
    validationErrorMessage = getMinimumDonationErrorMessage(
      id,
      minimumDonationAmount
    );
    validationErrorCode = 'MINIMUM_DONATION_AMOUNT_VALIDATION_ERROR';
  } else if (donationAmount <= 0) {
    showValidationError = true;
    validationErrorMessage = new MessageFormat(worldReady, {
      id: `${id}.error.amount`,
    }).format();
    validationErrorCode = 'MINIMUM_DONATION_AMOUNT_VALIDATION_ERROR';
  }
  return {
    showValidationError,
    validationErrorMessage,
    validationErrorCode,
  };
}

export function validateMaximumDonationAmount(maxAmount, limitReason) {
  const maximumDonationAmount =
    Number(TemplateData.maximumDonationAmount) || maxAmount || 0;
  const donationAmount = Number(AppStore.getDonationAmount());
  let showValidationError = false,
    validationErrorMessage = '',
    validationErrorCode = '';
  if (maximumDonationAmount > 0 && donationAmount > maximumDonationAmount) {
    showValidationError = true;
    validationErrorMessage = getMaximumDonationAlertMessage(limitReason);
    validationErrorCode = 'MAXIMUM_DONATION_AMOUNT_VALIDATION_ERROR';
  }
  return { showValidationError, validationErrorMessage, validationErrorCode };
}

export function isAmountGreaterThanZero(amount) {
  return Number(amount) > 0;
}

/**
 * Returns griffin-normalized number or zero
 */
export function getNormalizedNumber(amount) {
  try {
    if (!amount) {
      return 0;
    }

    const normalizedNumber = normalizeNumber(amount);

    if (isNaN(normalizedNumber)) {
      return 0;
    }

    if (typeof normalizedNumber !== 'number' && normalizedNumber.errors) {
      return 0;
    }

    return normalizedNumber;
  } catch (e) {
    return 0;
  }
}

/**
 * Gets the width of the input field
 * @param  {HTMLElement} input The input element
 * @param  {String} text Value of the input element
 * @return {Number}      Width of the input element
 */
export function getInputWidth(field, text) {
  text = text || '0.00';

  // Get styles from the element
  const currentStyles = window.getComputedStyle(field) || {};
  const { fontWeight } = currentStyles;
  const fontSize = `${getFontSize(text)}px`;

  // Create a span element outside visible area
  const spanElem = document.createElement('span');
  spanElem.style.fontSize = fontSize;
  spanElem.style.fontFamily = 'PayPalSansBig-Regular';
  spanElem.style.fontWeight = fontWeight || '400';
  spanElem.style.position = 'fixed';
  spanElem.style.top = '-1000px';
  spanElem.style.left = '-1000px';
  spanElem.innerHTML = text;
  document.body.appendChild(spanElem);

  // Get width of the span and then remove it
  const width = spanElem.getBoundingClientRect().width + 2; // increase by 2px to fix a layout problem in Safari iOS browser.
  document.body.removeChild(spanElem);

  if (width < MIN_INPUT_WIDTH) {
    return MIN_INPUT_WIDTH;
  }
  return width;
}

const DIGITS_TO_FONT_SIZE_MAPPING = {
  8: 42,
  9: 40,
  10: 38,
};
export function getFontSize(value) {
  const digitCount = value.replace(/[\.,]/g, '').length;

  if (digitCount < 8) {
    return DEFAULT_INPUT_FONTSIZE;
  }

  return DIGITS_TO_FONT_SIZE_MAPPING[`${digitCount}`] || 44;
}

export function isRightSymbol() {
  const currencyParts = getCurrencyParts();
  const [firstParts = {}] = currencyParts;
  return firstParts.type !== 'currencySymbol';
}

export function formatCurrencyWithoutDecimals(params, format) {
  if (!params.value || !params.currency) {
    return '';
  }
  const options = {
    currency: params.currency,
    maximumFractionDigits: 0,
    style: 'international',
  };

  return formatCurrency(params.value, options);
}
