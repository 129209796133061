import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  BodyText,
  CaptionText,
  ActionMenu,
  Button,
  ShareFacebookIcon,
  EmailIcon,
  ShareMessengerIcon,
  ShareWhatsappIcon,
  ShareTwitterIcon,
  LinkIcon,
  CheckmarkBackgroundSmIcon,
} from '@paypalcorp/pp-react';
import { StyleSheet, css } from '../../utility/paypalAphrodite';
import SocialShareEmail from './socialShareEmail';
import SocialShareMobile from './socialShareMobile';
import SocialShareFacebook from './socialShareFacebook';
import SocialShareMessenger from './socialShareMessenger';
import SocialShareWhatsapp from './socialShareWhatsapp';
import SocialShareTwitter from './socialShareTwitter';
import SocialShareCopyLink from './socialShareCopyLink';
import Illustration from '../illustrations/Illustration';
import CopyLink from '../CopyLink';
import { isInContext, isMobileOrTablet } from '../../utility/inContextDonation';
import { isExternalFlow } from '../../utility/productIntegrationUtils';
import { EVENT_NAME } from '../../utility/constants';
import { copyToClipboard } from '../../setup/lib/copyToClipBoard';
import templateData from '../../utility/getTemplateData';
import { trackLinkClick, trackPageIM } from '../../fpti/fpti';
import { FPTI_TAGS, LINK_NAME } from '../../fpti/fptiConstants';
import { PAYPAL_THEME } from '@paypalcorp/pp-react-theme';
import { isCampaigns } from '../../utility/productIntegrationUtils';
import { Col } from '@paypalcorp/pp-react';
import { IconButton, OverflowIcon } from '@paypalcorp/pp-react';

const { sysColorBackgroundHighContrast, sysColorSuccessMain } = PAYPAL_THEME;

const styles = StyleSheet.create({
  socialContainer: {
    display: 'flex',
    ':nth-child(1n) > *': {
      marginRight: 10,
    },
  },
  socialContainerMobile: {
    marginLeft: 10,
  },
  shareMenu: {
    flexDirection: 'column',
  },
  copyLinkModal: {
    paddingBottom: 30,
  },
  copyLinkContainer: {
    margin: '0 0 60px',
  },
  copyLinkHeader: {
    color: sysColorBackgroundHighContrast,
    marginTop: 20,
    marginBottom: 0,
    fontSize: '1.6rem',
  },
  copyLinkSubheader: {
    color: sysColorBackgroundHighContrast,
    marginTop: 28,
    marginRight: 'auto',
    fontSize: '1.15rem',
  },
  copiedText: {
    color: sysColorSuccessMain,
    marginLeft: '9px',
  },
});

const getPlatformType = (isMobile) => {
  return isMobile ? 'api' : 'web';
};

const getFBMessengerUrl = (
  isMobile,
  encodedUrl,
  appId,
  quote,
  redirect_uri
) => {
  return isMobile
    ? `fb-messenger://share/?link=${encodedUrl}&app_id=${appId}&quote=${quote}`
    : `https://www.facebook.com/dialog/send?app_id=${appId}&display=popup&link=${encodedUrl}&redirect_uri=${redirect_uri}`;
};

const getIsActionMenu = (renderMode) => {
  return renderMode === 'sheet';
};

const isActionOrPostDonationModule = (isActionMenu, isPostDonationModule) => {
  return isActionMenu || isPostDonationModule;
};

const getIconSize = () => {
  return isMobileOrTablet() ? 'lg' : 'sm';
};

const isValid = (value, isPostDonationModule) => {
  return value && !isPostDonationModule;
};

const getColor = (color, type) => {
  return color[type] ?? color;
};
export default function SocialShare({
  shareUrl,
  socialCampaign,
  isMobile,
  isPostDonationModule,
  ...props
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const encodedUrl = encodeURIComponent(shareUrl);
  const appId = '321983581337375';
  const emailUrl = `mailto:?subject=${socialCampaign.emailSubject}&body=${socialCampaign.emailBody}`;
  const facebookUrl = `https://www.facebook.com/dialog/share?app_id=${appId}&display=popup&quote=${socialCampaign.facebookMessage}&href=${encodedUrl}`;
  const fbMessengerUrl = getFBMessengerUrl(
    isMobile,
    encodedUrl,
    appId,
    socialCampaign.facebookMessage,
    templateData.paypalHostName
  );
  const whatsappUrl = `https://${getPlatformType(
    isMobile
  )}.whatsapp.com/send?&text=${socialCampaign.whatsappMessage}%20${encodedUrl}`;
  const twitterUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${socialCampaign.twitterMessage}`;
  const socialShareAltText = socialCampaign.title;
  const isActionMenu = getIsActionMenu(props.renderMode);
  const socialItems = isActionOrPostDonationModule(
    isActionMenu,
    isPostDonationModule
  )
    ? generateMenuOptions()
    : [];

  function handleSocialLinkClick(url, type) {
    window.open(
      url,
      'popup',
      'toolbar=0,status=0,width=640,height=340,scrollable=true'
    );
    handleAnalytics(`social_share_${type}`);
  }

  function handleOpenCopyModal() {
    setIsModalOpen(true);
  }

  function handleMobileClick() {
    if (navigator.share) {
      navigator.share({
        title: socialCampaign.mobileTitle,
        text: socialCampaign.mobileText,
        url: shareUrl,
      });
    } else {
      window.open(
        shareUrl,
        'popup',
        'toolbar=0,status=0,width=640,height=340,scrollable=true'
      );
    }
    handleAnalytics('mobile_share');
  }

  function handleMenuSelection(index) {
    if (socialItems[index].type === 'copy_link') {
      copyToClipboard('copy-link-text');
      setIsCopied(true);
      handleAnalytics('copy_link');
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    } else {
      if (socialItems[index].type === 'email') {
        handleAnalytics('email');
        window.open(socialItems[index].url);
      } else if (socialItems[index].type === 'mobile_share') {
        handleMobileClick();
      } else {
        handleSocialLinkClick(
          socialItems[index].url,
          socialItems[index].title.toLowerCase()
        );
      }
      setIsCopied(false);
      setIsShareMenuOpen(false);
    }
  }

  function handleAnalytics(type) {
    if (props.analytics) {
      props.analytics(type);
    }
  }

  function generateMenuOptions() {
    const options = [];
    const iconSize = getIconSize();

    if (isValid(socialCampaign.email, isPostDonationModule)) {
      options.push({
        title: socialCampaign.sheet.email,
        url: emailUrl,
        type: 'email',
        itemLeft: (
          <EmailIcon
            size="lg"
            style={{ color: getColor(props.color, 'email') }}
          />
        ),
      });
    }
    if (isValid(socialCampaign.facebook, isPostDonationModule)) {
      options.push({
        title: socialCampaign.sheet.facebook,
        url: facebookUrl,
        type: 'facebook',
        itemLeft: (
          <ShareFacebookIcon
            size="lg"
            style={{ color: getColor(props.color, 'facebook') }}
          />
        ),
      });
    }
    if (socialCampaign.twitter) {
      options.push({
        title: socialCampaign.sheet.twitter,
        url: twitterUrl,
        type: 'twitter',
        itemLeft: (
          <ShareTwitterIcon
            size={iconSize}
            style={{ color: getColor(props.color, 'twitter') }}
          />
        ),
      });
    }
    if (socialCampaign.facebookMessenger) {
      options.push({
        title: socialCampaign.sheet.messenger,
        url: fbMessengerUrl,
        type: 'messenger',
        itemLeft: (
          <ShareMessengerIcon
            size={iconSize}
            style={{ color: getColor(props.color, 'facebookMessenger') }}
          />
        ),
      });
    }
    if (socialCampaign.whatsapp) {
      options.push({
        title: socialCampaign.sheet.whatsapp,
        url: whatsappUrl,
        type: 'whatsapp',
        itemLeft: (
          <ShareWhatsappIcon
            size={iconSize}
            style={{ color: getColor(props.color, 'whatsapp') }}
          />
        ),
      });
    }
    if (socialCampaign.copyLink) {
      options.push({
        title: (
          <>
            {isCopied ? (
              <>
                {isMobileOrTablet() ? (
                  <>
                    <span>{socialCampaign.sheet.copyLink}</span>
                    <span>
                      <CheckmarkBackgroundSmIcon
                        size="xs"
                        color="sysColorSuccessMain"
                      />
                      <span className={`${css(styles.copiedText)}`}>
                        {socialCampaign.copyLinkCopied}
                      </span>
                    </span>
                  </>
                ) : (
                  <span className="sheetCopiedLabel">
                    <span>{socialCampaign.copyLinkCopied}</span>
                    <CheckmarkBackgroundSmIcon
                      size="xs"
                      color="sysColorSuccessMain"
                    />
                  </span>
                )}
              </>
            ) : (
              <span>{socialCampaign.sheet.copyLink}</span>
            )}
          </>
        ),
        type: 'copy_link',
        itemLeft: (
          <>
            <textarea
              id="copy-link-text"
              value={shareUrl}
              readOnly
              style={{
                position: 'absolute',
                opacity: 0.01,
                height: 0,
                overflow: 'hidden',
              }}
            >
              {shareUrl}
            </textarea>
            <LinkIcon
              name="share-link"
              size={iconSize}
              style={{ color: getColor(props.color, 'copyLink') }}
            />
          </>
        ),
      });
    }

    return options;
  }

  function handleMobileSocialShareButtonClick(event) {
    if (!isPostDonationModule) {
      setIsShareMenuOpen(true);
      trackLinkClick(LINK_NAME.SOCIAL_SHARE, {
        eventName: EVENT_NAME.DW_GIVING_PAYMENT_SUCCESS_SHARE_PRESSED,
      });

      trackPageIM({
        pageSpec: {
          pgrp: window.fpti.pgrp,
          page: `${window.fpti.pgrp}:mobile::`,
        },
        additionalData: {
          [FPTI_TAGS.EVENT_NAME]: EVENT_NAME.DW_GIVING_SHARE_SCREEN_SHOWN,
        },
      });
    } else {
      event.stopPropagation();
      setIsShareMenuOpen(!isShareMenuOpen);
    }
  }

  function handleSocialShareSheetClose() {
    setIsShareMenuOpen(false);
    setIsCopied(false);
    if (!isPostDonationModule) {
      trackLinkClick(LINK_NAME.SOCIAL_SHARE_DISMISS, {
        eventName: EVENT_NAME.DW_GIVING_SHARE_DISMISS_PRESSED,
      });
    }
  }

  function socialShareOverflow() {
    return (
      <>
        <CaptionText className="post-donation-share-text">
          {socialShareAltText.share}
        </CaptionText>
        {socialCampaign.facebook && (
          <SocialShareFacebook
            url={facebookUrl}
            handleSocialLinkClick={handleSocialLinkClick}
            linkTitle={socialShareAltText.facebook}
            color={props.color}
          />
        )}
        {socialCampaign.email && (
          <SocialShareEmail
            url={emailUrl}
            handleAnalytics={handleAnalytics}
            linkTitle={socialShareAltText.email}
            color={props.color}
          />
        )}
        <IconButton
          onClick={handleMobileSocialShareButtonClick}
          data-testid="social-share-overflow"
          size="sm"
          buttonType="secondary"
          svgIconComponent={OverflowIcon}
        >
          {socialShareAltText.overflow}
        </IconButton>
      </>
    );
  }

  function mobileSocialShare() {
    const menuProps = isPostDonationModule && {
      className: 'post-donation-share-menu',
      mobileTitle: socialShareAltText.share,
      itemClassName: 'post-donation-share-item-menu',
    };
    return (
      <>
        {(isPostDonationModule && socialShareOverflow()) || (
          <Button onClick={handleMobileSocialShareButtonClick}>
            {props.buttonText}
          </Button>
        )}
        <ActionMenu
          isOpen={isShareMenuOpen}
          onClose={handleSocialShareSheetClose}
          onSelection={(index) => handleMenuSelection(index)}
          options={socialItems}
          {...menuProps}
        ></ActionMenu>
      </>
    );
  }

  function regularSocialShare() {
    return (
      <>
        {socialCampaign.email && (
          <SocialShareEmail
            url={emailUrl}
            handleAnalytics={handleAnalytics}
            linkTitle={socialShareAltText.email}
            color={props.color}
          />
        )}
        {navigator.share && socialCampaign.mobile && (
          <SocialShareMobile
            handleMobileClick={handleMobileClick}
            linkTitle={socialShareAltText.mobile}
            color={props.color}
          />
        )}
        {socialCampaign.facebook && (
          <SocialShareFacebook
            url={facebookUrl}
            handleSocialLinkClick={handleSocialLinkClick}
            linkTitle={socialShareAltText.facebook}
            color={props.color}
          />
        )}
        {socialCampaign.facebookMessenger && (
          <SocialShareMessenger
            url={fbMessengerUrl}
            handleSocialLinkClick={handleSocialLinkClick}
            linkTitle={socialShareAltText.messenger}
            color={props.color}
          />
        )}
        {socialCampaign.whatsapp && (
          <SocialShareWhatsapp
            url={whatsappUrl}
            handleSocialLinkClick={handleSocialLinkClick}
            linkTitle={socialShareAltText.whatsapp}
            color={props.color}
          />
        )}
        {socialCampaign.twitter && (
          <SocialShareTwitter
            url={twitterUrl}
            handleSocialLinkClick={handleSocialLinkClick}
            linkTitle={socialShareAltText.twitter}
            color={props.color}
          />
        )}
        {socialCampaign.copyLink && (
          <SocialShareCopyLink
            handleOpenCopyModal={handleOpenCopyModal}
            linkTitle={socialShareAltText.copyLink}
            color={props.color}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div
        className={`${css(styles.socialContainer)} ${
          isActionMenu && !isPostDonationModule ? css(styles.shareMenu) : ''
        } ${isMobileOrTablet() ? css(styles.socialContainerMobile) : ''} `}
        style={{ justifyContent: props.align, ...props.style }}
        data-testid="social-share-container"
      >
        {isActionMenu || isPostDonationModule
          ? mobileSocialShare()
          : regularSocialShare()}
      </div>
      {isInContext() && isExternalFlow() && !isPostDonationModule ? (
        <section>
          <CopyLink
            url={shareUrl}
            analytics={handleAnalytics}
            messages={{
              copy: socialCampaign.copyLinkCopy,
              copied: socialCampaign.copyLinkCopied,
            }}
          />
        </section>
      ) : null}
      {socialCampaign.copyLink && (
        <Dialog
          containerClassName="copy-link-modal"
          id="copyLinkModal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <Col style={{ justifyContent: 'center', display: 'flex' }}>
            <Illustration
              type="send_bubble_hearts"
              size="medium"
              ariaHidden={true}
            />
          </Col>

          <BodyText className={`${css(styles.copyLinkHeader)} text-center`}>
            {socialCampaign.copyLinkHeader}
          </BodyText>
          <CaptionText
            className={`${css(styles.copyLinkSubheader)} text-center`}
          >
            {isCampaigns()
              ? socialCampaign.copyLinkSubheaderBefore
              : socialCampaign.copyLinkSubheader}
          </CaptionText>
          <section className={`${css(styles.copyLinkContainer)}`}>
            <div className={`${css(styles.copyLinkFieldContainer)}`}>
              <CopyLink
                url={shareUrl}
                analytics={handleAnalytics}
                messages={{
                  copy: socialCampaign.copyLinkCopy,
                  copied: socialCampaign.copyLinkCopied,
                }}
              />
            </div>
          </section>
        </Dialog>
      )}
    </>
  );
}

SocialShare.propTypes = {
  shareUrl: PropTypes.string,
  socialCampaign: PropTypes.object,
};

SocialShare.defaultProps = {
  align: 'center',
  handleAnalytics: null,
};
