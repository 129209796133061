import templateData from '../utility/getTemplateData';
import { getParameterByName } from './queryStringHelper';

const REMEMBERED_AUTH_STATE = 'REMEMBERED';
const LOGGEDIN_AUTH_STATE = 'LOGGEDIN';
const FROMUL_QUERY_PARAM = 'fromUL';

export function getForcedLoginUrlWithoutOt() {
  return templateData.forcedLoginUrlWithoutOt;
}

export function getHardLogoutUrl(force = false) {
  return templateData.hardLogoutUrl;
}

export function isOneTouchUser(authState) {
  return (
    templateData.authState === REMEMBERED_AUTH_STATE ||
    authState === REMEMBERED_AUTH_STATE
  );
}

export function isLoggedInUser(authState) {
  return (
    templateData.authState === LOGGEDIN_AUTH_STATE ||
    authState === LOGGEDIN_AUTH_STATE
  );
}

export function isAuthenticatedUser() {
  return isOneTouchUser() || isLoggedInUser();
}

export function shouldShowUserBanner() {
  if (isOneTouchUser()) {
    return true;
  }

  const fromUL = getParameterByName(FROMUL_QUERY_PARAM) || '';

  if (!fromUL && isLoggedInUser()) {
    return true;
  }

  if (fromUL === 'true' && isLoggedInUser()) {
    return false;
  }

  // Default to not showing the banner
  return false;
}
