import React, { useContext, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';
import {
  PostDonationShimmer,
  PostDonationCreateFundraiser,
  PostDonationGivingSummary,
  PostDonationSetFavCharity,
} from '@paypalcorp/donate-react';
import { isInContext } from '../../utility/inContextDonation';
import { POST_DONATION_MODULES } from '../../utility/constants';
import { fetcher } from '../../utility/domUtils';
import TemplateData from '../../utility/getTemplateData';
import { trackErrorIM, trackLinkClick } from '../../fpti/fpti';

import { FPTI_TAGS, FPTI_SPEC } from '../../fpti/fptiConstants';
import { EVENT_NAME } from '../../utility/constants';
import { trackEventWithData } from '../../fpti/analytics';
import { Message } from '@paypalcorp/worldready-react';
import templateData from '../../utility/getTemplateData';
import { updateGlobalFPTI } from '../../fpti/helper';

const AlertSuccessContentPath =
  'confirmation.confirmation.post.donation.common.successAlertContent';

const PostDonationModules = ({
  children,
  transactionId,
  donationAmount,
  metaData,
  module,
  postDonationSetFavCharity = false,
  successAlertTarget,
  showSuccessAlert = false,
  isPPGFCountry = false,
  isFirstTimeDonor = false,
  hasMultipleFavouriteCharities = false,
}) => {
  const POST_DONATION_SET_FAVORITE_CHARITY =
    '/donate/api/postdonation/setfavoritecharity';
  const CREATE_FUNDRAISER_URL = metaData?.npID
    ? `/pools/c/create-crowdfunding/basics?npid=${metaData.npID}&_fpti.source=post_donation`
    : '/pools/c/create-crowdfunding/nonprofit?_fpti.source=post_donation';

  const [dynamicProps, setDynamicProps] = useState({});
  const worldReady = useContext(WorldReadyContext);

  useEffect(() => {
    let showModuleEventName;
    switch (module) {
      case POST_DONATION_MODULES.SET_FAVORITE_CHARITY:
        showModuleEventName =
          FPTI_SPEC.CONFIRMATION_PAGE_SET_FAV_CHARITY_MODULE_SHOWN;
        break;
      case POST_DONATION_MODULES.CREATE_FUNDRAISER:
        showModuleEventName =
          FPTI_SPEC.CONFIRMATION_PAGE_CREATE_FUNDRAISER_MODULE_SHOWN;
        break;
      case POST_DONATION_MODULES.GIVING_SUMMARY:
        showModuleEventName = FPTI_SPEC.CONFIRMATION_PAGE_INSIGHTS_MODULE_SHOWN;
        break;
      default:
        showModuleEventName = undefined;
    }
    if (module) {
      updateGlobalFPTI({
        [FPTI_TAGS.MODULE]: module,
      });

      if (showModuleEventName) {
        trackEventWithData(showModuleEventName, {
          [FPTI_TAGS.FLOW]: TemplateData.product,
          [FPTI_TAGS.MODULE]: module,
          [FPTI_TAGS.ENCR_RCVR_ID]: TemplateData.merchantId,
          [FPTI_TAGS.NP_ID]: metaData?.npID,
        });
      }
    }
  }, [module]);

  const setFavCharityError = (error) => {
    setDynamicProps({
      showErrorAlert: true,
      showIconContent: false,
      showButton: true,
      showSuccessAlert: false,
      showLoadingSpinner: false,
    });
    trackErrorIM({
      errorCode: error?.status || 400,
      errorMessage:
        error?.message ||
        'fundraiserserv did not respond 200 OK for set favorite charity request',
      fieldName: 'Set favorite charity',
      additionalData: {
        [FPTI_TAGS.EVENT_NAME]:
          EVENT_NAME.DONATE_DONOR_VIEW_POST_DONATION_SET_FAVORITE_CHARITY_ERROR,
        [FPTI_TAGS.FLOW]: TemplateData.product,
        [FPTI_TAGS.MODULE]: module,
        [FPTI_TAGS.ENCR_RCVR_ID]: TemplateData.merchantId,
        [FPTI_TAGS.NP_ID]: metaData?.npID,
      },
    });
  };

  const onBtnClick = () => {
    if (module === POST_DONATION_MODULES.SET_FAVORITE_CHARITY) {
      setDynamicProps({
        ...dynamicProps,
        showLoadingSpinner: true,
      });
      trackLinkClick(module, {
        eventName:
          EVENT_NAME.DONATE_DONOR_VIEW_POST_DONATION_SET_FAV_CHARITY_MODULE_PRESSED,
        additionalData: {
          [FPTI_TAGS.FLOW]: TemplateData.product,
          [FPTI_TAGS.MODULE]: module,
          [FPTI_TAGS.ENCR_RCVR_ID]: TemplateData.merchantId,
          [FPTI_TAGS.NP_ID]: metaData?.npID,
        },
      });
      if (templateData.sfcUrl) {
        setDynamicProps({
          showLoadingSpinner: false,
        });
        window.location.href = templateData.sfcUrl;
      } else {
        fetcher(
          `${POST_DONATION_SET_FAVORITE_CHARITY}?token=${TemplateData.token}`
        )
          .then(async (response) => {
            if (!response.ok) {
              let err = new Error(
                response.statusText || 'FAILED TO SET FAVORITE CHARITY'
              );
              err.response = await response.json();
              err.status = response.status;
              throw err;
            }
            return await response.json();
          })
          .then((result) => {
            if (result?.data?.clientData?.postDonationSetFavCharity) {
              setDynamicProps({
                showErrorAlert: false,
                showIconContent: true,
                showButton: false,
                showSuccessAlert: false,
                showLoadingSpinner: false,
              });
              trackEventWithData(
                FPTI_SPEC.CONFIRMATION_PAGE_SET_FAV_CHARITY_SUCCESS,
                {
                  [FPTI_TAGS.FLOW]: TemplateData.product,
                  [FPTI_TAGS.MODULE]: module,
                  [FPTI_TAGS.ENCR_RCVR_ID]: TemplateData.merchantId,
                }
              );
            } else {
              setFavCharityError();
            }
          })
          .catch((err) => {
            setFavCharityError(err);
          });
      }
    } else if (module === POST_DONATION_MODULES.CREATE_FUNDRAISER) {
      trackLinkClick(module, {
        eventName:
          EVENT_NAME.DONATE_DONOR_VIEW_POST_DONATION_CREATE_FUNDRAISER_MODULE_PRESSED,
        additionalData: {
          [FPTI_TAGS.FLOW]: TemplateData.product,
          [FPTI_TAGS.MODULE]: module,
          [FPTI_TAGS.ENCR_RCVR_ID]: TemplateData.merchantId,
        },
      });
      if (templateData.charityFundraiserCreateUrl) {
        window.location.href = templateData.charityFundraiserCreateUrl;
      }
    } else if (module === POST_DONATION_MODULES.GIVING_SUMMARY) {
      trackLinkClick(module, {
        eventName:
          EVENT_NAME.DONATE_DONOR_VIEW_POST_DONATION_INSIGHTS_MODULE_PRESSED,
        additionalData: {
          [FPTI_TAGS.FLOW]: TemplateData.product,
          [FPTI_TAGS.MODULE]: module,
          [FPTI_TAGS.ENCR_RCVR_ID]: TemplateData.merchantId,
        },
      });
      if (templateData.impactSummaryUrl) {
        window.location.href = `${templateData.impactSummaryUrl}${
          templateData.impactSummaryUrl.indexOf('?') > 0 ? '&' : '?'
        }transaction_id=${transactionId}`;
      } else {
        let redirectUrl = '';
        if (transactionId) {
          if (isFirstTimeDonor) {
            redirectUrl = `/fundraiser/hub?flow=donate&_fpti.source=post_donation&txn_id=${transactionId}&firstTimeDonor=true`;
          } else {
            redirectUrl = `/fundraiser/hub?flow=donate&_fpti.source=post_donation&txn_id=${transactionId}`;
          }
        } else {
          redirectUrl = '/fundraiser/hub';
        }
        window.open(redirectUrl, isInContext() ? '' : '_self');
      }
    }
  };

  const onModalSubmit = () => {
    window.location.href = CREATE_FUNDRAISER_URL;
    trackLinkClick(module, {
      eventName:
        EVENT_NAME.DONATE_DONOR_VIEW_POST_DONATION_CREATE_FUNDRAISER_MODAL_PRESSED,
      additionalData: {
        [FPTI_TAGS.FLOW]: TemplateData.product,
        [FPTI_TAGS.MODULE]: module,
        [FPTI_TAGS.ENCR_RCVR_ID]: TemplateData.merchantId,
      },
    });
  };

  const onModalOpen = () => {
    trackLinkClick(module, {
      eventName:
        EVENT_NAME.DONATE_DONOR_VIEW_POST_DONATION_CREATE_FUNDRAISER_MODAL_SHOWN,
      additionalData: {
        [FPTI_TAGS.FLOW]: TemplateData.product,
        [FPTI_TAGS.MODULE]: module,
        [FPTI_TAGS.ENCR_RCVR_ID]: TemplateData.merchantId,
      },
    });
  };

  const createFundraiserContent = useCallback(() => {
    return {
      bodyContent: new MessageFormat(worldReady, {
        id: 'confirmation.confirmation.post.donation.fundraiser.bodyContent',
      }).format(),

      buttonText: new MessageFormat(worldReady, {
        id: 'confirmation.confirmation.post.donation.fundraiser.buttonText',
      }).format(),

      modalContent: {
        title: new MessageFormat(worldReady, {
          id: 'confirmation.confirmation.post.donation.fundraiser.modalContent.title',
        }).format({ orgName: TemplateData.donationName }),

        bodyContent: new MessageFormat(worldReady, {
          id: 'confirmation.confirmation.post.donation.fundraiser.modalContent.bodyContent',
        }).format(),

        listContent: [
          new MessageFormat(worldReady, {
            id: 'confirmation.confirmation.post.donation.fundraiser.modalContent.listContent.list0',
          }).format(),

          new MessageFormat(worldReady, {
            id: 'confirmation.confirmation.post.donation.fundraiser.modalContent.listContent.list1',
          }).format(),

          new MessageFormat(worldReady, {
            id: `confirmation.confirmation.post.donation.fundraiser.modalContent.listContent.${
              isPPGFCountry ? 'ppgf.' : ''
            }list2`,
          }).format(),
        ],

        captionText: new MessageFormat(worldReady, {
          id: 'confirmation.confirmation.post.donation.fundraiser.modalContent.captionText',
        }).format(),

        buttonText: new MessageFormat(worldReady, {
          id: 'confirmation.confirmation.post.donation.fundraiser.modalContent.buttonText',
        }).format(),
      },
      successAlertContent: new MessageFormat(worldReady, {
        id: AlertSuccessContentPath,
      }).format(),
    };
  }, []);

  const givingSummaryContent = useCallback(() => {
    return {
      bodyContent: (
        <Message
          id={`confirmation.confirmation.post.donation.giving.summary.${
            isFirstTimeDonor ? 'firstTime' : 'seasoned'
          }.bodyContent`}
          donationAmount={<strong>{donationAmount}</strong>}
        />
      ),
      buttonText: new MessageFormat(worldReady, {
        id: 'confirmation.confirmation.post.donation.giving.summary.buttonText',
      }).format(),

      successAlertContent: new MessageFormat(worldReady, {
        id: AlertSuccessContentPath,
      }).format(),
    };
  }, [donationAmount]);

  const setFavCharityContent = useCallback(() => {
    return {
      bodyContent: new MessageFormat(worldReady, {
        id: `confirmation.confirmation.post.donation.favorite.charity.${
          hasMultipleFavouriteCharities ? 'multiple.' : ''
        }bodyContent`,
      }).format(),

      buttonText: new MessageFormat(worldReady, {
        id: `confirmation.confirmation.post.donation.favorite.charity.${
          hasMultipleFavouriteCharities ? 'multiple.' : ''
        }buttonText`,
      }).format(),

      iconContent: new MessageFormat(worldReady, {
        id: 'confirmation.confirmation.post.donation.favorite.charity.iconContent',
      }).format({ orgName: TemplateData.donationName }),

      errorAlertContent: new MessageFormat(worldReady, {
        id: 'confirmation.confirmation.post.donation.favorite.charity.errorAlertContent',
      }).format(),

      successAlertContent: new MessageFormat(worldReady, {
        id: AlertSuccessContentPath,
      }).format(),
    };
  }, [hasMultipleFavouriteCharities]);

  if (!module) {
    return <PostDonationShimmer />;
  }

  return (
    <>
      {module === POST_DONATION_MODULES.SET_FAVORITE_CHARITY && (
        <PostDonationSetFavCharity
          content={setFavCharityContent()}
          onBtnClick={onBtnClick}
          successAlertTarget={successAlertTarget}
          showSuccessAlert={showSuccessAlert}
          showButton={!postDonationSetFavCharity}
          showIconContent={postDonationSetFavCharity}
          {...dynamicProps}
        />
      )}
      {module === POST_DONATION_MODULES.CREATE_FUNDRAISER && (
        <PostDonationCreateFundraiser
          content={createFundraiserContent()}
          onBtnClick={onBtnClick}
          successAlertTarget={successAlertTarget}
          showSuccessAlert={showSuccessAlert}
          onModalSubmit={onModalSubmit}
          onModalOpen={onModalOpen}
          {...dynamicProps}
        />
      )}
      {module === POST_DONATION_MODULES.GIVING_SUMMARY && (
        <PostDonationGivingSummary
          content={givingSummaryContent()}
          onBtnClick={onBtnClick}
          successAlertTarget={successAlertTarget}
          showSuccessAlert={showSuccessAlert}
          {...dynamicProps}
        />
      )}
      {module === POST_DONATION_MODULES.SHARE && children}
    </>
  );
};

PostDonationModules.propTypes = {
  children: PropTypes.element,
  donationAmount: PropTypes.string,
  metaData: PropTypes.object,
  module: PropTypes.string,
  postDonationSetFavCharity: PropTypes.bool,
  successAlertTarget: PropTypes.string,
  showSuccessAlert: PropTypes.bool,
  isPPGFCountry: PropTypes.bool,
  transactionId: PropTypes.string,
  isFirstTimeDonor: PropTypes.bool,
  hasMultipleFavouriteCharities: PropTypes.bool,
};

export default PostDonationModules;
