import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@paypalcorp/pp-react-links';
import { BodyText } from '@paypalcorp/pp-react-text';
import templateData from '../../utility/getTemplateData';
import { showSpinnerOverlay } from '../../utility/helper';
import { getHardLogoutUrl, isOneTouchUser } from '../../utility/authUtils';
import { sanitize } from '../../utility/domUtils';
import { isExternalFlow } from '../../utility/productIntegrationUtils';
import BackButton from '../BackButton';
import { isInContext } from '../../utility/inContextDonation';
import { trackLinkClick } from '../../fpti/fpti';
import { FPTI_TAGS, LINK_NAME } from '../../fpti/fptiConstants';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { Avatar } from '@paypalcorp/pp-react';
import { Message } from '@paypalcorp/worldready-react';

class UserDetailsBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerUrl: templateData.bannerUrl,
      showCCErrorMessage: templateData.showCCErrorMessage,
    };
  }
  static propTypes = {
    userDetails: PropTypes.object,
  };

  createLoggedInMessage = (firstName) => {
    return isOneTouchUser() ? (
      <Message
        id="common.oneTouch.wr.banner.oneTouchGreeting"
        sup={(innerMessage) => <sup>{innerMessage}</sup>}
        firstName={sanitize(firstName)}
      />
    ) : (
      <Message
        id="common.oneTouch.banner.fullLoginGreeting"
        firstName={sanitize(firstName)}
      />
    );
  };

  handleNotYouLinkClick = () => {
    showSpinnerOverlay();
    trackLinkClick(LINK_NAME.NOT_YOU, {
      additionalData: {
        [FPTI_TAGS.AUTH_STATE]: templateData.authState,
        [FPTI_TAGS.VERSION]: templateData.sessionDeprecationEnabled
          ? 'V2'
          : 'V1',
      },
    });
    this.logout();
  };

  logout = () => {
    window.location.href = getHardLogoutUrl();
  };

  determineUserDetailsBannerClass() {
    const backgroundImageExists = !_isEmpty(this.state.bannerUrl);
    return classNames({
      gn_user_details_banner: isExternalFlow() && !backgroundImageExists,
      gn_user_details_banner_no_border:
        isExternalFlow() && backgroundImageExists,
      user_details_banner: !isExternalFlow() && !backgroundImageExists,
      user_details_banner_no_border: !isExternalFlow() && backgroundImageExists,
    });
  }

  render() {
    const { userDetails = {} } = this.props;
    const firstName = userDetails.firstName || '';
    const profilePhotoUrl = userDetails.thumbnailUrl;
    const loggedInMessage = this.createLoggedInMessage(firstName);
    const isLandingPage = templateData.viewName === 'landing';
    const shouldShowBackButton =
      ((isInContext() && !this.state.showCCErrorMessage) || isExternalFlow()) &&
      !isLandingPage;

    return (
      <div className={this.determineUserDetailsBannerClass()}>
        {shouldShowBackButton && (
          <BackButton inverse={templateData.hasBanner} />
        )}
        <Avatar generic="profile" size="sm" src={profilePhotoUrl} />
        <div className="details-container">
          <div className="details">
            <BodyText className="hi-msg">{loggedInMessage}</BodyText>
            <div className="not-you-link">
              <Link href="#" onClick={this.handleNotYouLinkClick}>
                <Message id="common.oneTouch.banner.notYou" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UserDetailsBanner;
