import React from 'react';
import HeaderDNW from '../headerDNW';
import GNHeader from '../GN/gnHeader';
import PropTypes from 'prop-types';
import { isExternalFlow } from '../../utility/productIntegrationUtils';

const DonateHeader = ({ isLanding }) => {
  return (
    <div>
      {isExternalFlow() ? (
        <GNHeader />
      ) : (
        <HeaderDNW isLanding={isLanding ?? false} />
      )}
    </div>
  );
};

DonateHeader.propTypes = {
  isLanding: PropTypes.bool,
};

export default DonateHeader;
