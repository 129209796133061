export function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getParameterByNameFromURL(name, url) {
  name = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getValuesFromULState(keys, url) {
  const queryStringObj = {};
  keys.forEach((key) => {
    queryStringObj[key] = getParameterByName(key, url);
  });
  return queryStringObj;
}

export function getParametersFromUrl(url) {
  if (!url) {
    url = window.location.href;
  }

  if (url.indexOf('?') >= 0) {
    let queryString = /[^?]*$/.exec(url)[0]; // eslint-disable-line
    return JSON.parse(
      '{"' +
        decodeURI(queryString)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(new RegExp('=', 'g'), '":"') +
        '"}'
    );
  }
  return {};
}
