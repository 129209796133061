import React from 'react';
import ConfirmationReturnText from './ConfirmationReturnText';
import ConfirmationReturnTo from './ConfirmationReturnTo';
import PostTransaction from '../pages/confirmation/postTransaction';

export default function ConfirmationRedirect({
  autoRedirectMethod,
  autoRedirectUrl,
  handleRedirect,
  orgName,
  postBackData,
  returnText,
  returnTo,
  syncReturnUrl,
}) {
  return (
    <>
      <ConfirmationReturnText returnText={returnText} />
      <ConfirmationReturnTo
        handleRedirect={handleRedirect}
        returnto={returnTo}
      />
      <PostTransaction
        orgName={orgName}
        postdata={postBackData}
        syncReturnUrl={syncReturnUrl}
        method={autoRedirectMethod}
        action={autoRedirectUrl}
      />
    </>
  );
}
