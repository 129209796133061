/*
 * paypalphrodite = paypal + aphrodite
 * This is our little wrapper around aphrodite so we can customize it.
 * In particular it allows us to
 */

import { css, StyleSheet } from 'aphrodite';
import rtlConvert from 'rtl-css-js';
import templateData from './getTemplateData';

let shouldRTL = false;
// instead of aphrodite's StyleSheet, expose our own
const PayPalStyleSheet = { ...StyleSheet, create: customCreate };

function setShouldRTL(newVal) {
  shouldRTL = newVal;
}

function customCreate(cssInJSObject, ...rest) {
  return StyleSheet.create(rtlConvertInline(cssInJSObject), ...rest);
}

function rtlConvertInline(cssInJSObject) {
  if (shouldRTL) {
    return rtlConvert(cssInJSObject);
  }
  return cssInJSObject;
}

function initializeRtl() {
  const { contextLocalityDir = '' } = templateData || {};
  setShouldRTL(!(contextLocalityDir === 'ltr'));
}

initializeRtl();

export { setShouldRTL, rtlConvertInline, css, PayPalStyleSheet as StyleSheet };
