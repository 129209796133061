import React from 'react';
import Illustration from './illustrations/Illustration';
import { Row } from '@paypalcorp/pp-react';

function PendingWarning() {
  return (
    <Row align="center">
      <Illustration type="giving_fund" size="short" />
    </Row>
  );
}

export default PendingWarning;
