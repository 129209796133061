import { EVENT_NAME, PRODUCT } from './constants';
import TemplateData from './getTemplateData';
import AppStore from '../stores/AppStore';
import { formatCurrencyWithSymbol } from './formator';
import { getPersonalizedMessages } from './personalizedMessages';
import { GN_CONTENT_TOUCHPOINT } from './constants';
import { isInContext } from './inContextDonation';
import { MessageFormat } from '@paypalcorp/worldready';
import { getWorldReady } from './worldReadyUtils';
import { getParameterByName } from './queryStringHelper';

export const isGNC = () =>
  TemplateData.product === PRODUCT.GENEROSITY_NETWORK_CHARITIES;
export const isGN = () => TemplateData.product === PRODUCT.GENEROSITY_NETWORK;
export const isBrandedCampaign = () =>
  TemplateData.product === PRODUCT.BRANDED_CAMPAIGN;
export const isPPFundraising = () =>
  TemplateData.product === PRODUCT.PP_FUNDRAISING;
export const isCampaigns = () =>
  TemplateData.product === PRODUCT.DONATE_BUTTON_CAMPAIGN;
export const isPPListedCampaign = () =>
  TemplateData.product === PRODUCT.PAYPAL_FUNDRAISER_LISTED_CAMPAIGN;
export const isPPUnlistedCampaign = () =>
  TemplateData.product === PRODUCT.PAYPAL_FUNDRAISER_UNLISTED_CAMPAIGN;

// TODO: isGN is considered as isPPCampaign. Later we need to remove isGN completely.
export const isPPCampaign = () =>
  isPPListedCampaign() || isPPUnlistedCampaign() || isGN();

export const isCheckoutRail = () => isGNC() || isPPFundraising();
export const isSendMoneyRail = () => isPPCampaign() || isBrandedCampaign();

export const isExternalFlow = () =>
  isGNC() || isPPCampaign() || isBrandedCampaign() || isPPFundraising();

export const prepareOrderParams = () => {
  const worldReady = getWorldReady();

  return {
    amount: AppStore.getDonationAmount(),
    addressSharingConsent: false,
    currencyCode: AppStore.getDonationCode(),
    currencySymbol: AppStore.getDonationSymbol(),
    donationAmountType:
      AppStore.getDonationAmountType() === 'preset' ? 'preset' : 'userinput',
    donationNote: AppStore.getDonationNote() || '',
    recurring: AppStore.getDonationRecurring(),
    selectedProgram: AppStore.getSelectedProgram(),
    selectedPresetButtonId: AppStore.getSelectedPresetButtonId(),
    token: TemplateData.token,
    anonymousStatus: AppStore.getAnonymousStatus(),
    ppgfMessage: new MessageFormat(worldReady, {
      id: 'clients.generosityNetworkCharities.ppgfMessage',
    }).format(),
    directMessage: new MessageFormat(worldReady, {
      id: 'clients.generosityNetworkCharities.directMessage',
    }).format(),
    isInContext: isInContext(),
  };
};

export const getMaximumDonationAlertMessage = (limitReason) => {
  const formatedMaximumAmount = formatCurrencyWithSymbol({
    value: Number(TemplateData.maximumDonationAmount),
    currency: TemplateData.maximumDonationCurrency,
  });

  const worldReady = getWorldReady();
  return new MessageFormat(worldReady, {
    id:
      limitReason === 'cip'
        ? 'landing.cip.amountError'
        : 'clients.generosityNetworkCharities.maximumAmountAlert',
  }).format({ amount: formatedMaximumAmount });
};

export const getPersonalizedNoteToPayeeLabel = () => {
  const psMessages = getPersonalizedMessages(GN_CONTENT_TOUCHPOINT, {
    isHtml: false,
  });
  return psMessages?.noteToPayeeLabel;
};

export const getPersonalizedNoteToPayeeTextboxCommentLabel = () => {
  const psMessages = getPersonalizedMessages(GN_CONTENT_TOUCHPOINT, {
    isHtml: false,
  });
  return psMessages?.noteToPayeeTextboxCommentLabel;
};

export const getPersonalizedAnonymousContent = () => {
  const psMessages = getPersonalizedMessages(GN_CONTENT_TOUCHPOINT, {
    isHtml: false,
  });
  if (isCampaigns()) {
    psMessages.anonymousLabel = 'Donate anonymously';
    psMessages.anonymousTooltip =
      'Your name won’t appear on the fundraiser page and your name and email won’t be shared with the charity. Only the organizer will see your name.';
  }
  return {
    label: psMessages?.anonymousLabel,
    tooltip: psMessages?.anonymousTooltip,
  };
};

export const getDismissEventName = () => {
  const url = window.location.href;

  if (url.includes('confirmation') || url.includes('pending')) {
    return EVENT_NAME.DW_GIVING_PAYMENT_SUCCESS_DISMISS_PRESSED;
  } else if (url.includes('guest')) {
    return EVENT_NAME.DW_GIVING_GUEST_FORM_DISMISS_PRESSED;
  } else if (url.includes('member')) {
    return EVENT_NAME.DW_GIVING_MEMBER_REVIEW_DISMISS_PRESSED;
  }
  return EVENT_NAME.DW_GIVING_DONATE_SETUP_DISMISS_PRESSED;
};

export const isAndroidWebView = () => {
  return getParameterByName('_fpti.platform') === 'android';
};
