import React, { useEffect, useState } from 'react';
import { CaptionText } from '@paypalcorp/pp-react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { EVENT_NAME } from '../utility/constants';
import { trackLinkClick } from '../fpti/fpti';

export default function Disclaimer(props) {
  const [content, setContent] = useState(null);

  useEffect(() => {
    setContent(props.content);
  }, []);

  useEffect(() => {
    const terms = document.querySelectorAll(
      'div[data-testid="disclaimer-content"] > span > a'
    );
    if (terms?.length > 0 && props.content) {
      // terms is a NodeList of type object
      for (let i = 0; i < terms.length; i++) {
        terms[i].addEventListener('click', () => {
          const link = terms[i].href?.split('/').pop();
          trackLinkClick(link, {
            eventName: EVENT_NAME.DW_GIVING_DONATE_SETUP_TERMS_PRESSED,
          });
        });
      }
    }
  }, [content]);

  if (isEmpty(content)) {
    return null;
  }

  return (
    <CaptionText
      data-testid="disclaimer-content"
      className="legal-text"
      size="sm"
    >
      {content}
    </CaptionText>
  );
}

Disclaimer.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.element,
  ]),
};
