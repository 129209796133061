import React from 'react';
import PropTypes from 'prop-types';
import { MessageFormat } from '@paypalcorp/worldready';
import { getWorldReady } from '../utility/worldReadyUtils';
import * as DOMPurify from 'dompurify';

function MessageWithHTML({ id, ...rest }) {
  const worldReady = getWorldReady();
  const messageFormatter = new MessageFormat(worldReady, {
    id,
  });

  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(messageFormatter.format({ ...rest })),
      }}
    ></span>
  );
}

MessageWithHTML.propTypes = {
  id: PropTypes.string,
};

export default MessageWithHTML;
