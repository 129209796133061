import React from 'react';
import { IconButton, CloseIcon } from '@paypalcorp/pp-react';
import { closeWindow, isInContext } from '../utility/inContextDonation';
import TemplateData from '../utility/getTemplateData';
import { getDismissEventName } from '../utility/productIntegrationUtils';
import { Message } from '@paypalcorp/worldready-react';
import PropTypes from 'prop-types';
import { trackLinkClick } from '../fpti/fpti';
import { LINK_NAME } from '../fpti/fptiConstants';

export default function CloseButton(props) {
  const handleClose = () => {
    trackLinkClick(LINK_NAME.CANCEL, {
      eventName: getDismissEventName(),
    });
    if (isInContext()) {
      closeWindow();
      return;
    }
    window.location.href = TemplateData.charityCancelUrl;
  };

  return (
    <IconButton
      data-testid="close-button"
      svgIconComponent={CloseIcon}
      onClick={handleClose}
      className={'close-button'}
      buttonType="tertiary"
      {...props}
    >
      <Message id="common.header.button.close" />
    </IconButton>
  );
}

CloseButton.propTypes = {
  isDarkButton: PropTypes.bool,
};
