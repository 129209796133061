import React, { useEffect } from 'react';
import { Row, Col } from '@paypalcorp/pp-react';
import ConfirmationCheckmark from './ConfirmationCheckmark';
import ConfirmationMessage from './ConfirmationMessage';
import ConfirmationView from './ConfirmationView';
import withConfirmationData from './withConfirmationData';
import withConfirmationMessages from './withConfirmationMessages';
import withTheme from '../components/withTheme';
import PropTypes from 'prop-types';
import * as AppActions from '../actions/AppActions';
import { addClass } from '../utility/domUtils';
import { isInContext } from '../utility/inContextDonation';

function SuccessView(props) {
  const {
    confirmationNumber,
    extraDonateAmount,
    giftAidConfText,
    giftaidFlag,
    rp_status,
    yourDonation,
    yourRPdonation,
    orgSuccess,
  } = props;

  // Disabling 'Pull to Refresh' to thwart POST requests to /donate/guest/confirmation logged in calkibana.
  useEffect(() => {
    document.body.classList?.add('no-pulltorefresh');
  }, []);

  useEffect(() => {
    AppActions.showLanguageSelector(false);
  }, []);

  useEffect(() => {
    if (isInContext) {
      addClass('#mainWrapper', ['in-context-success-page']);
    }
  }, []);

  const SuccessMessage = () => (
    <Row>
      <Col xs={12}>
        <ConfirmationMessage
          yourDonation={rp_status ? yourRPdonation : yourDonation}
          giftaidFlag={giftaidFlag}
          extraDonateAmount={extraDonateAmount}
          giftAid={giftAidConfText}
          orgSuccess={!rp_status ? orgSuccess : null}
          rpStatus={rp_status}
        />
      </Col>
    </Row>
  );

  return (
    <ConfirmationView
      {...props}
      illustration={
        <ConfirmationCheckmark confirmationNumber={confirmationNumber} />
      }
      message={<SuccessMessage />}
    />
  );
}

SuccessView.propTypes = {
  confirmationNumber: PropTypes.string,
  extraDonateAmount: PropTypes.string,
  giftAidConfText: PropTypes.string,
  giftaidFlag: PropTypes.bool,
  rp_status: PropTypes.bool,
  returnTo: PropTypes.string,
  returnText: PropTypes.string,
  yourRPdonation: PropTypes.object,
  yourDonation: PropTypes.object,
  orgSuccess: PropTypes.object,
};

export default withConfirmationData(
  withConfirmationMessages(withTheme(SuccessView), 'success')
);
