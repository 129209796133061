import dompurify from 'dompurify';
import { polyfillFetch } from './polyfillFetch';

/**
 * This will take a node and select the given range of text from start to end in a
 * way that works for iOS
 * @param  {HTMLInputElement} node - the input to select the text in
 * @param  {Number} start - the index to start the selection
 * @param  {Number} end - the index to end the selection
 */
export function selectRangeOfText(node, start, end) {
  // select all the text, but do it on the next tick because iOS has issues otherwise
  setTimeout(() => {
    // we're using setSelectionRange rather than select because select doesn't work with iOS
    node.setSelectionRange(start, end);
  });
}

/**
 * This moves the cursor position to the end of the text in the input node in a way
 * that works on iOS
 * @param {HTMLInputElement} node - the input to set the cursor position in
 */
export function moveCursorToTheEnd(node) {
  const { length } = node.value;
  node.setSelectionRange(length, length);
}

export function sanitizeInPlace(node) {
  if (!node) return null;
  return dompurify.sanitize(node, { IN_PLACE: true });
}

export function sanitizeURL(url = '') {
  const dirtyNode = document.createElement('a');
  dirtyNode.setAttribute('href', url);
  const clean = sanitizeInPlace(dirtyNode);
  return clean?.getAttribute('href');
}

/**
 * Sanitizes HTML
 */
export function sanitizeOrgName(input) {
  try {
    return dompurify
      .sanitize(input)
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
  } catch (e) {
    return '';
  }
}

/**
 * Sanitizes HTML
 */
export function sanitize(input) {
  try {
    return escapeHtml(dompurify.sanitize(input));
  } catch (e) {
    return '';
  }
}

function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}

/**
 * This function shows the hidden element
 * @param {string} selector - element selector
 * @param {string} [display] - display property value
 */
export function show(selector, display = 'block') {
  const elementsToShow = document.querySelectorAll(selector);
  for (let i = 0; i < elementsToShow.length; i++) {
    elementsToShow[i].style.display = display;
  }
}

/**
 * This function hides the selected element
 * @param {string} selector - element selector
 */
export function hide(selector) {
  const elementsToHide = document.querySelectorAll(selector);
  for (let i = 0; i < elementsToHide.length; i++) {
    elementsToHide[i].style.display = 'none';
  }
}

/**
 * This function sets the value of the value attribute for all matched elements
 * @param {string} selector - elements selector
 * @param {string} value - value to set
 */
export function setValue(selector, value) {
  const elementsSelected = document.querySelectorAll(selector);
  for (let i = 0; i < elementsSelected.length; i++) {
    elementsSelected[i].value = value;
  }
}

/**
 * This function creates a serialized representation of an array or an object for use in a URL query string or Ajax request
 * @param {Array|Object} arrORObj - array or Object
 */
export var objectOrArrayToQueryString = function (arrOrObj) {
  var prefix, s, add, name, r20, output;
  s = [];
  r20 = /%20/g;
  add = function (key, value) {
    // If value is a function, invoke it and return its value
    const val = value === null ? '' : value;
    value = typeof value === 'function' ? value() : val;
    s[s.length] = encodeURIComponent(key) + '=' + encodeURIComponent(value);
  };
  if (arrOrObj instanceof Array) {
    for (name in arrOrObj) {
      add(name, arrOrObj[name]);
    }
  } else {
    for (prefix in arrOrObj) {
      buildParams(prefix, arrOrObj[prefix], add);
    }
  }
  output = s.join('&').replace(r20, '+');
  return output;
};

function buildParams(prefix, obj, add) {
  var name, i, l, rbracket;
  rbracket = /\[\]$/;
  if (obj instanceof Array) {
    for (i = 0, l = obj.length; i < l; i++) {
      if (rbracket.test(prefix)) {
        add(prefix, obj[i]);
      } else {
        buildParams(
          prefix + '[' + (typeof obj[i] === 'object' ? i : '') + ']',
          obj[i],
          add
        );
      }
    }
  } else if (typeof obj === 'object') {
    // Serialize object item.
    for (name in obj) {
      buildParams(prefix + '[' + name + ']', obj[name], add);
    }
  } else {
    // Serialize scalar item.
    add(prefix, obj);
  }
}

/**
 * Gets the offset top value relative to the document of the first DOM element that match the selector received as argument
 * @param {String} selector
 */
export function getOffsetTop(selector) {
  try {
    const element = document.querySelector(selector);
    return element.getBoundingClientRect().top + window.scrollY;
  } catch (e) {
    return 0;
  }
}

/**
 * This function will return the children of the selected element
 * @param {string} selector - element selector
 */
export function getChildren(selector) {
  const elementsSelected = document.querySelector(selector);
  return elementsSelected.children;
}

/**
 * This function will add the class provided to the element or elements
 * @param {string} selector - elements selector
 * @param {string[]} classNames - array of class name(s) to add
 */
export function addClass(selector, classNames) {
  const elementsSelected = document.querySelectorAll(selector);
  for (let i = 0; i < elementsSelected.length; i++) {
    elementsSelected[i].classList.add(...classNames);
  }
}

/**
 * This function will remove the class provided from the element or elements
 * @param {string} selector - elements selector
 * @param {string[]} classNames - array of class name(s) to remove
 */
export function removeClass(selector, classNames) {
  const elementsSelected = document.querySelectorAll(selector);
  for (let i = 0; i < elementsSelected.length; i++) {
    elementsSelected[i].classList.remove(...classNames);
  }
}

/**
 * This function will call fetch with additional headers and also uses a polyfill for IE
 * @param {string} url - url to access
 * @param {object} options - optional parameters
 */
export function fetcher(url, options = {}) {
  const csrf = document.querySelector('body').dataset.csrf;
  // This event is fired every time that a fetch is made and helps the user to extend session
  fireEvent('fetchCall');
  options.headers = {
    ...options.headers,
    'X-CSRF-Token': csrf,
    'Content-Type': 'application/json; charset=UTF-8',
    'X-Requested-With': 'XMLHttpRequest',
  };

  if (window.fetch) {
    if (window.location.href.indexOf('donate/buttons') > -1) {
      return fetch(url, options).then((response) => handleErrors(response));
    }
    return fetch(url, options);
  }
  return polyfillFetch(url, options);
}
/**
 * This function will look at the fetch response and determine if there are any network
 * erros and how to respond
 */

function handleErrors(response) {
  if (response.status !== 401) {
    return response;
  }
  handleFetch401Error();
}

function handleFetch401Error() {
  const currentTargetPath = window.location.pathname;
  const returnPath = currentTargetPath.replace(/(\/*donate)/, ''); // some browsers don't put a leading '/'
  const renderURL = `/donate/buttons/login?returnUri=${returnPath}`;
  // Need to do in this way because window.location.href doesn't update referer in http req header
  window.history.pushState(null, null, renderURL);
  location.reload();
}

/**
 * Utility function for firing an event
 * @param {*} eventName
 */
function fireEvent(eventName) {
  const event = document.createEvent('Event');
  event.initEvent(eventName, true, true);
  document.dispatchEvent(event);
}

/**
 * Executes the callback received just when all the DOM elements are safe to be manipulated. Similar to jQuery $.ready() function.
 * @param {function} callback
 */
export function documentReady(callback) {
  // in case the document is already rendered
  if (document.readyState !== 'loading') {
    return callback();
  }
  // modern browsers
  else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  }
  // IE <= 8
  else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') {
        return callback();
      }
    });
  }
}

function isElement(obj) {
  try {
    return obj instanceof HTMLElement;
  } catch (e) {
    return (
      typeof obj === 'object' &&
      obj.nodeType === 1 &&
      typeof obj.style === 'object' &&
      typeof obj.ownerDocument === 'object'
    );
  }
}

function getElement(selectorOrElement) {
  if (isElement(selectorOrElement)) {
    return selectorOrElement;
  } else if (typeof selectorOrElement === 'string') {
    return document.querySelector(selectorOrElement);
  }
  return null;
}

/**
 * Scrolls element into view
 * @param  {String | HTMLElement} selectorOrElement
 */
export function scrollElementIntoView(selectorOrElement) {
  const element = getElement(selectorOrElement);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
    });
  }
}

const _noOpFunction = () => {};

const body = document.querySelector('body');
const safeScrollElement = body.scrollTo
  ? (element, options) => {
      element.scrollTo(options);
    }
  : _noOpFunction;

const safeScrollWindow = window.scrollTo
  ? (options) => {
      window.scrollTo(options);
    }
  : _noOpFunction;

/**
 * Scrolls element or window to specified y location
 * @param  {String | HTMLElement} selectorOrElement
 * @param  { Number } scrollY
 */
export function scrollElementTo(selectorOrElement, scrollY = 0) {
  const element = getElement(selectorOrElement);
  if (element && element.scrollTop !== scrollY) {
    safeScrollElement(element, {
      top: scrollY,
      behavior: 'smooth',
    });
  } else if (window.pageYOffset !== scrollY) {
    safeScrollWindow({
      top: scrollY,
      behavior: 'smooth',
    });
  }
}

/**
 * Get {x, y} coordinates for HTML element
 * @param  {String | HTMLElement} selectorOrElement
 */
export function getPosition(selectorOrElement) {
  let element = getElement(selectorOrElement);

  let xPosition = 0;
  let yPosition = 0;

  while (element) {
    xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
    yPosition += element.offsetTop - element.scrollTop + element.clientTop;
    element = element.offsetParent;
  }

  return { x: xPosition, y: yPosition };
}

/**
 * Scrolls to specified y location
 * @param  {String | HTMLElement} selectorOrElement
 * @param  { Number } scrollY
 */
export function scrollTo(selectorOrElement, scrollY) {
  const element = getElement(selectorOrElement);
  if (element && typeof scrollY === 'number' && !Number.isNaN(scroll)) {
    safeScrollElement(element, {
      top: scrollY,
      behavior: 'smooth',
    });
  }
}
