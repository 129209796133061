import { fetcher } from './domUtils';
import templateData from './getTemplateData';
import _get from 'lodash/get';
import * as DonateAppActions from '../actions/AppActions';

const MERCHANT_PPCC_CONFIRMED_STATUS = 'CONFIRMED';

export const loadPPCCStatus = () => {
  return fetcher(`/donate/api/ppcc/?token=${templateData.token}`)
    .then((response) => response.json())
    .then((result) => {
      const isPPCC =
        _get(result, 'data.PPCC', '') === MERCHANT_PPCC_CONFIRMED_STATUS;
      DonateAppActions.updatePPCCStatus(isPPCC);
    })
    .catch(() => {});
};

export const loadPPCCSetupStatus = () => {
  return fetcher('/donate/api/setupFlow/ppcc')
    .then((response) => response.json())
    .then((result) => {
      return _get(result, 'data.clientData.ppccSetupStatus', '');
    })
    .catch(() => {});
};
