import templateData from './getTemplateData';
import { isExternalFlow } from './productIntegrationUtils';
import _get from 'lodash/get';

export function isInContext() {
  return isPopupMode();
}

export function onComplete(postBackData) {
  const data = _get(postBackData, 'postBackData') || {};

  const transactionDetails = {
    tx: data.txn_id,
    st: data.payment_status,
    amt: data.mc_gross,
    cc: data.mc_currency,
    cm: data.custom_data,
    item_number: data.item_number,
    item_name: data.item_name,
  };
  // Add token if it is an external flow
  // so that they can call the GET TOKEN API
  if (isExternalFlow()) {
    transactionDetails.token = templateData.token;
  }
  if (window && window.xprops && window.xprops.onComplete) {
    window.xprops.onComplete({
      ...transactionDetails,
    });
  }
}

export function onCancel(redirectUrl) {
  if (window && window.xprops && window.xprops.onCancel) {
    window.xprops.onCancel({
      redirectUrl,
    });
  }
}

export function closeWindow() {
  if (window && window.xprops && window.xprops.close) {
    window.xprops.close();
  }
}

/**
 * Returns true if Donate is being rendered inside an iframe
 */
export function isIframeMode() {
  return window.parent !== window;
}

export function isSdkMode() {
  return Boolean(templateData.sdkMeta || templateData.targetMeta);
}

/**
 * Returns true if window was opened by window.opener such as for pop-up windows.
 *
 */
export function isPopupMode() {
  // eslint-disable-next-line no-extra-boolean-cast
  if (Boolean(window.xprops)) {
    return isSdkMode() && Boolean(window.opener);
  }

  if (templateData.isdesktop) {
    return (
      isSdkMode() && Boolean(window.opener) && window && window.outerWidth < 650
    );
  }

  // default
  return isSdkMode() && Boolean(window.opener);
}
/**
 * We are in SDK Mode but we do not have window.xprops so this is FullPageRedirection Mode
 */
export function isFullPageRedirectionMode() {
  return isSdkMode() && Boolean(!window.xprops);
}

export function isMobileOrInContext() {
  return isPopupMode() || templateData.ismobile || templateData.istablet;
}

export function isMobileOrTablet() {
  return templateData.ismobile || templateData.istablet;
}

export function isMWebNoCancelURL() {
  return !isInContext() && isMobileOrTablet() && !templateData.charityCancelUrl;
}

export function isMWebNoReturnURL() {
  return !isInContext() && isMobileOrTablet() && !templateData.charityReturnUrl;
}

export function isNotFullScreen() {
  var isNotAtMaxWidth = window.screen.availWidth - window.outerWidth > 0;
  var isNotAtMaxHeight = window.screen.availHeight - window.outerHeight > 0;
  return isNotAtMaxHeight && isNotAtMaxWidth;
}
