import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationAmount from './ConfirmationAmount';
import ConfirmationAmountGiftAid from './ConfirmationAmountGiftAid';
import { BodyText } from '@paypalcorp/pp-react';
import { isCampaigns, isGNC } from '../utility/productIntegrationUtils';
import isPslCountry from '../utility/israelPsl';
import { Message } from '@paypalcorp/worldready-react';

const ConfirmationMessage = ({
  yourDonation,
  giftaidFlag,
  extraDonateAmount,
  giftAid,
  orgSuccess,
  rpStatus,
}) => {
  return (
    <div className="confirmation_header" role="text">
      <ConfirmationAmount message={yourDonation} />
      {giftaidFlag && (
        <ConfirmationAmountGiftAid
          extraDonateAmount={extraDonateAmount}
          giftAid={giftAid}
        />
      )}
      {isPslCountry() && !isGNC() && !rpStatus && (
        <BodyText className="text-center" style={{ margin: '30px 80px' }}>
          <Message id="confirmation.confirmation.post.donation.ispsl.bodyContent" />
        </BodyText>
      )}
      {isCampaigns() && orgSuccess && (
        <BodyText className="text-center" style={{ margin: '24px auto 72px' }}>
          {orgSuccess}
        </BodyText>
      )}
    </div>
  );
};

ConfirmationMessage.propTypes = {
  yourDonation: PropTypes.object,
  giftaidFlag: PropTypes.bool,
  extraDonateAmount: PropTypes.string,
  giftAid: PropTypes.string,
  orgSuccess: PropTypes.object,
  rpStatus: PropTypes.bool,
};

export default ConfirmationMessage;
