import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from '../../utility/paypalAphrodite';
import classNames from 'classnames';
import { appendCdnHostName } from '../../utility/geoUtils';

const illustrations = {
  critical: appendCdnHostName(
    '/paypal-ui/illustrations/focused/svg/empty-critical.svg'
  ),
  'more-info': appendCdnHostName('/paypal-ui/illustrations/svg/more-info.svg'),
  'credit-cards': appendCdnHostName(
    '/paypal-ui/illustrations/svg/credit-cards.svg'
  ),
  success: appendCdnHostName(
    '/paypal-ui/illustrations/focused/svg/empty-success.svg'
  ),
  warning: appendCdnHostName(
    '/paypal-ui/illustrations/focused/svg/empty-warning.svg'
  ),
  cause_success: appendCdnHostName(
    '/paypal-ui/illustrations/focused/svg/cause-success-desktop.svg'
  ),
  pp_fundraising_success: appendCdnHostName(
    '/paypal-ui/illustrations/focused/svg/cause-success-mobile.svg'
  ),
  send_bubble_hearts: appendCdnHostName(
    '/paypal-ui/illustrations/focused/svg/send-bubble-hearts.svg'
  ),
  document: appendCdnHostName(
    '/paypal-ui/illustrations/focused/svg/document.svg'
  ),
  document_colored: appendCdnHostName(
    '/paypal-ui/pictograms/multicolored/svg/documents.svg'
  ),
  donate: appendCdnHostName(
    '/paypal-ui/pictograms/multicolored/svg/donate.svg'
  ),
  share: appendCdnHostName('/paypal-ui/pictograms/multicolored/svg/share.svg'),
  heart: appendCdnHostName('/paypal-ui/pictograms/multicolored/svg/heart.svg'),
  giving_fund: appendCdnHostName(
    '/paypal-ui/pictograms/multicolored/svg/giving-fund.svg'
  ),
  confirmation: appendCdnHostName(
    '/paypal-ui/pictograms/multicolored/svg/collect-donations.svg'
  ),
};

const STYLES = StyleSheet.create({
  extraLargeDWEB: {
    width: 392,
  },
  extraLargeInContext: {
    width: 343,
  },
  extraLarge: {
    width: 336,
    height: 168,
  },
  large: {
    width: 144,
  },
  medium: {
    width: 108,
  },
  short: {
    width: 96,
  },
  small: {
    width: 72,
  },
  max: {
    width: '100%',
  },
  confirmationMax: {
    width: 136,
  },
  confirmationDesktop: {
    width: 160,
  },
});

export default function Illustration({
  type,
  size,
  className,
  ariaHidden,
  ...rest
}) {
  if (!size) {
    size = 'large';
  }

  const classes = classNames(className, {
    [css(STYLES.extraLargeDWEB)]: size === 'extraLargeDWEB',
    [css(STYLES.extraLargeInContext)]: size === 'extraLargeInContext',
    [css(STYLES.extraLarge)]: size === 'extraLarge',
    [css(STYLES.large)]: size === 'large',
    [css(STYLES.medium)]: size === 'medium',
    [css(STYLES.short)]: size === 'short',
    [css(STYLES.small)]: size === 'small',
    [css(STYLES.max)]: size === 'max',
    [css(STYLES.confirmationDesktop)]: type === 'confirmation',
    [css(STYLES.confirmationMax)]: size === 'max' && type === 'confirmation',
  });

  return (
    <img
      src={illustrations[type]}
      className={classes}
      aria-hidden={ariaHidden || false}
      {...rest}
    />
  );
}

Illustration.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  ariaHidden: PropTypes.bool,
};
