import React from 'react';
import AppStore from '../stores/AppStore';
import TemplateData from '../utility/getTemplateData';
import { trackConfirmationPageIM, trackPSLContentShow } from '../fpti/fpti';
import isPslCountry from '../utility/israelPsl';
import { isGNC } from '../utility/productIntegrationUtils';

const PAGE_TRACK_TAG = 'main:donate:wps:';

export default function withPageTrack(WrappedComponent, flow, result) {
  return class extends React.Component {
    componentDidMount() {
      const confData = AppStore.getConfirmation() || {};
      let isGuestUpgrade = false;
      let updatedFlow = flow;
      let updatedResult = result;

      if (flow !== 'member') {
        isGuestUpgrade =
          confData.showAccountOverview ||
          confData.signUpSuccess ||
          TemplateData.fptPageName === 'guestULSignUp';

        if (TemplateData.onboardingEnabled) {
          updatedFlow = 'signup';
        }
      }

      if (isGuestUpgrade) {
        updatedResult = 'upgrade';
      }

      const tag = `${PAGE_TRACK_TAG}${updatedFlow}:${updatedResult}`;
      const eventData = {
        isSignUp: isGuestUpgrade,
        pageSpec: {
          pgrp: tag,
          page: `${tag}:web::`,
          tmpl: `${tag}:web::`,
        },
        flow,
        confirmationData: confData,
      };
      trackConfirmationPageIM(eventData);

      const rpStatus = AppStore.getDonationRecurring() === 'checked';
      if (isPslCountry() && !isGNC() && !rpStatus) {
        trackPSLContentShow({
          ...eventData,
          status: confData.isOfacPending ? 'ofac' : result,
        });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
