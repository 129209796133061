import React from 'react';
import { BodyText } from '@paypalcorp/pp-react';
import PropTypes from 'prop-types';

export default function ConfirmationTitle(props) {
  return <BodyText style={{ margin: '0 66px 30px' }}>{props.message}</BodyText>;
}

ConfirmationTitle.propTypes = {
  message: PropTypes.string,
};
