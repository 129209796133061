import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@paypalcorp/pp-react';
import { MessageFormat } from '@paypalcorp/worldready';
import { Context as WorldReadyContext } from '@paypalcorp/worldready-react';

const className = 'sign_up_alert';

const SignUpAlert = (props) => {
  const worldReady = useContext(WorldReadyContext);
  const [hideAlert, setHideAlert] = useState(false);

  const buttonCloseMsgFmt = new MessageFormat(worldReady, {
    id: 'common.header.button.close',
  });

  const handleCloseButton = () => {
    setHideAlert(true);
  };
  if (props.signUpStatus && !hideAlert) {
    return (
      <Alert
        id="signUpAlert"
        type="success"
        className={className}
        closeButtonLabel={buttonCloseMsgFmt.format()}
        closeButton={{
          onClick: handleCloseButton,
        }}
      >
        {props.successMessage}
      </Alert>
    );
  }

  return (
    <>
      {!hideAlert && (
        <Alert
          id="signUpAlert"
          type="info"
          className={className}
          closeButtonLabel={buttonCloseMsgFmt.format()}
          closeButton={{
            onClick: handleCloseButton,
          }}
        >
          {props.failMessage}
        </Alert>
      )}
    </>
  );
};

SignUpAlert.propTypes = {
  signUpStatus: PropTypes.bool,
  successMessage: PropTypes.string,
  failMessage: PropTypes.string,
};

export default SignUpAlert;
