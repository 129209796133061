import React from 'react';
import { HeadingText } from '@paypalcorp/pp-react';
import PropTypes from 'prop-types';

export default function ConfirmationTitle(props) {
  return (
    <HeadingText size="sm" style={{ margin: '24px auto' }}>
      {props.message}
    </HeadingText>
  );
}

ConfirmationTitle.propTypes = {
  message: PropTypes.string,
};
