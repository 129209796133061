import React from 'react';
import PropTypes from 'prop-types';
import { Link, DownloadIcon } from '@paypalcorp/pp-react';
import TemplateData from '../utility/getTemplateData';
import { StyleSheet, css } from 'aphrodite';
import _get from 'lodash/get';
import { trackLinkClick } from '../fpti/fpti';
import { LINK_NAME } from '../fpti/fptiConstants';
import { Message } from '@paypalcorp/worldready-react';

const styles = StyleSheet.create({
  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 'auto',
    maxWidth: 415,
    '@media (max-width: 576px)': {
      justifyContent: 'center',
    },
  },
});

class OfferSaveButton extends React.Component {
  printPage = () => {
    window.print();
    trackLinkClick(LINK_NAME.VOUCHER_PRINT);
  };

  sharePage = ({ title, text }) => {
    navigator.share({
      title,
      text,
    });
    trackLinkClick(LINK_NAME.VOUCHER_SHARE);
  };

  handleClick = (event) => {
    event.preventDefault();
    if (TemplateData.isdesktop || !navigator.share) {
      this.printPage();
    } else {
      const { offerProgram, txnStatement } = this.props;
      this.sharePage({
        title: _get(offerProgram, 'subTitle'),
        text: `${txnStatement} ${_get(offerProgram, 'description')}`,
      });
    }
  };

  render() {
    return (
      <div>
        <div className={css(styles.saveButton)}>
          <Link
            href="#"
            onClick={this.handleClick}
            style={{ fontSize: '14px' }}
          >
            <DownloadIcon size="sm" />{' '}
            <Message id="common.offerdetail.offer.button.text" />
          </Link>
        </div>
      </div>
    );
  }
}

OfferSaveButton.propTypes = {
  offerProgram: PropTypes.object.isRequired,
  txnStatement: PropTypes.string,
};

export default OfferSaveButton;
