import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, EmailIcon } from '@paypalcorp/pp-react';

function SocialShareEmail({ url, handleAnalytics, linkTitle }) {
  const mailToHandle = () => {
    handleAnalytics('email');
    window.location.href = url;
  };

  return (
    <IconButton
      onClick={mailToHandle}
      data-testid="social-share-email"
      size="sm"
      buttonType="secondary"
      svgIconComponent={EmailIcon}
    >
      {linkTitle}
    </IconButton>
  );
}

SocialShareEmail.propTypes = {
  url: PropTypes.string,
  handleAnalytics: PropTypes.func,
  linkTitle: PropTypes.string,
};

export default SocialShareEmail;
