import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HeadingText } from '@paypalcorp/pp-react';
import ReturnToMerchantLink from '../../components/returnToMerchant';
import { isExternalFlow } from '../../utility/productIntegrationUtils';
import CloseButton from '../../components/CloseButton';
import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react';
import { MessageFormat } from '@paypalcorp/worldready';
import templateData from '../../utility/getTemplateData';
import {
  PPCC_ELIGIBILITY_CODE_CONSTANTS,
  CIP_ELIGIBILITY_CODE_CONSTANT,
} from '../../../../constants/constants';
import { PAGE_SPEC } from '../../fpti/fptiConstants';
import { trackErrorPageIM } from '../../fpti/fpti';

const ReceiverEligibilityErrorTile = ({ organizationName, returnUrl }) => {
  const worldReady = useContext(WorldReadyContext);
  const messageKey =
    PPCC_ELIGIBILITY_CODE_CONSTANTS.includes(
      templateData.receiverEligibilityCode
    ) || CIP_ELIGIBILITY_CODE_CONSTANT === templateData.receiverEligibilityCode
      ? 'errors.paymentError.ppccNotConfirmed.title'
      : 'errors.landingerror.error.receiveDonationNotAllowed';

  useEffect(() => {
    if (
      CIP_ELIGIBILITY_CODE_CONSTANT === templateData.receiverEligibilityCode
    ) {
      trackErrorPageIM({
        errorCode: 'RECEIVER CIP FAILURE',
      });
    }
  }, []);
  return (
    <>
      {isExternalFlow() && <CloseButton />}
      <div
        className="receiver-eligibility-error-tile"
        data-testid="receiver-eligibility-error-tile"
      >
        <HeadingText size="lg">
          <Message id={messageKey} />
        </HeadingText>
        {returnUrl && (
          <ReturnToMerchantLink
            returnLabel={new MessageFormat(worldReady, {
              id: 'errors.landingerror.label.returnTo',
            }).format({ orgName: organizationName })}
            organizationName={organizationName}
            returnUrl={returnUrl}
          />
        )}
      </div>
    </>
  );
};

ReceiverEligibilityErrorTile.propTypes = {
  organizationName: PropTypes.string,
  returnUrl: PropTypes.string,
};

export default ReceiverEligibilityErrorTile;
