import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BodyText } from '@paypalcorp/pp-react';
import { addFeature } from '../fpti/helper';
import { FEATURES } from '../fpti/fptiConstants';

export class ConfirmationAmountGiftAid extends Component {
  componentDidMount() {
    addFeature(FEATURES.GIFT_AID);
  }

  render() {
    return (
      <BodyText className="text-center" style={{ margin: '24px auto' }}>
        {' '}
        + {this.props.extraDonateAmount} {this.props.giftAid}
      </BodyText>
    );
  }
}

export default ConfirmationAmountGiftAid;

ConfirmationAmountGiftAid.propTypes = {
  extraDonateAmount: PropTypes.string,
  giftAid: PropTypes.string,
};
