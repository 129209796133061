import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, ShareTwitterIcon } from '@paypalcorp/pp-react';

function SocialShareTwitter({ url, handleSocialLinkClick, linkTitle }) {
  return (
    <IconButton
      href={url}
      target="popup"
      onClick={() => handleSocialLinkClick(url, 'twitter')}
      data-testid="social-share-twitter"
      size="sm"
      buttonType="secondary"
      svgIconComponent={ShareTwitterIcon}
    >
      {linkTitle}
    </IconButton>
  );
}

SocialShareTwitter.propTypes = {
  url: PropTypes.string,
  handleSocialLinkClick: PropTypes.func,
  linkTitle: PropTypes.string,
  color: PropTypes.string,
};

export default SocialShareTwitter;
