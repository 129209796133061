import React from 'react';
import { PAYPAL_THEME } from '@paypalcorp/pp-react';
import { PAYPAL_THEME as PPUI4_PAYPAL_THEME } from 'ppui4theme';
import { ThemeProvider } from '@emotion/react';
import { isAndroidWebView } from '../utility/productIntegrationUtils';

// We need to keep this as a function for it to not be memoized and apply
// the styles when coming back from XO
const shouldApplyPPUI4Styles = () =>
  isAndroidWebView() && window.location.pathname.includes('/donate/member');

function withTheme(WrappedComponent, theme = 'v2') {
  return (props) => {
    return (
      <ThemeProvider
        theme={shouldApplyPPUI4Styles() ? PPUI4_PAYPAL_THEME : PAYPAL_THEME}
      >
        <WrappedComponent {...props} />
      </ThemeProvider>
    );
  };
}

export default withTheme;
