// See WR documentation: https://github.paypal.com/pages/Globalization-R/worldready-js/docs/about.html

import {
  WorldReady,
  NumberFormat,
  CurrencyFormat,
  PhoneNumberFormat,
  DateInputMaskFormat,
  RelativeTimeFormat,
  DateTimeFormat,
} from '@paypalcorp/worldready';

let worldReady;
const getLocale = () => {
  const { country = 'US', language = 'en' } =
    JSON.parse(document.getElementById('templateData').innerHTML) || {};
  return `${language}-${country}`;
};

export const initializeWorldReady = () => {
  if (!worldReady) {
    const worldReadyData = JSON.parse(
      document.getElementById('worldReady').textContent
    );
    WorldReady.load(worldReadyData);
    // WorldReady has loaded!

    const locale = getLocale();
    worldReady = new WorldReady(locale);
  }
};

/**
 * It returns a WorldReady instance
 * @returns a WorldReady instance.
 */
export const getWorldReady = () => {
  return worldReady;
};

/**
 * It takes a number and returns a localized number string
 * @param value - The number to format.
 * @param [options] - An object with the following properties: { maximumFractionDigits }
 * Example input: 1000
 * Example output: '1,000.00'
 */
export const formatNumber = (value, options = {}) =>
  new NumberFormat(worldReady, options).format(value);

/**
 * It takes a string, and returns a number
 * @param value - The value to be formatted.
 * @param [options] - An object with the following properties:
 * Example input: '1,000.00'
 * Example output: 1000
 */
export const wrNormalizeNumber = (value, options = {}) =>
  new NumberFormat(worldReady, options).parse(value);

/**
 * It takes a value and an options object, and returns a formatted currency string
 * @param value - The value to format.
 * @param options - An object containing the following properties: { currency, maximumFractionDigits, style }
 * Example input: 1000, currency: 'USD'
 * Example output: '$1,000.00'
 */
export const formatCurrency = (value, options) =>
  options && new CurrencyFormat(worldReady, options).format(value);

/**
 * It takes a number and an options object, and returns an array of objects
 * @param value - The value to be formatted.
 * @param options - An object with the following properties: { style }
 * Input: 1234.5
 * Output: [
 *  { type: "currencySymbol", value: "$" },
 *   { type: "integer", value: "1" },
 *   { type: "group", value: "," },
 *   { type: "integer", value: "234" },
 *   { type: "decimal", value: "." },
 *   { type: "fraction", value: "50" },
 *   { type: "literal", value: " " },
 *   { type: "currencyCode", value: "USD" }
 * ] (considering locale is en-US)
 */
export const formatCurrencyToParts = (value, options) =>
  options && new CurrencyFormat(worldReady, options).formatToParts(value);

/**
 * "If the options object has a phoneRegion property, and the phoneObject is truthy, then format the
 * phoneObject using the PhoneNumberFormat class."
 *
 * The PhoneNumberFormat class is a class that I wrote to format phone numbers. It's not part of the
 * Google libphonenumber library
 * @param [options] - An object with the following properties:
 * @param phoneObject - The phone number object returned by the parsePhoneNumberFromString function.
 * Input: { countryCode: "1", nationalNumber: "4088430088" }
 * Output: "+1 408-843-0088" (considering phoneRegion is US)
 */
export const formatPhoneNumber = (options = {}, phoneObject) =>
  options.phoneRegion &&
  phoneObject &&
  new PhoneNumberFormat(options).format(phoneObject);

/**
 * It takes a phone number and a phone region and returns a parsed phone number
 * @param options - The options object that you pass to the PhoneNumberFormat constructor.
 * @param phoneNumber - The phone number to parse.
 * Input: "+1 408-843-0088" (considering phoneRegion is US)
 * Output: { countryCode: "1", nationalNumber: "4088430088" }
 */
export const parsePhoneNumber = (options, phoneNumber) =>
  options.phoneRegion &&
  phoneNumber &&
  new PhoneNumberFormat(options).parse(phoneNumber);

/**
 * It returns a new instance of the DateInputMaskFormat class, which is a class that extends the
 * InputMaskFormat class
 * @param options - An object with the following properties:
 * Input: { style: 'date-short' }
 * Output: "mm/dd/yy" (considering locale is en-US)
 */
export const getDateMaskFormat = (options) =>
  new DateInputMaskFormat(worldReady, options).format();

/**
 * It returns a string that represents the relative time between the given date and the current time
 * @param date - The date to format.
 * @param options - An object with the following properties:
 */
export const getRelativeTime = (date, options) =>
  new RelativeTimeFormat(worldReady, options).format(date);

/**
 * It takes a date and an optional options object, and returns a string
 * @param date - The date to format.
 * @param [options] - An object with the following properties:
 */
export const formatDate = (date, options = {}) =>
  new DateTimeFormat(worldReady, options).format(date);

initializeWorldReady();
