import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, CheckmarkBackgroundSmIcon } from '@paypalcorp/pp-react';
import { StyleSheet, css } from '../utility/paypalAphrodite';
import { copyToClipboard } from '../setup/lib/copyToClipBoard';
import { isInContext } from '../utility/inContextDonation';
import { PAYPAL_THEME } from '@paypalcorp/pp-react-theme';

const { sysColorBackgroundSurfaceHover, sysColorPrimaryMain } = PAYPAL_THEME;

const styles = StyleSheet.create({
  copyLinkFieldContainer: {
    borderBottom: '1px dashed #CBD2D6',
    margin: '20px 0px 30px',
    fontSize: 14,
    '@media @tabletAndMobile': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    ':last-child': {
      borderBottom: 'none',
    },
  },
  inContext: {
    padding: 0,
    maxWidth: 420,
    margin: '0 auto',
  },
  inContextCopyLinkHeight: {
    height: 60,
    maxHeight: 60,
  },
  copyLinkField: {
    backgroundColor: sysColorBackgroundSurfaceHover,
    alignItems: 'center',
    border: '0px',
    borderStyle: 'dotted',
    paddingRight: '12px',
    display: 'flex',
  },
  copyLinkTextarea: {
    backgroundColor: sysColorBackgroundSurfaceHover,
    height: 'auto',
    maxHeight: 60,
    margin: '16px',
    marginRight: '30px',
    fontSize: '1.1rem',
    border: 'none',
    resize: 'none',
    overflow: 'hidden',
    width: '100%',
    outline: 'none',
    wordBreak: 'break-all',
    ':hover': {
      overflow: 'auto',
    },
    flex: 4,
  },
  copyLinkFieldIconContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    zIndex: 5,
    alignSelf: 'stretch',
    alignItems: 'center',
    flex: 1,
  },
  copyLinkFieldIcons: {
    lineHeight: '1rem',
    cursor: 'pointer',
    minWidth: '4rem',
    wordBreak: 'normal',
  },
  notCopied: {
    background: 'none',
    color: sysColorPrimaryMain,
  },
  copied: {
    borderColor: '#018065',
    backgroundColor: '#018065',
    color: 'white',
  },
  copyLinkDescription: {
    padding: '8px 16px 20px',
  },
});

function CopyLink({ url, analytics, messages }) {
  const [isCopied, setIsCopied] = useState(false);
  const [padding, setPadding] = useState('16px');
  const copyRef = useRef(null);
  useEffect(() => {
    if (copyRef.current) {
      // hacky padding to center text in a textarea
      const current = copyRef.current;
      const innerHTML = current.innerHTML;
      if (!innerHTML || innerHTML.length > 48 || current.clientWidth < 195) {
        setPadding('0');
        return;
      }
      setPadding('3% 0px');
    }
  }, []);

  function handleCopyToClipBoard() {
    copyToClipboard('copy-link-text');
    if (analytics) {
      analytics('copy_link');
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }

  return (
    typeof document !== 'undefined' &&
    document.queryCommandSupported('copy') && (
      <div
        className={`${css(styles.copyLinkFieldContainer)} ${
          isInContext() ? css(styles.inContext) : ''
        }`}
        id="copy-link-field-container"
      >
        <div
          className={`${css(styles.copyLinkField)}
            ${isInContext() ? css(styles.inContextCopyLinkHeight) : ''}`}
          onClick={handleCopyToClipBoard}
          data-testid="copy-link-field-click"
        >
          <textarea
            id="copy-link-text"
            className={`${css(styles.copyLinkTextarea)}`}
            style={{ padding: padding }}
            ref={copyRef}
            value={url}
            readOnly
          >
            {url}
          </textarea>
          <div className={`${css(styles.copyLinkFieldIconContainer)}`}>
            {isCopied ? (
              <Button
                size="sm"
                className={`${css(styles.copied)} ${css(
                  styles.copyLinkFieldIcons
                )}`}
                data-testid="copy-link-copied"
              >
                <CheckmarkBackgroundSmIcon size="xs" />
              </Button>
            ) : (
              <Button
                size="sm"
                className={`${css(styles.notCopied)} ${css(
                  styles.copyLinkFieldIcons
                )}`}
                data-testid="copy-link-not-copied"
              >
                {messages.copy}
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  );
}

CopyLink.propTypes = {
  url: PropTypes.string,
  analytics: PropTypes.func,
  messages: PropTypes.object,
};

CopyLink.defaultProps = {
  analytics: null,
};

export default CopyLink;
