import React from 'react';
import BannerHeader, { Avatar, Banner } from './bannerHeader';
import {
  isBrandedCampaign,
  isGNC,
  isPPCampaign,
  isPPFundraising,
} from '../../utility/productIntegrationUtils';
import GNCSubheader from './GNCSubheader';
import GNSubheader from './GNSubheader';
import templateData from '../../utility/getTemplateData';

const GNHeader = () => {
  const organizationName = templateData.donationName,
    purpose = templateData.charityName,
    bannerUrl = templateData.bannerUrl,
    logoUrl = templateData.logoUrl,
    initialColor = templateData.initialColor,
    initialLogo = templateData.initialLogo;

  return (
    <div>
      <BannerHeader type="logoBanner">
        <Banner bannerURL={bannerUrl} />
        <Avatar
          logoURL={logoUrl}
          initialLogo={initialLogo}
          initialColor={initialColor}
        />
      </BannerHeader>
      {isGNC() && (
        <GNCSubheader organizationName={organizationName} purpose={purpose} />
      )}
      {(isPPCampaign() || isPPFundraising() || isBrandedCampaign()) && (
        <GNSubheader
          organizationName={organizationName}
          purpose={purpose}
          isCharityDonation={isPPFundraising()}
        />
      )}
    </div>
  );
};

export default GNHeader;
