import templateData from './getTemplateData';

const countries = ['IL'];

function isPslCountry() {
  const userCountry = templateData?.legalCountry;
  return countries.indexOf(userCountry) >= 0;
}

export default isPslCountry;
