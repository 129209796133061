import * as AppActions from '../actions/AppActions';
import '../stores/AppStore';
import { formatAmount } from './formator';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import { init } from 'pp-react-l10n';
import { getPersonalizedMessagesAsContentLoaderStandard } from './personalizedMessages';
import { loadPPCCStatus } from './ppccUtils';
import { isAtLandingPage } from '../components/coverFee/feeUtils';
import { MessageFormat } from '@paypalcorp/worldready';
import { getWorldReady } from './worldReadyUtils';

const rawTemplateData =
  document.getElementById('templateData') &&
  document.getElementById('templateData').innerHTML;
const templateData = JSON.parse(rawTemplateData ? rawTemplateData : '{}');

if (!_isEmpty(templateData)) {
  if (!_isEmpty(templateData.giftAid)) {
    _set(templateData, 'giftAid', JSON.parse(templateData.giftAid));
  }
  if (!_isEmpty(templateData.webscrData)) {
    _set(templateData, 'webscrData', JSON.parse(templateData.webscrData));
  }
  if (!_isEmpty(templateData.giftAidAddress)) {
    _set(
      templateData,
      'giftAidAddress',
      JSON.parse(templateData.giftAidAddress)
    );
  }
}

const localsMessagesNode =
  document.getElementById('localeMessages') &&
  document.getElementById('localeMessages').innerHTML;
const locals = (localsMessagesNode && JSON.parse(localsMessagesNode)) || {};
const personalized = getPersonalizedMessagesAsContentLoaderStandard();
const content = {
  locals,
  personalized,
};
init({ content });

/**
 * Normalize charity name and donation ID strings from template data.
 */
if (templateData.charityName) {
  const charityName = templateData.charityName;
  try {
    templateData.charityName = decodeURIComponent(charityName);
  } catch (er) {
    templateData.charityName = charityName;
  }
}
if (templateData.donationId) {
  const donationId = templateData.donationId;
  try {
    templateData.donationId = decodeURIComponent(donationId);
  } catch (er) {
    templateData.donationId = donationId;
  }
}

export const shouldSelectPresetAmount = (amount) => {
  if (!amount) {
    return false;
  }
  if (templateData.presetAmounts?.length === 3) {
    const presetAmounts = templateData.presetAmounts || [];
    return presetAmounts.map(Number).includes(Number(amount));
  }
};

/**
 * Attempting update default value for amount in flux store
 */
const donorCoversFee = templateData.feePayer === 'consumer';
const donationAmount =
  donorCoversFee && isAtLandingPage()
    ? templateData.rawAmount.toString()
    : templateData.donationAmount.toString();
AppActions.changeAmount({
  amount: donationAmount,
});

if (donorCoversFee) {
  AppActions.changeCoverFee({ coverFee: 'checked' });
  AppActions.changeFeeAmount({ feeAmount: templateData.feeAmount });
  AppActions.changeFormatedAmount({
    formatedFeeAmount: formatAmount({
      currency: templateData.feeCurr,
      value: templateData.rawAmount,
    }),
  });
}

if (templateData.anonymousEnabled) {
  const anonymousStatus = templateData.anonymousStatus
    ? 'checked'
    : 'unchecked';
  AppActions.changeAnonymousStatus({ anonymousStatus });
}

/**
 * Attempting update default value for currency symbol in flux store
 */
AppActions.changeSymbol({
  symbol: templateData.donationSymbol,
});

/**
 * Attempting update default value for currency code in flux store
 */
AppActions.changeCode({
  code: templateData.donationCode,
});

/**
 * Attempting update default value for note in flux store
 */
AppActions.changeNote({
  note: templateData.donationNote,
});

/**
 * Attempting update default value for recurring in flux store
 */
AppActions.changeRecurring({
  recurring:
    templateData.recurringStatus === 'checked' ||
    templateData.recurringEnabled === 'checked'
      ? 'checked'
      : 'unchecked',
});

if (templateData.selectedRecurringFrequency) {
  AppActions.changeSelectedRecurringFrequency({
    selectedRecurringFrequency: templateData.selectedRecurringFrequency,
  });
}

AppActions.updateSelectedPresetButtonId(templateData.selectedPresetButtonId);

templateData.enablePresetAmounts = shouldEnablePresetAmounts();

if (_isEmpty(templateData.presetAmounts)) {
  templateData.presetAmounts = _get(
    templateData,
    'donateContext.amount_options'
  );
}

if (
  templateData.donationAmountType === 'preset' &&
  templateData.presetAmounts?.length === 3
) {
  if (!_isEmpty(donationAmount) && !shouldSelectPresetAmount(donationAmount)) {
    AppActions.updateDonationAmountType('userinput');
  } else {
    AppActions.updateDonationAmountType('preset');
  }
}

export const isOldButtonFactory = () => {
  return _isEmpty(templateData.donateContext);
};

export const isNewButtonFactory = () => {
  return !isOldButtonFactory();
};

// Prepopulate donationId in the dropdown
if (isNewButtonFactory() && templateData.donationId) {
  AppActions.updateSelectedProgram(templateData.donationId);
}

/**
 * Attempting to parse all locals messages from DOM
 */
function parseMessages(templateName) {
  if (!templateName) {
    console.warn('Missing template name');
  }
  try {
    const localsAllMessages = JSON.parse(
      document.getElementById('localeMessages').innerHTML || {}
    );
    const path = templateName.replaceAll('/', '.');
    return _get(localsAllMessages, path) || localsAllMessages;
  } catch (e) {
    console.warn('Please attach locales to DOM');
    return {};
  }
}

/**
 * Attempting to get all data from template
 */
export default templateData;

/**
 * Attempting to grab all locals messages
 */
export function localsMessages(templateName) {
  return parseMessages(templateName);
}

/**
 * Attempting to grab all locals messages for guest billing page
 */

export function guestLocalsMessages() {
  const worldReady = getWorldReady();
  const guestMessages = localsMessages('guest/guestForm') || {};
  const guestsignup = guestMessages.guestsignup || {};

  let nationalIds = [];

  try {
    nationalIds = _map(guestsignup.nationalIds, (label, value) => {
      return {
        label,
        value,
      };
    });
  } catch (e) {
    /* nothing required as nationalIds are only applicable for limited countries */
  }

  const createAccountPreProps = {
    mainTitle: '', // guestsignup.mainTitle
    mainBlurb: '', // guestsignup.mainBlurb
    itsFreeLine: '', // guestsignup.itsFreeLine
    mainTitle2: 'guestsignup.mainTitle2',
    createAccountCheckboxContent: 'guestsignup.createAccountCheckboxContent',
    createAccountHelp1: 'guestsignup.expcreateAccountHelpContent',
    label1: 'guestsignup.explabel1',
    label2: 'guestsignup.explabel2',
    legalBlurbHTML: 'guestsignup.legalBlurbHTML',
    legalBlurbWithFeeInformationDoc:
      'guestsignup.legalBlurbWithFeeInformationDoc',
    incentiveTitle: 'guestsignup.incentive.title',
    incentiveCreateAccountCheckboxContent:
      'guestsignup.incentive.createAccountCheckboxContent',
    incentiveTerms: 'guestsignup.incentive.terms',
    labeldob: 'guestsignup.labeldob',
    labelnationality: 'guestsignup.labelnationality',
    dobPattern: 'guestsignup.dobPattern',
    dobHelp: 'guestsignup.dobPattern',
    labelocc: 'guestsignup.labelocc',
    legalOnlyHTML: 'guestsignup.legalOnlyHTML',
    consentBlurbHTML: 'guestsignup.consentBlurbHTML',
    labelNationalIdType: 'guestsignup.labelNationalIdType',
    labelNationalIdNumber: 'guestsignup.labelNationalIdNumber',
    nationalIds,
    defaultIdType: 'NATIONAL_ID', // Setting up deafult ID type
    passwordWarnings: {
      _MISMATCH: 'passwordWarnings.MISMATCH',
      _IS_NULL: 'passwordWarnings.IS_NULL',
      _TOO_SHORT: 'passwordWarnings.TOO_SHORT',
      _TOO_LONG: 'passwordWarnings.TOO_LONG',
      _CONTAINS_REPEATED_CHARACTERS:
        'passwordWarnings.CONTAINS_REPEATED_CHARACTERS',
      _CONTAINS_NUMBER_SEQUENCE: 'passwordWarnings.CONTAINS_NUMBER_SEQUENCE',
      _CONTAINS_LETTER_SEQUENCE: 'passwordWarnings.CONTAINS_LETTER_SEQUENCE',
      _CONTAINS_NUMBER_LETTER_SEQUENCE:
        'passwordWarnings.CONTAINS_NUMBER_LETTER_SEQUENCE',
      _IS_BLACKLISTED: 'passwordWarnings.IS_BLACKLISTED',
      _CONTAINS_SPACES: 'passwordWarnings.CONTAINS_SPACES',
      _HAS_ILLEGAL_CHARACTERS: 'passwordWarnings.HAS_ILLEGAL_CHARACTERS',
      _LEGAL_CHECKBOX: 'passwordWarnings.LEGAL_CHECKBOX',
      _REQUIRED_DOB: 'passwordWarnings.REQUIRED_DOB',
      _INVALID_DOB: 'passwordWarnings.INVALID_DOB',
      _OCCUPATION: 'passwordWarnings.OCCUPATION',
      _NATIONALITY: 'passwordWarnings.NATIONALITY',
      _HINT1: 'passwordWarnings.HINT1',
      _HINT2: 'passwordWarnings.HINT2',
      _HINT3: 'passwordWarnings.HINT3',
      _NATIONAL_ID_TYPE: 'passwordWarnings._NATIONAL_ID_TYPE',
      _NATIONAL_ID_NUMBER: 'passwordWarnings._NATIONAL_ID_NUMBER',
    },
  };

  const createAccountPost = {
    mainTitle: '', // guestsignup.mainTitle
    mainBlurb: '', // guestsignup.mainBlurb
    itsFreeLine: '', // guestsignup.itsFreeLine
    createAccountCheckboxContent: 'guestsignup.createAccountCheckboxContent',
    pplogo: 'guestsignup.pplogo',
    mainTitle1: 'guestsignup.mainTitle1',
    mainTitle2: 'guestsignup.expmainTitle2',
    mainTitle3: 'guestsignup.expmainTitle3',
    notNow: 'guestsignup.notNow',
    label1: 'guestsignup.explabel1',
    label2: 'guestsignup.explabel2',
    legalBlurbHTML: 'guestsignup.legalBlurbHTML',
    legalBlurbWithFeeInformationDoc:
      'guestsignup.legalBlurbWithFeeInformationDoc',

    labeldob: 'guestsignup.labeldob',
    labelnationality: 'guestsignup.labelnationality',
    dobPattern: 'guestsignup.dobPattern',
    labelocc: 'guestsignup.labelocc',
    legalOnlyHTML: 'guestsignup.legalOnlyHTML',
    consentBlurbHTML: 'guestsignup.consentBlurbHTML',

    passwordWarnings: {
      _MISMATCH: 'passwordWarnings.MISMATCH',
      _IS_NULL: 'passwordWarnings.IS_NULL',
      _TOO_SHORT: 'passwordWarnings.TOO_SHORT',
      _TOO_LONG: 'passwordWarnings.TOO_LONG',
      _CONTAINS_REPEATED_CHARACTERS:
        'passwordWarnings.CONTAINS_REPEATED_CHARACTERS',
      _CONTAINS_NUMBER_SEQUENCE: 'passwordWarnings.CONTAINS_NUMBER_SEQUENCE',
      _CONTAINS_LETTER_SEQUENCE: 'passwordWarnings.CONTAINS_LETTER_SEQUENCE',
      _CONTAINS_NUMBER_LETTER_SEQUENCE:
        'passwordWarnings.CONTAINS_NUMBER_LETTER_SEQUENCE',
      _IS_BLACKLISTED: 'passwordWarnings.IS_BLACKLISTED',
      _CONTAINS_SPACES: 'passwordWarnings.CONTAINS_SPACES',
      _HAS_ILLEGAL_CHARACTERS: 'passwordWarnings.HAS_ILLEGAL_CHARACTERS',
      _LEGAL_CHECKBOX: 'passwordWarnings.LEGAL_CHECKBOX',

      _REQUIRED_DOB: 'passwordWarnings.REQUIRED_DOB',
      _INVALID_DOB: 'passwordWarnings.INVALID_DOB',
      _OCCUPATION: 'passwordWarnings.OCCUPATION',
      _NATIONALITY: 'passwordWarnings.NATIONALITY',
      _HINT1: 'passwordWarnings.HINT1',
      _HINT2: 'passwordWarnings.HINT2',
      _HINT3: 'passwordWarnings.HINT3',
    },
    buttonText: 'guestsignup.agreeanddonate',
  };

  for (const ca in createAccountPreProps) {
    if (!createAccountPreProps[ca]) {
      continue;
    }
    if (typeof createAccountPreProps[ca] === 'string') {
      const path = `guest.guestForm.${createAccountPreProps[ca]}`;
      createAccountPreProps[ca] = new MessageFormat(worldReady, {
        id: path,
      }).format({
        country: '{country}',
        locale: '{locale}',
        brand: '{brand}',
        language: '{language}',
      });
    } else if (ca === 'passwordWarnings') {
      for (const cp in createAccountPreProps[ca]) {
        const path2 = `guest.guestForm.${createAccountPreProps[ca][cp]}`;
        createAccountPreProps[ca][cp] = new MessageFormat(worldReady, {
          id: path2,
        }).format();
      }
    }
  }

  createAccountPreProps.dobPattern =
    createAccountPreProps.dobPattern.toUpperCase();
  for (const ca in createAccountPost) {
    if (!createAccountPost[ca]) {
      continue;
    }
    if (typeof createAccountPost[ca] === 'string') {
      const path = `guest.guestForm.${createAccountPost[ca]}`;

      createAccountPost[ca] = new MessageFormat(worldReady, {
        id: path,
      }).format({
        country: '{country}',
        locale: '{locale}',
        brand: '{brand}',
        language: '{language}',
      });
    } else if (ca === 'passwordWarnings') {
      for (const cp in createAccountPost[ca]) {
        const path2 = `guest.guestForm.${createAccountPost[ca][cp]}`;
        createAccountPost[ca][cp] = new MessageFormat(worldReady, {
          id: path2,
        }).format();
      }
    }
  }

  const cpf = {
    label: new MessageFormat(worldReady, {
      id: 'guest.guestForm.guestsignup.cpf',
    }).format(),
    warnings: {
      _REQUIRED_CPF: new MessageFormat(worldReady, {
        id: 'guest.guestForm.cpfWarnings.REQUIRED_CPF',
      }).format(),
      _INVALID_CPF: new MessageFormat(worldReady, {
        id: 'guest.guestForm.cpfWarnings.INVALID_CPF',
      }).format(),
    },
  };

  return {
    guestMessages: guestMessages,
    guestLabels: guestMessages.label || {},
    guestHelp: guestMessages.help || {},
    guestCaptcha: guestMessages.captcha || {},
    createAccountPreProps: createAccountPreProps,
    createAccountPost: createAccountPost,
    cpf: cpf,
  };
}

function shouldEnablePresetAmounts() {
  return (
    (templateData.donationAmountType === 'preset' &&
      templateData.presetAmounts?.length === 3) ||
    (templateData.donateContext &&
      templateData.donateContext.amount_type === 'MERCHANT_RECOMMENDED' &&
      templateData.donateContext.amount_options.length === 3) ||
    false
  );
}

if (templateData.ppccLookupRequired) {
  loadPPCCStatus();
}

let currencyLists;
export function getCurrencySymbol(currencyCode) {
  if (!currencyLists) {
    try {
      currencyLists = JSON.parse(
        document.getElementById('currencyLists').innerHTML || {}
      );
    } catch (e) {
      currencyLists = {};
    }
  }
  return (currencyLists.symbols && currencyLists.symbols[currencyCode]) || '';
}

export const isMobileHeaderExp = () => {
  return _get(templateData, 'pxp.moveMobileHeaderExp.experiment', false);
};

export const isPartnerFlow = () => {
  return _get(templateData, 'isPartnerFlow', false);
};

export const hasPartnerSource = () => {
  return _get(templateData, 'hasPartnerSource', false);
};
