import React from 'react';
import { CaptionText, HeadingText } from '@paypalcorp/pp-react';
import PropTypes from 'prop-types';
import { Message } from '@paypalcorp/worldready-react';

const GNSubheader = ({
  organizationName,
  purpose,
  isCharityDonation = false,
}) => {
  return (
    <div className="donate_sub_header donate-main-content">
      <div className="gn-subheader">
        <CaptionText className="personal-fundraiser-header">
          {(isCharityDonation && (
            <Message
              id="common.subHeader.label.donateToGivingFund"
              bold={(innerMessage) => <b>{innerMessage}</b>}
            />
          )) || (
            <Message
              id="common.subHeader.wr.label.donateToOrganization"
              organization={<strong>{organizationName}</strong>}
            />
          )}
        </CaptionText>
        <HeadingText className="gn-purpose" level={2}>
          {purpose}
        </HeadingText>
      </div>
    </div>
  );
};

GNSubheader.propTypes = {
  organizationName: PropTypes.string,
  purpose: PropTypes.string,
  isCharityDonation: PropTypes.bool,
};

export default GNSubheader;
