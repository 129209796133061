import AppDispatcher from '../dispatcher/AppDispatcher';
import assign from 'object-assign';
import { EventEmitter } from 'events';
import * as DonationConstants from '../utility/constants';

const CHANGE_EVENT = 'change';

const _alldata = {}; // collection of donation data

/**
 * @param {string} text The content donation
 */
function create(field, value) {
  _alldata[field] = value;
}

var DonationStore = assign({}, EventEmitter.prototype, {
  emitChange: function () {
    this.emit(CHANGE_EVENT);
  },

  getAllData: function () {
    return _alldata;
  },

  getDonationAmount: function () {
    if (_alldata.amount) {
      return _alldata.amount.toString();
    }
    return _alldata.amount;
  },

  getDonationSymbol: function () {
    return _alldata.symbol;
  },

  getDonationCode: function () {
    return _alldata.code;
  },

  getFormatedAmount: function () {
    return _alldata.formatedAmount;
  },

  getDonationNote: function () {
    return _alldata.note;
  },

  getDonationRecurring: function () {
    return _alldata.recurring;
  },

  getSelectedRecurringFrequency: function () {
    return _alldata.selectedRecurringFrequency;
  },

  getRecurringModalStatus: function () {
    return _alldata.recurringModalStatus;
  },

  getAnonymousStatus: function () {
    return _alldata.anonymousStatus;
  },

  getCoverFeeOptInStatus: function () {
    return _alldata.coverFee;
  },

  getFeeAmount: function () {
    return _alldata.feeAmount;
  },

  getFormatedFeeAmount: function () {
    return _alldata.formatedFeeAmount;
  },

  getAmountBeforeFee: function () {
    return _alldata.amountBeforeFee;
  },

  getConfirmation: function () {
    return _alldata.confirmation;
  },

  getPostBackData: function () {
    return _alldata.postBackData;
  },

  getCurrentPage: function () {
    return _alldata.currentPage;
  },

  getServerErros: function () {
    return _alldata.serverErrors;
  },

  getPaymentError: function () {
    return _alldata.paymentError;
  },

  getSignUpError: function () {
    return _alldata.signUpError;
  },

  getFundingOptions: function () {
    return _alldata.fundingOptions;
  },

  getEmail: function () {
    return _alldata.email;
  },

  getBillingAddress: function () {
    return _alldata.billingAddress;
  },

  getUserCountry: function () {
    return _alldata.country;
  },

  getDonationAmountType: function () {
    return _alldata.donationAmountType;
  },

  getGiftaidItFlagStatus: function () {
    return _alldata.giftAidItFlag;
  },

  getShareAddress: function () {
    return _alldata.shareAddress;
  },

  getAddCardError: function () {
    return _alldata.addCardError;
  },

  getGiftAidItExtraAmount: function () {
    return DonationConstants.DONATE_GIFTAID_IT_EXTRA_AMOUNT;
  },

  getSelectedProgram: function () {
    return _alldata.selectedProgram;
  },

  getSelectedPresetButtonId: function () {
    return _alldata.selectedPresetButtonId;
  },

  getCardSpec: function () {
    return _alldata.cardSpec;
  },

  getFormData: function () {
    return _alldata.formData;
  },

  getThreeDSContingency: function () {
    return _alldata.threeDSContingency;
  },

  getLanguageSelectorState: function () {
    return _alldata.languageSelectorState;
  },

  getOrderStatus: function () {
    return _alldata.orderStatus;
  },

  getPPCCStatus: function () {
    return _alldata.ppccStatus;
  },

  dispatcherIndex: AppDispatcher.register(function (payload) {
    var action = payload.action;
    var amount,
      recurring,
      selectedRecurringFrequency,
      recurringModalStatus,
      note,
      symbol,
      code,
      formatedAmount,
      country,
      confirmation,
      postBackData,
      currentPage,
      serverErrors,
      paymentError,
      signUpError,
      fundingOptions,
      email,
      billingAddress,
      giftAidItFlag,
      coverFee,
      feeAmount,
      formatedFeeAmount,
      amountBeforeFee,
      anonymousStatus,
      orderStatus;

    switch (action.actionType) {
      case DonationConstants.CHANGE_AMOUNT:
        amount = action.item.amount.trim();
        create('amount', amount);
        DonationStore.emitChange();
        break;

      case DonationConstants.CHANGE_SYMBOL:
        symbol = action.item.symbol.trim();
        if (symbol !== '') {
          create('symbol', symbol);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.CHANGE_CODE:
        code = action.item.code.trim();
        if (code !== '') {
          create('code', code);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.CHANGE_FORMATED_AMOUNT:
        formatedAmount = action.item.formatedAmount;
        if (formatedAmount !== '') {
          create('formatedAmount', formatedAmount);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.CHANGE_RECURRING_FREQUENCY:
        selectedRecurringFrequency =
          action.item.selectedRecurringFrequency?.trim();
        if (selectedRecurringFrequency !== '') {
          create('selectedRecurringFrequency', selectedRecurringFrequency);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.CHANGE_RECURRING:
        recurring = action.item.recurring.trim();
        if (recurring !== '') {
          create('recurring', recurring);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.CHANGE_ANONYMOUS_STATUS:
        anonymousStatus = action.item.anonymousStatus.trim();
        if (anonymousStatus !== '') {
          create('anonymousStatus', anonymousStatus);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.CHANGE_COVER_FEE:
        coverFee = action.item.coverFee.trim();
        if (coverFee !== '') {
          create('coverFee', coverFee);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.CHANGE_FEE_AMOUNT:
        feeAmount = action.item.feeAmount;
        if (feeAmount !== '') {
          create('feeAmount', feeAmount);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.CHANGE_FORMATED_FEE_AMOUNT:
        formatedFeeAmount = action.item.formatedFeeAmount;
        create('formatedFeeAmount', formatedFeeAmount);
        DonationStore.emitChange();
        break;

      case DonationConstants.CHANGE_AMOUNT_BEFORE_FEE:
        amountBeforeFee = action.item.amountBeforeFee;
        create('amountBeforeFee', amountBeforeFee);
        DonationStore.emitChange();
        break;

      case DonationConstants.RP_MODAL_CLOSED:
        recurringModalStatus = action.item.recurringModalStatus.trim();
        if (recurringModalStatus !== '') {
          create('recurringModalStatus', recurringModalStatus);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.CHANGE_NOTE:
        note = action.item.note.trim();
        if (note !== '') {
          create('note', note);
          DonationStore.emitChange();
        } else {
          create('note', '');
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.CHANGE_COUNTRY:
        country = action.item.country.trim();
        if (country !== '') {
          create('country', country);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.UPDATE_CONFIRMATION:
        confirmation = action.item.confirmation;
        if (confirmation !== '') {
          create('confirmation', confirmation);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.POST_BACK_DATA:
        postBackData = action.item.postBackData;
        if (postBackData !== '') {
          create('postBackData', postBackData);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.UPDATE_CURRENT_PAGE:
        currentPage = action.item.currentPage;
        if (currentPage !== '') {
          create('currentPage', currentPage);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.UPDATE_SERVER_ERRORS:
        serverErrors = action.item.serverErrors;
        if (serverErrors !== '') {
          create('serverErrors', serverErrors);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.PAYMENT_ERROR:
        paymentError = action.item.paymentError;
        if (paymentError !== '') {
          create('paymentError', paymentError);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.UPDATE_SIGN_UP_ERROR:
        signUpError = action.item.signUpError;
        if (signUpError !== '') {
          create('signUpError', signUpError);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.FUNDING_OPTIONS:
        fundingOptions = action.item.fundingOptions;
        if (fundingOptions !== '') {
          create('fundingOptions', fundingOptions);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.EMAIL:
        email = action.item.email;
        if (email !== '') {
          create('email', email);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.BILLING_ADDRESS:
        billingAddress = action.item.billingAddress;
        if (billingAddress !== '') {
          create('billingAddress', billingAddress);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.UPDATE_DONATION_AMOUNT_TYPE:
        create('donationAmountType', action.item);
        DonationStore.emitChange();
        break;

      case DonationConstants.GIFT_AID_IT:
        giftAidItFlag = action.item.giftAidItFlag;
        create('giftAidItFlag', giftAidItFlag);
        DonationStore.emitChange();
        break;

      case DonationConstants.CHANGE_SHARE_ADDRESS:
        create('shareAddress', action.item);
        DonationStore.emitChange();
        break;

      case DonationConstants.ADD_CARD_ERROR:
        create('addCardError', action.item);
        DonationStore.emitChange();
        break;

      case DonationConstants.UPDATE_SELECTED_PROGRAM:
        create('selectedProgram', action.item);
        DonationStore.emitChange();
        break;

      case DonationConstants.UPDATE_SELECTED_PRESET_BUTTON_ID:
        create('selectedPresetButtonId', action.item);
        DonationStore.emitChange();
        break;

      case DonationConstants.CHANGE_CARD_SPEC:
        create('cardSpec', action.item);
        DonationStore.emitChange();
        break;

      case DonationConstants.SAVE_FORM_DATA:
        create('formData', action.item);
        DonationStore.emitChange();
        break;

      case DonationConstants.UPDATE_THREEDS_DATA:
        create('threeDSContingency', action.item);
        DonationStore.emitChange();
        break;

      case DonationConstants.SHOW_LANGUAGE_SELECTOR:
        create('languageSelectorState', action.item);
        DonationStore.emitChange();
        break;

      case DonationConstants.UPDATE_ORDER_STATUS:
        orderStatus = action.item.orderStatus.trim();
        if (orderStatus !== '') {
          create('orderStatus', action.item);
          DonationStore.emitChange();
        }
        break;

      case DonationConstants.UPDATE_PPCC_STATUS:
        create('ppccStatus', action.item);
        DonationStore.emitChange();
        break;

      default:
        break;
    }

    return true; // No errors. Needed by promise in Dispatcher.
  }),
});

AppDispatcher.register((payload) => {
  return true;
});

export default DonationStore;
