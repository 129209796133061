import React from 'react';
import { ContextProvider as WorldReadyContextProvider } from '@paypalcorp/worldready-react';
import { WorldReadyProvider } from '@paypalcorp/pp-react-worldready';
import templateData from './../utility/getTemplateData';
import { initializeWorldReady } from '../utility/worldReadyUtils';

initializeWorldReady();

function withWorldReady(
  WrappedComponent,
  locale = `${templateData?.language || 'en'}-${templateData?.country || 'US'}`
) {
  return (props) => {
    return (
      <WorldReadyContextProvider locale={locale}>
        <WorldReadyProvider>
          <WrappedComponent {...props} />
        </WorldReadyProvider>
      </WorldReadyContextProvider>
    );
  };
}

export default withWorldReady;
